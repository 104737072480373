import React from 'react'

function MultipleFeatureDropDown({ values, dropdown, index, handleChangeFeatureChange, handleRemoveFeatureDropdown }) {
    return (
        <>
            <div key={index} data-key={dropdown.key} className="row position-relative">
                <div className="bed-row d-flex flex-wrap align-items-end">
                    <div className="bed-add-row mt-5">
                        <input
                            type="text"
                            className="form-control py-2 shadow-none px-3"
                            placeholder="Enter feature"
                            name="features"
                            onChange={(e) => handleChangeFeatureChange(e, dropdown.key)}
                            value={values.features[dropdown.key]}
                        />
                    </div>
                    <div className="add-more-blocks text-end mb-2">
                        <a
                            onClick={() => handleRemoveFeatureDropdown(dropdown)}
                            className="btn btn-outline-danger text-uppercase px-2 py-2"
                        >
                            <i className="fa fa-minus"></i>
                        </a>
                    </div>
                </div>

            </div>
        </>
    )
}

export default MultipleFeatureDropDown