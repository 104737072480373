import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import bannerImage from '../../assets/images/press-one.jpg'

function Products() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  return (
    <>
        <div className='page-body'>
            <div className='container-fluid dashboard-default'>
                <div className='page-heading d-flex flex-wrap align-items-center justify-content-between'>
                    <div className='left-header'>
                        <h4 className='fw-normal m-0 fs-5'>Categories</h4>
                    </div>
                </div>
                <div className='row mt-md-4 mt-3'>
                    <div className='col-md-12'>
                        <div className='card mb-4'>
                            <div className='card-header bg-transparent border-bottom py-2 d-flex align-items-center justify-content-between'>
                                <h5 className='m-0 fw-medium fs-6'>
                                    Categories
                                </h5>
                                <div className='right-actions'>
                                    <Link className='btn btn-primary btn-theme fw-semibold text-uppercase px-2 py-1' to='/manage-products/manage-categories/add-category'>Add</Link>
                                </div>
                            </div>
                            <div className='card-body'>
                                <div className='prdct-data'>
                                    <div className='tabledata mt-3'>
                                        <div className='table-responsive'>
                                            <table className='table'>
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            Name
                                                        </th>
                                                        <th>
                                                            Products
                                                        </th>
                                                        <th>
                                                            Banner Image
                                                        </th>
                                                        <th>
                                                            Created On
                                                        </th>
                                                        <th>
                                                            Status
                                                        </th>
                                                        <th>
                                                            Actions
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            Category 1
                                                        </td>
                                                        <td>
                                                            24
                                                        </td>
                                                        <td>
                                                            <button onClick={handleShow} className='btn text-success fw-semibold pt-0 pb-0 ms-1'>View</button>
                                                        </td>
                                                        <td>
                                                            23/10/2024 10:30am
                                                        </td>
                                                        <td>
                                                            <select className='form-select'>
                                                                <option value='1' selected='Active'>Active</option>
                                                                <option value='2'>Inactive</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <button className='btn btn-primary btn-sm btn-theme'>
                                                                <FontAwesomeIcon icon={faPencil} />
                                                            </button>
                                                            <Link className='btn btn-primary btn-sm btn-theme mx-1'to="/manage-products/product-details">
                                                                <FontAwesomeIcon icon={faEye} />
                                                            </Link>
                                                            <button className='btn btn-sm btn-danger'>
                                                                <FontAwesomeIcon icon={faTrash} />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Category 2
                                                        </td>
                                                        <td>
                                                            24
                                                        </td>
                                                        <td>
                                                            <button onClick={handleShow} className='btn text-success fw-semibold pt-0 pb-0 ms-1'>View</button>
                                                        </td>
                                                        <td>
                                                            23/10/2024 10:30am
                                                        </td>
                                                        <td>
                                                            <select className='form-select'>
                                                                <option value='1' selected='Active'>Active</option>
                                                                <option value='2'>Inactive</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <button className='btn btn-primary btn-sm btn-theme'>
                                                                <FontAwesomeIcon icon={faPencil} />
                                                            </button>
                                                            <Link className='btn btn-primary btn-sm btn-theme mx-1'to="/manage-products/product-details">
                                                                <FontAwesomeIcon icon={faEye} />
                                                            </Link>
                                                            <button className='btn btn-sm btn-danger'>
                                                                <FontAwesomeIcon icon={faTrash} />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Category 3
                                                        </td>
                                                        <td>
                                                            24
                                                        </td>
                                                        <td>
                                                            <button onClick={handleShow} className='btn text-success fw-semibold pt-0 pb-0 ms-1'>View</button>
                                                        </td>
                                                        <td>
                                                            23/10/2024 10:30am
                                                        </td>
                                                        <td>
                                                            <select className='form-select'>
                                                                <option value='1' selected='Active'>Active</option>
                                                                <option value='2'>Inactive</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <button className='btn btn-primary btn-sm btn-theme'>
                                                                <FontAwesomeIcon icon={faPencil} />
                                                            </button>
                                                            <Link className='btn btn-primary btn-sm btn-theme mx-1' to="/manage-products/product-details">
                                                                <FontAwesomeIcon icon={faEye} />
                                                            </Link>
                                                            <button className='btn btn-sm btn-danger'>
                                                                <FontAwesomeIcon icon={faTrash} />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Category 4
                                                        </td>
                                                        <td>
                                                            24
                                                        </td>
                                                        <td>
                                                            <button onClick={handleShow} className='btn text-success fw-semibold pt-0 pb-0 ms-1'>View</button>
                                                        </td>
                                                        <td>
                                                            23/10/2024 10:30am
                                                        </td>
                                                        <td>
                                                            <select className='form-select'>
                                                                <option value='1' selected='Active'>Active</option>
                                                                <option value='2'>Inactive</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <button className='btn btn-primary btn-sm btn-theme'>
                                                                <FontAwesomeIcon icon={faPencil} />
                                                            </button>
                                                            <Link className='btn btn-primary btn-sm btn-theme mx-1' to="/manage-products/product-details">
                                                                <FontAwesomeIcon icon={faEye} />
                                                            </Link>
                                                            <button className='btn btn-sm btn-danger'>
                                                                <FontAwesomeIcon icon={faTrash} />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Modal centered aria-labelledby="contained-modal-title-vcenter" className='product-img-modal' size="lg" show={show} onHide={handleClose}>
            <Modal.Body>
            <Modal.Header className='border-0 position-absolute end-0 me-3 top-0 mt-3' closeButton>
            </Modal.Header>
                <div className='product-img' closeButton>
                    <img className='w-100' src={bannerImage} alt='' />
                </div>
            </Modal.Body>
        </Modal>
  </>
  )
}

export default Products