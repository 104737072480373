import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-bootstrap/Modal';
import { Button, ButtonGroup, Dropdown, Form } from 'react-bootstrap';
import bannerImage from '../../assets/images/press-one.jpg'
import { FaRegEye } from 'react-icons/fa';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { LuSearch } from "react-icons/lu";
import { TbArrowsSort } from "react-icons/tb";

function AllProducts() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  return (
    <>
        <div className='page-body'>
            <div className='container-fluid dashboard-default'>
                <div className='page-heading d-flex flex-wrap align-items-center justify-content-between'>
                    <div className='left-header'>
                        <h4 className='fw-normal m-0 fs-5'>Products</h4>
                    </div>
                    <div className='right-actions product-btns'>
                        <div className='right-actions d-flex align-items-center gap-2'>
                            <Button className='btn btn-primary btn-theme fw-semibold text-uppercase px-2 py-1' to='/add-product'>Export</Button>
                            <Button className='btn btn-primary btn-theme fw-semibold text-uppercase px-2 py-1' to='/add-product'>Import</Button>
                            <div className='show-analystic-bar'>
                                <Dropdown>
                                    <Dropdown.Toggle className='btn btn-primary btn-theme fw-semibold text-uppercase px-2 py-1' id="dropdown-basic">
                                        More Actions
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item className='py-0' href="#"><small><FaRegEye className='me-1' /> Show analytic bar</small></Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <Link className='btn btn-primary btn-theme fw-semibold text-uppercase px-2 py-1' to="/manage-products/product-details" >Add Product</Link>
                        </div>
                    </div>
                </div>
                <div className='products-tabs'>
                    <div className='row mt-md-4 mt-3'>
                        <div className='col-md-12'>
                            <Tabs
                            defaultActiveKey="allproducts"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                            variant='pills'
                            >
                                <Tab eventKey="allproducts" title="All">
                                    <div className='card mb-4'>
                                        <div className='card-header bg-transparent border-bottom py-2 d-flex align-items-center justify-content-between'>
                                            <h5 className='m-0 fw-medium fs-6'>
                                                All Products
                                            </h5>
                                            <div className='right-actions d-flex gap-2'>
                                                <Form>
                                                    <div className='position-relative'>
                                                        <Form.Control size="sm" type="text" className='shadow-none' placeholder="Search..." />
                                                        <Button className='btn bg-transparent border-0 shadow-none text-black position-absolute end-0 top-0 h-100 d-flex align-items-center' type='submit'><LuSearch /></Button>
                                                    </div>
                                                </Form>
                                                <Button className='btn bg-transparent border text-black btn-sm px-2 py-1'>
                                                    <TbArrowsSort />
                                                </Button>
                                            </div>
                                        </div>
                                        <div className='card-body'>
                                            <div className='prdct-data'>
                                                <div className='tabledata'>
                                                    <div className='table-responsive'>
                                                        <table className='table'>
                                                            <thead>
                                                                <tr>
                                                                    <th>
                                                                        <input type='checkbox'/>
                                                                    </th>
                                                                    <th>
                                                                        Product
                                                                    </th>
                                                                    <th>
                                                                        Status
                                                                    </th>
                                                                    <th>
                                                                        Inventory
                                                                    </th>
                                                                    <th>
                                                                        Category
                                                                    </th>
                                                                    <th>
                                                                        Type
                                                                    </th>
                                                                    <th>
                                                                        Vendor
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <input type='checkbox'/>
                                                                    </td>
                                                                    <td>
                                                                        <Link to="/manage-products/product-details" className='text-black text-decoration-none d-flex align-items-center'>
                                                                            <img className='me-2 rounded-1 border' width={50} src={bannerImage} alt='' /> Cate one
                                                                        </Link>
                                                                    </td>
                                                                    <td>
                                                                        <span className='px-2 py-1 bg-success rounded-1 text-white'>Active</span>
                                                                    </td>
                                                                    <td>
                                                                        <span className='text-danger'>0 in stock</span> for 4 variants
                                                                    </td>
                                                                    <td>
                                                                        Uncategorized
                                                                    </td>
                                                                    <td>
                                                                        Uncategorized
                                                                    </td>
                                                                    <td>
                                                                        My Store
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <input type='checkbox'/>
                                                                    </td>
                                                                    <td>
                                                                        <Link to="/manage-products/product-details" className='text-black text-decoration-none d-flex align-items-center'>
                                                                            <img className='me-2 rounded-1 border' width={50} src={bannerImage} alt='' /> Cate one
                                                                        </Link>
                                                                    </td>
                                                                    <td>
                                                                        <span className='px-2 py-1 bg-success rounded-1 text-white'>Active</span>
                                                                    </td>
                                                                    <td>
                                                                        <span className='text-danger'>0 in stock</span> for 4 variants
                                                                    </td>
                                                                    <td>
                                                                        Uncategorized
                                                                    </td>
                                                                    <td>
                                                                        Uncategorized
                                                                    </td>
                                                                    <td>
                                                                        My Store
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <input type='checkbox'/>
                                                                    </td>
                                                                    <td>
                                                                        <Link to="/manage-products/product-details" className='text-black text-decoration-none d-flex align-items-center'>
                                                                            <img className='me-2 rounded-1 border' width={50} src={bannerImage} alt='' /> Cate one
                                                                        </Link>
                                                                    </td>
                                                                    <td>
                                                                        <span className='px-2 py-1 bg-success rounded-1 text-white'>Active</span>
                                                                    </td>
                                                                    <td>
                                                                        <span className='text-danger'>0 in stock</span> for 4 variants
                                                                    </td>
                                                                    <td>
                                                                        Uncategorized
                                                                    </td>
                                                                    <td>
                                                                        Uncategorized
                                                                    </td>
                                                                    <td>
                                                                        My Store
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="active" title="Active">
                                    Tab content for Profile
                                </Tab>
                                <Tab eventKey="draft" title="Draft" >
                                    Tab content for Profile
                                </Tab>
                                <Tab eventKey="archived" title="Archived">
                                    Tab content for Profile
                                </Tab>
                            </Tabs>                        
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Modal centered aria-labelledby="contained-modal-title-vcenter" className='product-img-modal' size="lg" show={show} onHide={handleClose}>
            <Modal.Body>
            <Modal.Header className='border-0 position-absolute end-0 me-3 top-0 mt-3' closeButton>
            </Modal.Header>
                <div className='product-img' closeButton>
                    <img className='w-100' src={bannerImage} alt='' />
                </div>
            </Modal.Body>
        </Modal>
  </>
  )
}

export default AllProducts