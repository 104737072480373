import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from 'react-bootstrap/Modal';
import { Button, Dropdown } from 'react-bootstrap';
import bannerImage from '../../assets/images/press-one.jpg'
import dragDrop from '../../assets/images/drag.svg'
import { FaPlus, FaRegEye } from 'react-icons/fa';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import backarrow from "../../assets/images/back-arrow.svg";
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { WithContext as ReactTags } from 'react-tag-input';
import Select from 'react-select'
import { IoImage } from "react-icons/io5";
import { FaPencil } from "react-icons/fa6";
import uploadedImg from '../../assets/images/album-img.png'
import CategorySelector from './CategorySelector';

// Sample options for the react-select component
const colourOptions = [
    { value: 'red', label: 'Red' },
    { value: 'green', label: 'Green' },
    { value: 'blue', label: 'Blue' }
];

function ProductDetils() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [tags, setTags] = useState([
        { id: "Home", text: "Home" },
        { id: "About", text: "About" },
        { id: "Product", text: "Product" }
    ]);

    const handleDelete = (i) => {
        setTags(tags.filter((tag, index) => index !== i));
    };

    const handleAddition = (tag) => {
        setTags([...tags, tag]);
    };

    const handleDrag = (tag, currPos, newPos) => {
        const newTags = [...tags];
        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);
        setTags(newTags);
    };

    const handleTagClick = (index) => {
        console.log(`The tag at index ${index} was clicked`);
    };

    const onClearAll = () => {
        setTags([]);
    };

    // Define additional variables for react-select
    const isDisabled = false;
    const isLoading = false;
    const isClearable = true;
    const isRtl = false;
    const isSearchable = true;

    const [isVisible, setIsVisible] = useState(false);

  const toggleDivVisibility = () => {
    setIsVisible(!isVisible);
  };
  


  return (
    <>
        <div className='page-body'>
            <div className='container-fluid dashboard-default'>
                <div className='page-heading d-flex flex-wrap align-items-center justify-content-between'>
                    <nav className="w-100" aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                            <Link
                                className="text-decoration-none"
                                to={'/manage-products'}
                            >
                                All Products
                            </Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                Product Details
                            </li>
                        </ol>
                        </nav>
                    <div className="left-header d-flex align-items-center">
                    <Link
                        to={'/manage-products'}
                        className="back-arrow d-inline-block me-2"
                    >
                        <img src={backarrow} alt="" />
                    </Link>
                    <h4 className="fw-normal m-0 fs-6">
                        Product Details
                    </h4>
                    </div>
                    <div className='right-actions product-btns'>
                        <div className='right-actions d-flex align-items-center gap-2'>
                            <Button className='btn btn-primary btn-theme fw-semibold text-uppercase px-2 py-1' to='/add-product'>Duplicate</Button>
                            <Button className='btn btn-primary btn-theme fw-semibold text-uppercase px-2 py-1' to='/add-product'>Preview</Button>
                            <div className='show-analystic-bar'>
                                <Dropdown>
                                    <Dropdown.Toggle className='btn btn-primary btn-theme fw-semibold text-uppercase px-2 py-1' id="dropdown-basic">
                                        Share
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item className='py-0' href="#"><small><FaRegEye className='me-1' /> Show analytic bar</small></Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div className='show-analystic-bar'>
                                <Dropdown>
                                    <Dropdown.Toggle className='btn btn-primary btn-theme fw-semibold text-uppercase px-2 py-1' id="dropdown-basic">
                                        More Actions
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item className='py-0' href="#"><small>Localize</small></Dropdown.Item>
                                        <Dropdown.Item className='py-0' href="#"><small>Edit product boost search terms</small></Dropdown.Item>
                                        <Dropdown.Item className='py-0' href="#"><small>Edit product recommendations</small></Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            {/* <Link className='btn btn-primary btn-theme fw-semibold text-uppercase px-2 py-1' to='/manage-products/add-product'>Add Product</Link> */}
                        </div>
                    </div>
                </div>
                <div className='products-tabs'>
                    <div className='row mt-md-4 mt-3'>
                        <div className='col-md-9'>
                            <Tabs
                            defaultActiveKey="productinfo"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                            variant='pills'
                            >
                                <Tab eventKey="productinfo" title="Product Info">
                                    <div className='card mb-4'>
                                        <div className='card-body'>
                                            <form action='#' method='get'>
                                                <div className='row'>
                                                    <div>
                                                        
                                                    </div>
                                                    <div className='col-12 mb-4'>
                                                        <label className="form-label d-flex align-items-center" htmlFor="Title">
                                                            Title
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control shadow-none px-3"
                                                            name="propertyName"
                                                            id="Title"
                                                        />
                                                    </div>
                                                    <div className='col-12 mb-4'>
                                                        <label className="form-label d-flex align-items-center" htmlFor="Description">
                                                            Description
                                                        </label>
                                                        <textarea
                                                            className="form-control shadow-none px-3"
                                                            name="propertyName"
                                                            id="Description"
                                                            rows={4}
                                                        >
                                                        </textarea>
                                                    </div>
                                                    <div className='col-12 mb-4'>
                                                        <div className='upload-file-wrapper'>
                                                            <label className="form-label d-flex align-items-center" htmlFor="Media">
                                                                Media
                                                            </label>
                                                            <div className="uploaded-property-img d-flex">
                                                                <label
                                                                htmlFor="UploadCover"
                                                                tabIndex={-1}
                                                                className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5"
                                                                >
                                                                <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                                                                    <Button
                                                                    type="button"
                                                                    className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                                                                    >
                                                                    Upload Product Image
                                                                    </Button>
                                                                </div>
                                                                </label>
                                                            </div>
                                                            <div className="uploaded-property-img uploaded-product-media d-flex flex-wrap align-items-start">
                                                                <div className='property-img uploaded hero-image d-flex justify-content-center align-items-center position-relative'>
                                                                    <div className=''>
                                                                        <div className="upload-img">
                                                                            <img src={uploadedImg} alt='' />
                                                                            <button
                                                                            className="remove-image-button position-absolute end-0 top-0"
                                                                            >
                                                                            <i className="fa fa-times"></i>
                                                                            </button>
                                                                            {/* </div> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='property-img uploaded d-flex justify-content-center align-items-center position-relative'>
                                                                    <div className=''>
                                                                        <div className="upload-img">
                                                                            <img src={uploadedImg} alt='' />
                                                                            <button
                                                                            className="remove-image-button position-absolute end-0 top-0"
                                                                            >
                                                                            <i className="fa fa-times"></i>
                                                                            </button>
                                                                            {/* </div> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='property-img uploaded d-flex justify-content-center align-items-center position-relative'>
                                                                    <div className=''>
                                                                        <div className="upload-img">
                                                                            <img src={uploadedImg} alt='' />
                                                                            <button
                                                                            className="remove-image-button position-absolute end-0 top-0"
                                                                            >
                                                                            <i className="fa fa-times"></i>
                                                                            </button>
                                                                            {/* </div> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='property-img d-flex justify-content-center align-items-center position-relative'>
                                                                    <div className='upload-img'>
                                                                        <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                                                                            <i className="fa fa-plus"></i>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-12 mb-4'>
                                                    <label className="form-label d-flex align-items-center" htmlFor="Category">
                                                        Category
                                                    </label>
                                                    <CategorySelector />
                                                    <div className='mt-1'><small>Determines tax rates and adds metafields to improve search, filters, and cross-channel sales</small></div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    {/* /card */}
                                    
                                    <div className='card pricing-card mt-4'>
                                        <div className='card-header bg-transparent border-bottom py-2 d-flex align-items-center justify-content-between'>
                                            <h5 className='m-0 fw-medium fs-6'>Pricing</h5>
                                        </div>
                                        <div className='card-body'>
                                            <form action="#" method='get'>
                                                <div className='row'>
                                                    <div className='col-lg-6'>
                                                        <div className='mb-3'>
                                                            <label className="form-label d-flex align-items-center" htmlFor="pricetext">
                                                                Price
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control shadow-none px-3 price-input"
                                                                name="propertyName"
                                                                id="pricetext"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <div className='mb-3'>
                                                            <label className="form-label d-flex align-items-center" htmlFor="Metatitle">
                                                                Compare-at price
                                                                <ReactTooltip
                                                                    id="my-tooltip-2"
                                                                    place="top"
                                                                    content="To display a markdown, enter a value higher than your price. Often shown with a strikethrough."
                                                                />
                                                                <div data-tooltip-id="my-tooltip-2">
                                                                    <span className='ms-2' id='my-tooltip-2'><FontAwesomeIcon icon={faCircleInfo} /></span>
                                                                </div>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control shadow-none px-3 price-input"
                                                                name="propertyName"
                                                                id="Metatitle"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-12 mb-4'>
                                                        <label htmlFor='taxCheck' className='d-flex gap-2'>
                                                            <input type='checkbox' id='taxCheck'/>
                                                            <span className='me-2'>Charge tax on this product</span>
                                                        </label>
                                                    </div>
                                                    <div className='col-lg-4'>
                                                        <div className='mb-4'>
                                                            <label className="form-label d-flex align-items-center" htmlFor="CostPerItem">
                                                                Cost Per Item
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control shadow-none px-3 price-input"
                                                                name="propertyName"
                                                                id="CostPerItem"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-4 mb-4'>
                                                        <label className="form-label d-flex align-items-center" htmlFor="Profit">
                                                            Profit
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control shadow-none px-3"
                                                            name="propertyName"
                                                            id="Profit"
                                                        />
                                                    </div>
                                                    <div className='col-lg-4 mb-4'>
                                                        <label className="form-label d-flex align-items-center" htmlFor="Margin">
                                                            Margin
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control shadow-none px-3"
                                                            name="propertyName"
                                                            id="Margin"
                                                        />
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                    <div className='card mt-4'>
                                        <div className='card-header bg-transparent border-bottom py-2 d-flex align-items-center justify-content-between'>
                                            <h5 className='m-0 fw-medium fs-6'>Attributes</h5>
                                        </div>
                                        <div className='card-body'>
                                            <div className='attributes-list'>
                                                <ul className='list-unstyled mb-0'>
                                                    <li className='d-flex align-items-center'>
                                                        <div className='me-3'><img className='opacity-75' src={dragDrop} alt='' /></div>
                                                        <div>
                                                            <div className='attributs-tag'>
                                                                <span className='btn-secondary bg-transparent mb-2 btn border-0 text-black py-1 btn-sm'>xl</span>
                                                            </div>
                                                            <div className='attributs-tag'>
                                                                <span className='btn-secondary btn border-0 text-black py-1 btn-sm'>2</span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className='d-flex align-items-center'>
                                                        <div className='me-3'><img className='opacity-75' src={dragDrop} alt='' /></div>
                                                        <div>
                                                            <div className='attributs-tag'>
                                                                <span className='btn-secondary bg-transparent mb-2 btn border-0 text-black py-1 btn-sm'>xl</span>
                                                            </div>
                                                            <div className='attributs-tag'>
                                                                <span className='btn-secondary btn border-0 text-black py-1 btn-sm'>2</span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        <div>
                                        {!isVisible && (
                                            <button className='border-0 shadow-none px-2 rounded-1 add-attributs' onClick={toggleDivVisibility}>
                                                <small className='d-flex align-items-center'><FaPlus className='me-1' /> Add options like size or color</small>
                                            </button>
                                            )}
                                            {isVisible && (
                                                <div id="sectiontohide">
                                                    <div className='col-12 mb-4'>
                                                        <label className="form-label d-flex align-items-center" htmlFor="OptionName">
                                                            Option name
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control shadow-none px-3"
                                                            name="propertyName"
                                                            id="OptionName"
                                                        />
                                                    </div>
                                                    <div className='col-12 mb-4'>
                                                        <label className="form-label d-flex align-items-center" htmlFor="Optionvalues">
                                                            Option values
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control shadow-none px-3"
                                                            name="propertyName"
                                                            id="Optionvalues"
                                                        />
                                                    </div>
                                                    <div className='col-12 d-flex justify-content-end'>
                                                        <button className='fw-semibold text-uppercase px-2 py-1 btn btn-sm btn-danger me-2'>Delete</button>
                                                        <button className='btn btn-theme fw-semibold text-uppercase btn-sm px-2 py-1 btn btn-primary'>Done</button>
                                                    </div>
                                                </div>
                                            )}
                                            </div>
                                        </div>
                                    </div>
                                                    
                                </Tab>
                                <Tab eventKey="active" title="Variants">
                                    <div className='card'>
                                        <div className='card-header bg-transparent border-bottom py-2 d-flex align-items-center justify-content-between'>
                                            <h5 className='m-0 fw-medium fs-6'>Variants</h5>
                                        </div>
                                        <div className='card-body'>
                                        <div className='table-responsive'>
                                            <table className='table table-bordered'>
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            <input type='checkbox'/>
                                                        </th>
                                                        <th>
                                                            Variant
                                                        </th>
                                                        <th>
                                                            Price
                                                        </th>
                                                        <th>
                                                            Available
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <input type='checkbox'/>
                                                        </td>
                                                        <td>
                                                            <Link to='' className='fs-5 text-black'><IoImage /></Link>
                                                        </td>
                                                        <td>
                                                            <div className='varient-price'>
                                                                <input
                                                                type="text"
                                                                className="form-control shadow-none px-3"
                                                                name="propertyName"
                                                                id="Optionvalues"
                                                                value='0.00'
                                                            />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className='varient-numbers'>
                                                            <input
                                                            type="number"
                                                            className="form-control shadow-none px-3"
                                                            name="propertyName"
                                                            id="Optionvalues"
                                                            />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className='numer-varients'>
                                            <small>Total inventory at Shop location: <span>5</span> available</small>
                                        </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="draft" title="Search Engine Listing">
                                    <div className='card'>
                                        <div className='card-header bg-transparent border-bottom py-2 d-flex align-items-center justify-content-between'>
                                            <h5 className='m-0 fw-medium fs-6'>Search engine listing</h5>
                                        </div>
                                        <div className='card-body'>
                                        <form action="#" method='get'>
                                                <div className='row'>
                                                    <div className='col-lg-6'>
                                                        <div className='mb-4'>
                                                            <label className="form-label d-flex align-items-center" htmlFor="CategoryUrl">
                                                                Category URL*
                                                            </label>
                                                            <input
                                                                type="url"
                                                                className="form-control shadow-none px-3"
                                                                name="propertyName"
                                                                id="CategoryUrl"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <div className='mb-4'>
                                                            <label className="form-label d-flex align-items-center" htmlFor="Metatitle">
                                                                Meta Title
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control shadow-none px-3"
                                                                name="propertyName"
                                                                id="Metatitle"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <div className='mb-4'>
                                                            <label className="form-label d-flex align-items-center" htmlFor="Keywords">
                                                                Keywords
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control shadow-none px-3"
                                                                name="propertyName"
                                                                id="Keywords"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 mb-4'>
                                                    <label className="form-label d-flex align-items-center" htmlFor="Metadescription">
                                                        Meta Description
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control shadow-none px-3"
                                                        name="propertyName"
                                                        id="Metadescription"
                                                    />
                                                </div>
                                                </div>
                                                <div className='col-12 d-flex justify-content-end'>
                                                    <button className='btn btn-theme fw-semibold text-uppercase btn-sm px-2 py-1 btn btn-primary'>Save</button>
                                                </div>
                                            </form>
                                            <div className='my-store-info py-2'>
                                                <h6 className='mb-1'>My Store</h6>
                                                <span className='opacity-50'>https://2hzqqd-rp.myshopify.com › products › cate-one</span>
                                                <div className='store-name text-primary fs-5 mt-2'>
                                                    Cate one
                                                </div>
                                                <div className='price-store'>
                                                    <small className='opacity-75'>₹0.00 INR</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                            </Tabs>                        
                        </div>
                        <div className='col-md-3 mt-4 pt-4'>
                            <div className='card'>
                                <div className='card-header bg-transparent border-bottom py-2 d-flex align-items-center justify-content-between'>
                                    <h5 className='m-0 fw-medium fs-6'>Status</h5>
                                </div>
                                <div className='card-body'>
                                    <select className='form-select form-select-sm'>
                                        <option value='1' selected='Active'>Active</option>
                                        <option value='2'>Draft</option>
                                    </select>
                                </div>
                            </div>
                            <div className='card mt-3'>
                                <div className='card-header bg-transparent border-bottom py-2 d-flex align-items-center justify-content-between'>
                                    <h5 className='m-0 fw-medium fs-6'>Product organization</h5>
                                </div>
                                <div className='card-body'>
                                    <div className='mb-2'>
                                    <label className="form-label d-flex align-items-center" htmlFor="ParentCategory">
                                        Product type
                                        <ReactTooltip
                                                id="my-tooltip-1"
                                                place="top"
                                                content="Hello world! I'm a Tooltip"
                                            />
                                            <div data-tooltip-id="my-tooltip-1">
                                                <span className='ms-2' id='my-tooltip-1'><FontAwesomeIcon icon={faCircleInfo} /></span>
                                            </div>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control form-control-sm shadow-none px-3"
                                            name="propertyName"
                                            id="CategoryName"
                                        />
                                    </div>
                                    <div className='mb-2'>
                                    <label className="form-label d-flex align-items-center" htmlFor="ParentCategory">
                                        Vendor
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control form-control-sm shadow-none px-3"
                                            name="propertyName"
                                            id="CategoryName"
                                        />
                                    </div>
                                    <div className='mb-2 input-tags'>
                                    <label className="form-label d-flex align-items-center" htmlFor="ParentCategory">
                                    Collections
                                        </label>
                                        <ReactTags
                                            tags={tags}
                                            inputFieldPosition="top"
                                            handleDelete={handleDelete}
                                            handleAddition={handleAddition}
                                            handleDrag={handleDrag}
                                            handleTagClick={handleTagClick}
                                            editable={true}
                                            // clearAll={true}
                                            onClearAll={onClearAll}
                                            maxTags={7}
                                            allowAdditionFromPaste={true}
                                        />
                                    </div>
                                    <div className='mb-2 input-tags'>
                                    <label className="form-label d-flex align-items-center" htmlFor="ParentCategory">
                                        Tags
                                        </label>
                                        <ReactTags
                                            tags={tags}
                                            inputFieldPosition="top"
                                            handleDelete={handleDelete}
                                            handleAddition={handleAddition}
                                            handleDrag={handleDrag}
                                            handleTagClick={handleTagClick}
                                            editable={true}
                                            // clearAll={true}
                                            onClearAll={onClearAll}
                                            maxTags={7}
                                            allowAdditionFromPaste={true}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='card mt-3'>
                                <div className='card-header bg-transparent border-bottom py-2 d-flex align-items-center justify-content-between'>
                                    <h5 className='m-0 fw-medium fs-6'>Theme template</h5>
                                </div>
                                <div className='card-body'>
                                    <select className='form-select form-select-sm'>
                                        <option value='1' selected='Default'>Default Product</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Modal centered aria-labelledby="contained-modal-title-vcenter" className='product-img-modal' size="lg" show={show} onHide={handleClose}>
            <Modal.Body>
            <Modal.Header className='border-0 position-absolute end-0 me-3 top-0 mt-3' closeButton>
            </Modal.Header>
                <div className='product-img' closeButton>
                    <img className='w-100' src={bannerImage} alt='' />
                </div>
            </Modal.Body>
        </Modal>
  </>
  )
}

export default ProductDetils