import React from 'react'
import { Link } from 'react-router-dom'
import backarrow from "../../assets/images/back-arrow.svg";
import { Button} from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { Tooltip as ReactTooltip } from 'react-tooltip'

function AddProducts() {
  return (
    <div className='page-body'>
            <div className='container-fluid dashboard-default'>
                <div className='page-heading d-flex flex-wrap align-items-center justify-content-between'>
                <nav className="w-100" aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                        <Link
                            className="text-decoration-none"
                            to="/manage-products/manage-categories"
                        >
                            Categories
                        </Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            Add New
                        </li>
                    </ol>
                    </nav>
                    <div className="left-header d-flex align-item-center">
                        <Link
                            to="/manage-products/manage-categories"
                            className="back-arrow d-inline-block me-2"
                        >
                            <img src={backarrow} alt="" />
                        </Link>
                        <h4 className="fw-normal m-0 fs-5">Category</h4>
                    </div>
                </div>
                <div className='row mt-md-4 mt-3'>
                    <div className='col-md-12'>
                        <div className='card mb-4'>
                            <div className='card-header bg-transparent border-bottom py-2 d-flex align-items-center justify-content-between'>
                                <h5 className='m-0 fw-medium fs-6'>
                                    Add Category
                                </h5>
                            </div>
                            <div className='card-body'>
                                <form action='#' method='get'>
                                    <div className='row'>
                                        <div className="col-lg-4">
                                            <div className='mb-4'>
                                                <label className="form-label" htmlFor="CategoryName">
                                                    Category Name<sup>*</sup>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control py-2 shadow-none px-3"
                                                    name="propertyName"
                                                    id="CategoryName"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className='mb-4'>
                                                <label className="form-label d-flex align-items-center" htmlFor="ParentCategory">
                                                    Parent Category 
                                                    
                                                    <ReactTooltip
                                                        id="my-tooltip-1"
                                                        place="top"
                                                        content="Hello world! I'm a Tooltip"
                                                    />
                                                    <div data-tooltip-id="my-tooltip-1">
                                                        <span className='ms-2' id='my-tooltip-1'><FontAwesomeIcon icon={faCircleInfo} /></span>
                                                    </div>
                                                </label>
                                                <select id='ParentCategory' className='form-control form-select py-2 shadow-none px-3'>
                                                    <option selected='' value='1'>Select Category</option>
                                                    <option value='2'>Category 1</option>
                                                    <option value='2'>Category 2</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className='mb-4'>
                                                <label className="form-label" htmlFor="Status">
                                                    Status
                                                </label>
                                                <select id='Status' className='form-control form-select py-2 shadow-none px-3'>
                                                    <option selected='' value='1'>Active</option>
                                                    <option value='2'>Inactive</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-12'>
                                            <div className='upload-file-wrapper mb-4'>
                                                <label className='form-label' htmlFor='UploadBanner'>Category Banner Image</label>
                                                <div className="uploaded-property-img d-flex">
                                                    <label
                                                    htmlFor="UploadCover"
                                                    className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5">
                                                    <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                                                        <Button
                                                        type="button"
                                                        className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                                                        >
                                                        Choose from gallery
                                                        </Button>
                                                    </div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className='mb-4'>
                                                <label className="form-label" htmlFor="Description">
                                                    Description<sup>*</sup>
                                                </label>
                                                <textarea id='Description' rows='5' className='form-control'>

                                                </textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mt-1'>
                                        <div className='col-12 mb-3'>
                                            <h5 className='fs-6 border-top pt-4'>Search Engine Options</h5>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div className='mb-4'>
                                                <label className="form-label" htmlFor="CatUrl">
                                                    Category URL<sup>*</sup>
                                                </label>
                                                <input
                                                    type="url"
                                                    className="form-control py-2 shadow-none px-3"
                                                    name="propertyName"
                                                    id="CatUrl"
                                                />
                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div className='mb-4'>
                                                <label className="form-label" htmlFor="MetaTitle">
                                                    Meta Title
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control py-2 shadow-none px-3"
                                                    name="MetaTitle"
                                                    id="MetaTitle"
                                                />
                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div className='mb-4'>
                                                <label className="form-label" htmlFor="Keywords">
                                                    Keywords
                                                </label>
                                                <input
                                                    type="url"
                                                    className="form-control py-2 shadow-none px-3"
                                                    name="propertyName"
                                                    id="Keywords"
                                                />
                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div className='mb-4'>
                                                <label className="form-label" htmlFor="MetaDes">
                                                    Meta Description
                                                </label>
                                                <input
                                                    type="url"
                                                    className="form-control py-2 shadow-none px-3"
                                                    name="propertyName"
                                                    id="MetaDes"
                                                />
                                            </div>
                                        </div>
                                        <div className='col-12 text-end mt-lg-4 mt-0'>
                                            <button type='reset' className='btn btn-primary btn-theme outline-btn fw-semibold text-uppercase px-2 py-1 me-2 btn-sm btn btn-primary'>Cancel</button>
                                            <input type='submit' value='Save' className='btn btn-primary btn-theme fw-semibold text-uppercase px-2 py-1 btn btn-primary'/>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default AddProducts