import { useNavigate, Link } from "react-router-dom";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation } from "react-query";
import backarrow from "../../assets/images/back-arrow.svg"
import * as querydata from "../../services/service";


export const userSchema = Yup.object({
    userName: Yup.string().trim().required("Please enter user name")
        .min(4, "User name must be at least 4 character long"),
    email: Yup.string().email().required("Please enter email"),
    phone: Yup.string().matches(/^[0-9]+$/, "Invalid phone number")

});
const CreateUser = () => {
    const navigate = useNavigate();
    const [error, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const initialValues = {
        userName: "",
        password: "",
        email: "",
        phone: "",
        isActive: "1"

    };
    const { values, handleBlur, handleChange, handleSubmit, errors, touched } =
        useFormik({
            initialValues: initialValues,
            validationSchema: userSchema,
            onSubmit: (values, action) => {
               
                const formData = new FormData();
                formData.append("userName", values.userName);
                formData.append("email", values.email);
                formData.append("phone", values.phone);
                formData.append("isActive", values.isActive);
                


                addnewprojectMutation.mutate(values);
            },
        });
    //form post
    const addnewprojectMutation = useMutation(querydata.insertUser, {
        onError: (error) => {
            toast.dismiss();
            toast.error(error.message);
        },
        onSuccess: (data) => {
            setIsLoading(false);
           
            toast.dismiss();
            toast.success(data.message);
            navigate("/manage-users");
        },
        onSettled: () => {
            setIsLoading(false); // Set loading to false on success or error
        },
        onMutate: () => {
            setIsLoading(true); // Set loading to true before making the request
        },
    });



    return (
        <>
            <div className="page-body">
                <div className="container-fluid dashboard-default">
                    <div className="page-heading d-flex flex-wrap align-items-center justify-content-between">
                        <nav className="w-100" aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link className="text-decoration-none" to="/manage-users">Manage user</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Create User</li>
                            </ol>
                        </nav>
                        <div className="left-header d-flex align-item-center">
                            <Link to='/manage-users' className="back-arrow d-inline-block me-2"><img src={backarrow} alt="" /></Link>
                            <h4 className="fw-normal m-0 fs-6">Create User</h4>
                        </div>
                    </div>
                    <div className="row mt-md-4 mt-3">
                        <div className="col-md-12">
                            <div className="card mb-4">
                                <div className="card-header bg-transparent border-bottom py-2 d-flex align-items-center justify-content-between">
                                    <h5 className="m-0 fw-medium fs-6">Create New</h5>
                                </div>
                                <div className="card-body">
                                    <div className="upload-file-wrapper">
                                        <form action="#" method="get">
                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <div className="mb-4">
                                                        <label className="form-label" htmlFor="userName">User Name <span className="mandatory">*</span> </label>
                                                        <input name="userName" value={values.userName}
                                                            onChange={handleChange}
                                                            maxLength={20}
                                                            onBlur={handleBlur} type="text" id="userName" className="form-control py-2 shadow-none px-3" />
                                                    </div>
                                                    {errors.userName && touched.userName ? (
                                                        <p className="form-error text-danger">
                                                            {errors.userName}
                                                        </p>
                                                    ) : error.userName ? (
                                                        <p className="form-error text-danger">
                                                            {error.userName}
                                                        </p>
                                                    ) : null}
                                                </div>
                                               
                                                <div className="col-lg-4">
                                                    <div className="mb-4">
                                                        <label className="form-label" htmlFor="email">Email <span className="mandatory">*</span> </label>
                                                        <input name="email"
                                                            value={values.email}
                                                            onChange={handleChange}
                                                            maxLength={100}
                                                            onBlur={handleBlur} type="email" id="email" className="form-control py-2 shadow-none px-3" />
                                                    </div>
                                                    {errors.email && touched.email ? (
                                                        <p className="form-error text-danger">
                                                            {errors.email}
                                                        </p>
                                                    ) : error.email ? (
                                                        <p className="form-error text-danger">
                                                            {error.email}
                                                        </p>
                                                    ) : !errors.email &&
                                                        error.email &&
                                                        touched.email ? (
                                                        <p className="form-error text-danger">
                                                            Please enter email
                                                        </p>
                                                    ) : null}
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="mb-4">
                                                        <label className="form-label" htmlFor="phone">Phone</label>
                                                        <input name="phone" value={values.phone}
                                                            onChange={handleChange}
                                                            maxLength={12}
                                                            onBlur={handleBlur} type="text" id="phone" className="form-control py-2 shadow-none px-3" />
                                                    </div>
                                                    {errors.phone && touched.phone ? (
                                                        <p className="form-error text-danger">
                                                            {errors.phone}
                                                        </p>
                                                    ) : error.phone ? (
                                                        <p className="form-error text-danger">
                                                            {error.phone}
                                                        </p>
                                                    ) : !errors.phone &&
                                                        error.phone &&
                                                        touched.phone ? (
                                                        <p className="form-error text-danger">
                                                            {/* Please enter phone number */}
                                                        </p>
                                                    ) : null}
                                                </div>


                                                <div className="col-12 text-end mt-4">
                                                    {addnewprojectMutation.isLoading ? (<button
                                                        className="btn btn-primary btn-theme fw-semibold text-uppercase px-2 py-1"
                                                        type="button"
                                                        disabled
                                                    >
                                                        <span
                                                            className="spinner-border spinner-border-sm"
                                                            aria-hidden="true"
                                                        ></span>
                                                        <span className="visually-hidden" role="status">
                                                            Loading...
                                                        </span>
                                                    </button>) : (<input onClick={handleSubmit} type="submit" value="Save" className="btn btn-primary btn-theme fw-semibold text-uppercase px-2 py-1" />)}

                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CreateUser
