import { Formik } from "formik";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import pdfImg from "../../assets/images/pdf-doc.svg";

const VenueSittingPlan = (
  {
    initialValues,
    distinctValue = false,
    className,
    name,
    value = [],
    setFieldValue,
    values,
    formik,
    showCropMediaModal,
    restMenu,
    setrestMenu,
  },
  ref
) => {
  //   const { setFieldValue } = form;
  const [inputCount, setInputCount] = useState(
    // value.length
    // value && value.length === 0 ? 1 : value.length
    values && values?.sittingPlanPdfName?.length === 0 ? 1 : values?.sittingPlanPdfName?.length
  );
  if (inputCount === 0) {
    setInputCount(1);
  } else {
  }
  const [selectedValues, setSelectedValues] = useState(values?.sittingPlanPdfName);
  useEffect(() => {
    setInputCount(values?.sittingPlanPdfName?.length);
    setSelectedValues(values?.sittingPlanPdfName);
  }, [values?.sittingPlanPdfName?.length]);

  const handleInputChange = (index, inputValue) => {
    let updated = [...selectedValues];

    updated[index] = inputValue;
    setInputCount(updated.length);

    setSelectedValues(updated);
    // updated[0] = "12anuj"
    formik.resetForm({
      values: {
        ...formik.values,

        sittingPlanPdfName: updated,
      },
    });
  };

  const handleAddClick = (index) => {
    // if (selectedValues[inputCount - 1] !== "") {
    //   setInputCount((prevCount) => prevCount + 1);

    // } else {
    //   toast.error(
    //     "Please enter a value in the current input before adding a new one."
    //   );
    // }
    if (!restMenu[index]) {
      toast.dismiss();
      toast.error("please upload the pdf for the current field ");
      return;
    }

    let update = [...selectedValues];
    if (update[inputCount - 1] === undefined || "") {
      toast.dismiss();
      toast.error(
        "Please enter a value in the current input before adding a new one."
      );
    } else {
      setInputCount((prevCount) => prevCount + 1);
    }
  };

  const handleRemoveClick = (index) => {
    setInputCount((prevCount) => prevCount - 1);
    let update = [...selectedValues];
    update.splice(index, 1);
    setSelectedValues(update);
    formik.resetForm({
      values: {
        ...formik.values,

        sittingPlanPdfName: update,
      },
    });
    if (restMenu.length > 0) {
      restMenu.splice(index, 1);
    }
  };

  const handelRemoveMenuPdf = (e, index) => {
    e.preventDefault();
    const data = [...restMenu];
    data[index]=""
    setrestMenu(data);
  };

  return (
    <>
      {Array.from({ length: inputCount }, (_, index) => (
        <div className="bed-row d-flex flex-wrap align-items-end" key={index}>
          <div className="bed-add-row d-flex flex-wrap w-100">
            <div className="select-bed-col mb-4 d-flex align-items-center">
              <input
                className="form-control py-2 shadow-none px-3"
                type="text"
                name={`input_${index}`}
                id={`input_${index}`}
                value={value[index]}
                onChange={(e) => handleInputChange(index, e.target.value)}
                // className="py-2 shadow-none px-4 form-control "
              />

              <div className="mx-3 position-relative uploaded-file-view">
                {restMenu && restMenu[index] ? (
                  restMenu[index].name === "file.pdf" ||
                  typeof restMenu[index] === "string" ? (
                    typeof restMenu[index] === "string" &&
                    !restMenu[index].endsWith(".pdf") ? (
                      <>
                        <img
                          src={restMenu[index]}
                          alt="alt fot pdf  image "
                          width={"35px"}
                        />
                        <button
                          className="remove-image-button removebtn position-absolute end-0 top-0"
                          onClick={(e) => handelRemoveMenuPdf(e, index)}
                        >
                          <i className="fa fa-times"></i>
                        </button>
                      </>
                    ) : (
                      <a
                        href={
                          restMenu[index].name === "file.pdf"
                            ? URL.createObjectURL(restMenu[index])
                            : restMenu[index]
                        }
                        target="_blank"
                      >
                        <img
                          src={pdfImg}
                          alt="alt fot pdf  image "
                          width={"35px"}
                        />
                        <button
                          className="remove-image-button removebtn position-absolute end-0 top-0"
                          onClick={(e) => handelRemoveMenuPdf(e, index)}
                        >
                          <i className="fa fa-times"></i>
                        </button>
                      </a>
                    )
                  ) : (
                    <>
                      <img
                        src={URL.createObjectURL(restMenu[index])}
                        alt="alt for image"
                        width={"35px"}
                      />
                      <button
                        className="remove-image-button"
                        onClick={(e) => handelRemoveMenuPdf(e, index)}
                      >
                        <i className="fa fa-times"></i>
                      </button>
                    </>
                  )
                ) : (
                  <button
                    className="btn btn-primary px-2 py-2 w-100 outline-btn btn-theme btn btn-primary text-nowrap"
                    type="button"
                    onClick={() => showCropMediaModal("", "retsmenu", index)}
                  >
                    Add Pdf
                  </button>
                )}
              </div>
            </div>

            {index === inputCount - 1 && (
              <>
                <div className="add-more-blocks text-end mb-4">
                  <a
                    onClick={() => {
                      handleAddClick(index);
                    }}
                    className="btn btn-primary btn-theme fw-semibold text-uppercase px-2 py-2"
                  >
                    <i className="fa fa-plus" aria-hidden="true"></i>
                  </a>
                </div>
              </>
            )}
            {index !== inputCount - 1 && (
              <>
                <div className="add-more-blocks text-end mb-2">
                  <a
                    onClick={() => handleRemoveClick(index)}
                    className="btn btn-outline-danger text-uppercase px-2 py-2"
                  >
                    <i className="fa fa-minus"></i>
                  </a>
                </div>
              </>
            )}
          </div>
        </div>
      ))}
    </>
  );
};

export default React.forwardRef(VenueSittingPlan);
