import React, { useState, useEffect } from 'react'
import albumPng from '../../assets/images/album.png';
import { Button } from 'react-bootstrap';
import { components } from 'react-select';
import {  useMutation, useQuery, useQueryClient } from "react-query";
import {  useNavigate } from 'react-router-dom';

import * as query from '../../services/service'
import Pagination from '../../components/Pagination';

import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as querydata from "../../services/service";
import { toast } from "react-toastify";


export const albumSchema = Yup.object({
    albumTitle: Yup.string().typeError("Album Title must be a string")
        .trim()
        .required("Please enter Album Title"),

});
const { Option } = components;


const MultiValueLabel = (props) => (
    <Option {...props}>
        <input type="checkbox" checked={props.isSelected} onChange={() => null} />
        {props.label}
    </Option>
);
const ManageAlbum = ({ folderId, setAlbumId, setwebsiteId, setFolderId }) => {
    //function ManageAlbum(folderId,{setAlbumId, setwebsiteId}) {
    const queryClient = useQueryClient();
    // const { websiteId } = useParams();
    const websiteId = folderId;
   
    const getMediaAlbumFolData = useQuery(
        ["getMediaAlbumFolData", +websiteId],
        () => query.getMediaAlbumByWebId(websiteId)
    );
    const navigate = useNavigate();
    //const { websiteId } = useParams();
    const [error, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [filterValue, setFilterValue] = useState('');
    const [selectedMediaAlbumId, setSelectedMediaAlbumId] = useState('');
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const handleAlbumSelection = (selected, website) => {
        // If "Select All" is selected, set all options as selected.
        // Otherwise, update the selected options.
        setAlbumId(selected);
        setwebsiteId(website);
        
        // setSelectedOptions(selected.some((item) => item.value === 'all') ? options.slice(1) : selected);
    };
    const navigatetoFolder = () => {
        // If "Select All" is selected, set all options as selected.
        // Otherwise, update the selected options.
        setAlbumId('');
        setwebsiteId('');
        setFolderId('');

       
    };

    const options = [
        { value: 'all', label: 'Select All' },
        { value: '3', label: 'Photos' },
        { value: '4', label: 'Videos' },
    ];


    const handleSelectChange = (selected) => {
        // If "Select All" is selected, set all options as selected.
        // Otherwise, update the selected options.
        setSelectedOptions(selected.some((item) => item.value === 'all') ? options.slice(1) : selected);
    };

    // Handler function for filter value
    const handleFilterChange = (e) => {
        setFilterValue(e.target.value);
    };

    // Start line For pagination
    const itemsPerPage = 10; // Number of items to display per page
    const totalPages = Math.ceil(getMediaAlbumFolData?.data?.data.length / itemsPerPage);
    const handlePageChange = (newPage) => {
        // Validate newPage to ensure it's within the valid range of pages
        if (newPage >= 1 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };
    // Ensure that data is an array before using slice
    const dataArray = Array.isArray(getMediaAlbumFolData?.data?.data) ? getMediaAlbumFolData?.data?.data : [];
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const albumtData = dataArray.slice(startIndex, endIndex);
    // End line For pagination
    const totalItems = dataArray.length;

    //For update open modal
    const handleShow = (mediaAlbumId) => {
        setSelectedMediaAlbumId(prevvalues => {
            return mediaAlbumId;
        })
        setShow(true);
    };


    const [getMediaAlbum, setGetMediaAlbum] = useState(null);

    useEffect(() => {
        if (selectedMediaAlbumId !== null) {
            const fetchData = async () => {
                try {
                    const result = await querydata.getMediaAlbumNameById(selectedMediaAlbumId);
                    setGetMediaAlbum(result);
                    // Process the result or update state as needed
                } catch (error) {
                    // Handle errors
                }
            };

            fetchData();
        }
    }, [selectedMediaAlbumId]);

  

    let initialValues = {
        albumTitle: getMediaAlbum && getMediaAlbum.data && getMediaAlbum.data[0] && getMediaAlbum.data[0].media_album || ""
    };

    useEffect(() => {
        setValues({ ...initialValues });
    }, [getMediaAlbum]);

    const { values, handleBlur, handleChange, handleSubmit, errors, touched, setValues } =
        useFormik({
            initialValues: initialValues,
            validationSchema: albumSchema,
            onSubmit: (values, action) => {
                const formData = new FormData();

                formData.append("albumTitle", values.albumTitle);
               
                if (selectedMediaAlbumId > 0) {
                    updateprojectMutation.mutate({ formData: values.albumTitle, mediaAlbumId: selectedMediaAlbumId });

                } else {
                    if (values.albumTitle !== "" || websiteId > 0) {
                        addnewprojectMutation.mutate({ formData: values.albumTitle, websiteId: websiteId });
                    } else {
                        toast.dismiss();
                        toast.error('Please contact to administrator');
                    }
                }

                action.resetForm();
            },
        });



    const addnewprojectMutation = useMutation(querydata.insertMediaAlbumName, {
        onError: (error) => {
            toast.dismiss();
            toast.error(error.message);
        },
        onSuccess: (data) => {
            setIsLoading(false);
            toast.dismiss();
            toast.success(data.message);
            handleClose();
            queryClient.invalidateQueries('getMediaAlbumFolData');
        },
        onSettled: () => {
            setIsLoading(false); // Set loading to false on success or error
        },
        onMutate: () => {
            setIsLoading(true); // Set loading to true before making the request
        },
    });
    const updateprojectMutation = useMutation(querydata.updateMediaAlbumName, {
        onError: (error) => {
            toast.dismiss();
            toast.error(error.message);
        },
        onSuccess: (data) => {
            setIsLoading(false);
            toast.dismiss();
            toast.success(data.message);
            handleClose();
            queryClient.invalidateQueries('getMediaAlbumFolData');
        },
        onSettled: () => {
            setIsLoading(false); // Set loading to false on success or error
        },
        onMutate: () => {
            setIsLoading(true); // Set loading to true before making the request
        },
    });
    let albumHeading = "Add Album"
    if (selectedMediaAlbumId > 0) {
        albumHeading = "Edit Album";
    }

    return (
        <>
            <div className="">
                <div className="container-fluid dashboard-default">
                    <div className="page-heading d-flex flex-wrap align-items-center justify-content-between">
                        <nav className="w-100" aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a className="text-decoration-none"  onClick={navigatetoFolder}>Manage Media</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Manage Album</li>
                            </ol>
                        </nav>
                        <div className="left-header">
                            <h4 className="fw-normal m-0 fs-5">Manage <strong>Album</strong></h4>
                        </div>
                        <div className="header-action">
                         
                        </div>

                    </div>
                    <div className="row mt-md-4 mt-3">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header bg-transparent border-bottom py-2 d-flex align-items-center justify-content-between">
                                    <div className="left-header">
                                        
                                    </div>
                                    <div className="right-action d-flex">
                                        <div className="atmosphere-hr ms-2 me-2">
                                            <div className="position-relative multiselect-box ">
                                                <div className='ms-options-wrap'>

                                               

                                                </div>
                                            </div>
                                        </div>
                                        <div className="atmosphere-hr filter-album">
                                           
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="gallery-assets">
                                        <ul className="list-unstyled m-0 p-0 d-flex flex-wrap align-items-center">

                                            {albumtData && albumtData.map(album =>

                                            (

                                                <li key={album.media_album_id}>
                                                    <div className="album-view border rounded-3 position-relative overflow-hidden">
                                                        <div className="album-set-icon">
                                                         
                                                            <a
                                                                onClick={() =>
                                                                    handleAlbumSelection(album.media_album_id, websiteId)
                                                                }
                                                                className="position-relative text-decoration-none text-white d-flex px-2 py-3">
                                                                <img src={albumPng} alt="Album" title="Album" />
                                                                <div className="album-title position-absolute start-0 w-100 z-1 bottom-0 py-3 px-3 text-center">
                                                                    <h6 className="mb-0">{album.media_album || 'No Folder'}</h6>
                                                                    <div className="created-info mt-3 d-flex justify-content-between align-items-center">
                                                                        <span>By-: {album.user_name}</span>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </div>

                                                </li>
                                            )
                                            )}
                                        </ul>
                                        <div className="atmosphere-pagination album-pagination mt-4 d-flex justify-content-end">
                                            <nav aria-label="...">
                                                <ul className="pagination m-0 d-flex justify-content-end">
                                                    <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} visiblePages={itemsPerPage} totalItems={totalItems} />
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal size='xl' show={show} onHide={handleClose}>
                <Modal.Header className="modal-header py-2" closeButton>
                    <Modal.Title className="modal-title fs-5" >

                        {albumHeading}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="">
                        <div className="upload-file-wrapper">
                            <form >
                                <div className="row">
                                    {(
                                        <div className="col-12 mb-4">
                                            <label className="form-label" htmlFor="Name">Album Title</label>
                                            <div className="mb-4">

                                                <input type="text" name="albumTitle" value={values.albumTitle}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur} className="form-control py-2 shadow-none px-3" maxLength={100} />


                                                {errors.albumTitle && touched.albumTitle ? (
                                                    <p className="form-error text-danger">
                                                        {errors.albumTitle}
                                                    </p>
                                                ) : error.albumTitle ? (
                                                    <p className="form-error text-danger">
                                                        {error.albumTitle}
                                                    </p>
                                                ) : null}
                                            </div>
                                        </div>
                                    )
                                    }

                                </div>
                                <div className="col-12 pt-5 text-end mb-2">
                                    <Button onClick={handleClose} data-bs-dismiss="modal" value="Cancel" className="btn btn-primary btn-theme outline-btn text-uppercase px-3 py-2 me-1" >Cancel</Button>
                                    <input onClick={handleSubmit} type='submit' value='Submit' className="btn btn-primary btn-theme fw-semibold text-uppercase px-2 py-1" />
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default ManageAlbum
