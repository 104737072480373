import { React, useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import { faPlus, faTrash, faTrashAlt, faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "react-bootstrap";
import MultiRestaurantDropDown from "./MultiRestaurantDropDown";
import Features from "./IncrementedInput";
import { toast } from "react-toastify";
import { Prev } from "react-bootstrap/esm/PageItem";
import { data } from "jquery";
import { HiPlus } from "react-icons/hi";
import { useContext } from "react";
import MultipleImageAltTags from "./MultipleImageAltTags";
import { getMandatoryFields, getExtraFields } from "../../mandatoryfileds";
import { GlobalContext } from "../../globalContext";

export default function RestaurantDetail({
  formik = "",
  values,
  setValues,
  dataArray = [],
  setDataArray,
  maincroppedThirdImage,
  image3,
  image,
  initialValues,
  showCropMediaModal,
  handleThirdRemoveImage,
  handleRemoveImage,
  getFieldProps,
  setFieldValue,
  setRestaurandDetailDataArray,
  restaurandDetailDataArray,
  setInitialValues,
  maincroppedThirdFile,
  setmainCroppedThirdFile,
  handleChange="",
  // handleToggleDetailTagModal,
  // handleCloseDetailImgTagModal,
  // showDetailImgTagModal,
  // detailAltTagId,
  // setDetailAltTagId
}) {

  const { errors, touched } = formik
  const { bwebsiteId } = useContext(GlobalContext)
  const moduleName = "dinning";
  // Get the mandatory fields for the specific websiteId and moduleName
  const mandatoryFields = getMandatoryFields(bwebsiteId, moduleName);
  const extraFields = getExtraFields(bwebsiteId, moduleName);

  const [newItemName, setNewItemName] = useState({
    id: Math.random(),
    name: "",
    tagImage: "",
    tagHeading: "",
    tagDescription: "",
    tagFeatures: [],//condition
    detailImgAltTag: ""

  });
  const [featureDropdowns, setFeatureDropdowns] = useState([]);
  const [FeatureValues, setFeatureValues] = useState([]);
  const [descriptions, setDescriptions] = useState([]);
  const [show, setShow] = useState(false);
  const [imageArray, setImageArray] = useState(maincroppedThirdFile)
  const [err, setErr] = useState(false);
  const [name, setName] = useState("")
  const [index, setIndex] = useState("")
  const [showEdit, setShowEdit] = useState(false);
  const [showWarning, setshowWarning] = useState(false);



  useEffect(() => {
    if (values.dataArray && values.dataArray.length > 0) {
      // Initialize descriptions state with tagDescription values
      setDescriptions(values.dataArray.map(data => data.tagDescription || ""));
    }
  }, [values.dataArray.length]);
  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setShowEdit(false)
  };
  const error = {}
  const handleSave = () => {

    if (newItemName.name.trim().length === 0) {
      setErr(true)
    }
    if (newItemName.name.trim() !== "") {
      setErr(false)
      // setDataArray((seprevArray) => [...prevArray, newItemName]);
      // setRestaurandDetailDataArray((prevArray) => [...prevArray, newItemName]);


      setNewItemName({
        id: Math.random() * 1000,
        name: "",
        tagImage: "",
        tagHeading: "",
        tagDescription: "",
        tagFeatures: [],//condition
        detailImgAltTag: ""



      });
      setRestaurandDetailDataArray(values?.dataArray)
      setRestaurandDetailDataArray((prevArray) => [...prevArray, newItemName]);
      // setValues()
      setShow(false);
    } else {
      setErr(true)
    }
  };
  const handleDescriptionChange = (e, index) => {

    const updatedDescriptions = [...descriptions];
    updatedDescriptions[index] = e.target.value;
    setDescriptions(updatedDescriptions);
    setFieldValue(
      `dataArray[${index}].tagDescription`,
      e.target.value
    );
  };

  // const handleChangeImageAltTag = (e, index) => {

  //   const updatedAltTag = [...imageAltTag];
  //   updatedAltTag[index] = e.target.value;
  //   setImageAltTag(updatedAltTag);
  //   setFieldValue(
  //     `dataArray[${index}].detailImgAltTag`,
  //     e.target.value
  //   );
  // };

  const [showDetailImgTagModal, setShowDetailImgTagModal] = useState(false);
  const [detailAltTagId, setDetailAltTagId] = useState(0);

  const handleToggleDetailTagModal = (id) => {
    setDetailAltTagId(id);
    setShowDetailImgTagModal(true);
  };


  const handleCloseDetailImgTagModal = () => {
    setShowDetailImgTagModal(false);
  };


  const handleSaveImageAltTag = (index, value) => {
    // You can perform any necessary actions here, such as updating state or making API calls


    if (value !== undefined) {

      setFieldValue(
        `dataArray[${index}].detailImgAltTag`,
        value
      );
    } else {
      console.error('Invalid event or event target');
    }

    handleCloseDetailImgTagModal();
  };


  const handleRemoveTab = (index) => {
    toast.dismiss();

    if (index) {
      toast.success("successfully deleted")

    }
    if (index === detailAltTagId) {
      // Reset detailAltTagId if the deleted tab was the one being referred to
      setDetailAltTagId(Math.max(0, detailAltTagId - 1)); // Ensure detailAltTagId is not negative
    }
    // If all tabs are deleted, set detailAltTagId to 0
    if (values.dataArray.length === 1) {
      setDetailAltTagId(0);
    }
    deleteMaincorppedThird(index)

    setRestaurandDetailDataArray(values?.dataArray)


    setRestaurandDetailDataArray((prevArray) => prevArray.filter((_, i) => i !== index));



  };
  const deleteMaincorppedThird = (index) => {
    if (maincroppedThirdFile === null) {
      return
    }

    const update = Object.values(maincroppedThirdFile)

    const check = update.filter((item, ind) => ind !== index)

    setImageArray(check)
    setmainCroppedThirdFile(check)
  }


  const handleRemoveFeatureDropdown = (dropdown) => {
    const parentDiv = document.querySelector(
      `.row.position-relative[data-key="${dropdown}"]`
    );
    if (parentDiv) {
      parentDiv.remove();
    }
    //condition start
    if (extraFields.includes('tagFeatures')) {
      const data = values.tagFeatures;
      const keyToRemove = dropdown; // You might get the key dynamically or hardcode it

      // Check if the key exists before removing
      if (data.hasOwnProperty(keyToRemove)) {
        delete data[keyToRemove];

        //  updateDisplay();
      } else {
      }
    }
    //condition end
  };
  const handelHeading = (index) => {
    // setName(values?.dataArray[index]?.name)
    setShowEdit(true)
    setName(values?.dataArray[index]?.name)
    setIndex(index)
    // handleShow(true)



  }
  const handleSave2 = () => {

    const trimmedNmae = name.trim("")

    if (trimmedNmae.length === 0) {
      setshowWarning(true)
      return
    }
    const data = { ...values?.dataArray }

    data[index].name = name
    setShowEdit(false)
    setshowWarning(false)
    setFieldValue(`values.dataArray`, data)

  }


  return (
    <>
      <div className="section-tabs mt-3">
        <p className="m-0 mb-2 form-label ">Ambience</p>
      {bwebsiteId ==="TOC" && ( 
        <>
        <Col lg={6}>
          <Form.Group className="mb-3" controlId="slug Name">
            <Form.Label>
              Title
              <span className="mandatory">*</span>
            </Form.Label>
            <div className="bs-example">
              <input
                // ref={slugRef}
                onChange={handleChange}
                onBlur={formik.handleBlur}
                type="text"
                maxLength={100}
                name="ambienceTitle"
                value={values?.ambienceTitle?.trim()}
                id="ambienceTitle"
                className="form-control py-2 shadow-none px-3"
                // onKeyPress={handleKeyPress}
                data-role="ambienceTitle"
              />
            </div>
            {errors.ambienceTitle && touched.ambienceTitle ? (
              <p className="form-error text-danger">
                {errors.ambienceTitle}
              </p>
            ) : error.ambienceTitle ? (
              <p className="form-error text-danger">
                {error.ambienceTitle}
              </p>
            ) : null}
          </Form.Group>
        </Col>
        <Col lg={12}>
          <Form.Group className="mb-3" controlId="ambienceDescription">
            <Form.Label>
              Description
              {/* <span className="mandatory">*</span>{" "} */}
            </Form.Label>
            <Form.Control
              as="textarea"
              style={{ height: "100px" }}
               onChange={handleChange}
              // ref={RestaurantDescriptionRef}
              value={values.ambienceDescription}
              name="ambienceDescription"
            //  onBlur={formik.handleBlur}
            // maxLength={30}
            />
            {/* {errors.RestaurantDescription &&
                            touched.RestaurantDescription ? (
                              <p className="form-error text-danger">
                                {errors.RestaurantDescription}
                              </p>
                            ) : error.RestaurantDescription ? (
                              <p className="form-error text-danger">
                                {error.RestaurantDescription}
                              </p>
                            ) : !errors.RestaurantDescription &&
                              error.RestaurantDescription &&
                              touched.RestaurantDescription ? (
                              <p className="form-error text-danger">
                                Please select a restaurant description
                              </p>
                            ) : null} */}
          </Form.Group>
        </Col>
        </> )}
        <Tab.Container id="left-tabs-example" defaultActiveKey="0">
          <Row>
            <Col xxl={3} lg={4}>
              <div className="left-tabs">
                <Nav variant="pills" className="flex-column">
                  {/* <Nav.Item>
                    <Nav.Link
                      eventKey={"AmbienceTab"}
                      className="nav-item w-100 position-relative rounded-0"
                    >
                      Ambience Heading and description
                    </Nav.Link>
                  </Nav.Item> */}
                  {values?.dataArray?.map((item, index) => (
                    <div key={index}>
                      <Nav.Item>
                        <Nav.Link
                          eventKey={index}
                          className="nav-item w-100 position-relative rounded-0"
                        >
                          {item.name}
                          <span className="delete-tab text-dark position-absolute end-0 top-0 h-100 d-flex align-items-center px-3">
                            <span className="px-3 ">
                              <FontAwesomeIcon
                                icon={faPencil}
                                onClick={() => handelHeading(index)}
                              />
                            </span>
                            <FontAwesomeIcon
                              icon={faTrashAlt}
                              onClick={() => handleRemoveTab(index)}
                            />
                          </span>
                        </Nav.Link>
                      </Nav.Item>
                    </div>
                  ))}
                </Nav>
                <div className="facility-view addmore-facility">
                  <Button
                    type="button"
                    className="btn btn-primary px-3 py-3 w-100 outline-btn btn-theme"
                    onClick={handleShow}

                  >
                    <FontAwesomeIcon icon={faPlus} /> Add
                  </Button>
                </div>
              </div>
            </Col>

            <Col xxl={9} lg={8}>

              <Tab.Content>
                {values?.dataArray?.map((data, index) => (
                  <>
                    <Tab.Pane eventKey={index} key={index}>
                      <Form>
                        <Row>
                          <Col lg={12}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <div className="d-flex align-items-center">

                                <Form.Label className="mb-0"> Tag Image <span className="mandatory">*</span></Form.Label>
                                <a title="Add image alt tag" className="btn image-tag" onClick={() => handleToggleDetailTagModal(index)}>
                                  <HiPlus />
                                </a>
                              </div>

                              {!image3[`section_${data.id}`] ? (
                                <div className="uploaded-property-img d-flex">
                                  <label
                                    htmlFor="UploadCover"
                                    className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5"
                                    onClick={() =>
                                      showCropMediaModal(`section_${data.id}`, "thirdImage", index)
                                    }
                                  >
                                    <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                                      <Button
                                        type="button"
                                        className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                                      >
                                        Choose from gallery
                                      </Button>
                                    </div>
                                  </label>
                                </div>
                              ) : (
                                ""
                              )}

                              {image3 && image3[`section_${data.id}`] ? (
                                <div
                                  className="uploaded-img-view"
                                  style={{
                                    height: "200px",
                                    width: "200px",
                                    position: "relative",
                                  }}
                                >
                                  <div>
                                    <a
                                      href={image3[`section_${data.id}`]}
                                      target="_blank"
                                    >
                                      <img
                                        src={image3[`section_${data.id}`]}
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          objectFit: "cover",
                                        }}
                                      />
                                    </a>
                                    <div
                                      style={{
                                        position: "absolute",
                                        top: "0",
                                        right: "0",
                                      }}
                                    >
                                      <button
                                        className="remove-image-button"
                                        onClick={(e) =>
                                          handleThirdRemoveImage(e,
                                            `section_${data.id}`, index
                                          )
                                        }
                                      >
                                        <i className="fa fa-times"></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </Form.Group>
                          </Col>

                          {/* <Col lg={12}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Heading <span className="mandatory">*</span> </Form.Label>
                              <Form.Control
                                type="text"
                                className="py-2 shadow-none px-3"
                                // value={data.name}
                                value={values.dataArray[index]?.name || ""}
                                onChange={(e) =>
                                  setFieldValue(
                                    `dataArray[${index}].name`,
                                    e.target.value
                                  )

                                }
                                maxLength={20}
                              />
                              {touched.dataArray && errors?.dataArray ? (
                                <p className="form-error text-danger">
                                  {errors.dataArray.map((error, i) => (

                                    // i === index ? <span key={i}>{error.name}</span> : []
                                    i === index && error && <span key={i}>{error.name}</span>

                                  ))}


                                </p>
                              ) : null}
                            </Form.Group>
                          </Col> */}
                         
                          <Col lg={12}>
                            <Form.Group
                              className="mb-3"
                              controlId="Inner Tab title "
                            >
                             {/* this is description but i use as title  */}
                              <Form.Label>
                              {bwebsiteId ==="TOC"? ("Title"):"Description"} 
                              {mandatoryFields.includes('tagDescription') && <span className="mandatory">*</span>}</Form.Label>
                             { bwebsiteId ==="TOC" ? 
                             <Form.Control
                                // as="textarea"
                                type="text"
                                style={{ height: "60px" }}
                                value={descriptions[index]}
                                maxLength={100}
                                onChange={(e) => handleDescriptionChange(e, index)}
                              />:
                             <Form.Control
                               as="textarea"                              
                                style={{ height: "100px" }}
                                value={descriptions[index]}
                                // maxLength={100}
                                onChange={(e) => handleDescriptionChange(e, index)}
                              />
                              
                              }
                              {/* {errors.dataArray ? (
                                <p className="form-error text-danger">
                                  {errors.dataArray.map((error, i) => (

                                    i === index ? <span key={i}>{error.tagDescription}</span> : null

                                  ))}

                                </p>
                              ) : null} */}
                              {/* {errors?.dataArray ? (
                                <p className="form-error text-danger">
                                  {errors.dataArray.map((error, i) => (

                                    // i === index ? <span key={i}>{error.name}</span> : []
                                    i === index && error && <span key={i}>{error.tagDescription}</span>

                                  ))}


                                </p>
                              ) : null} */}

                              {touched.dataArray && errors.dataArray ? (
                                <p className="form-error text-danger">
                                  {errors.dataArray.map((error, i) => (
                                    i === index && error && <span key={i}>{error.tagDescription}</span>
                                  ))}
                                </p>
                              ) : null}

                            </Form.Group>
                          </Col>
                          {extraFields.includes('tagFeatures') &&
                            <Col lg={12}>
                              <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Label>Features <span className="mandatory">*</span> </Form.Label>
                                <Features

                                  setFieldValue={setFieldValue}

                                  value={
                                    getFieldProps(
                                      `dataArray[${index}].tagFeatures`
                                    ).value
                                  }
                                  name={`dataArray[${index}].tagFeatures`}
                                  placeholder="Enter feature"
                                  maxLength={20}
                                  values={values}
                                  ind={index}


                                />
                                {touched.dataArray && errors?.dataArray ? (
                                  <p className="form-error text-danger">
                                    {errors.dataArray.map((error, i) => (

                                      // i === index ? <span key={i}>{error.name}</span> : []
                                      i === index && error && <span key={i}>{'Please add tag Feature'}</span>

                                    ))}


                                  </p>
                                ) : null}

                              </Form.Group>
                            </Col>}
                        </Row>
                      </Form>
                    </Tab.Pane>
                  </>
                ))}
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="py-2" closeButton>
          <Modal.Title className="fs-5">Add Name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formName">
            <Form.Label>Name:</Form.Label>
            <Form.Control
              type="text"
              // placeholder="Enter"
              maxLength={20}
              value={newItemName.name}
              onChange={(e) =>
                setNewItemName({ ...newItemName, name: e.target.value })
              }
            />
            {
              err ? newItemName.name.trim().length > 0 ? '' : <p className="form-error text-danger">please enter tag name  </p> : ''
            }

          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className=" text-uppercase btn-sm" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" className=" text-uppercase btn-sm" onClick={handleSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>




      {/* edit modal  */}

      <Modal show={showEdit} onHide={handleClose}>
        <Modal.Header className="py-2" closeButton>
          <Modal.Title className="fs-5">Edit Name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formName">
            <Form.Label>Name:</Form.Label>
            <Form.Control
              type="text"
              // placeholder="Enter"
              maxLength={20}
              value={name}
              onChange={(e) => {
                setName(e.target.value)
              }

              }
            />
            {showWarning ? <p className="form-error text-danger">please enter tag name  </p> : ""}


          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="text-uppercase btn-sm" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" className="text-uppercase btn-sm" onClick={handleSave2}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>


      <MultipleImageAltTags
        showModalImageTagModal={showDetailImgTagModal}
        handleCloseImageTagModal={handleCloseDetailImgTagModal}
        // initialImageAltTag={initialValues.dataArray.length > 0 && initialValues.dataArray[0].detailImgAltTag ? initialValues.dataArray[detailAltTagId].detailImgAltTag : ""}  
        // initialImageAltTag={values.dataArray[detailAltTagId].detailImgAltTag||""} 
        initialImageAltTag={values.dataArray.length > 0 ? values.dataArray[detailAltTagId]?.detailImgAltTag || "" : ""}
        tagType={detailAltTagId}
        handleSaveImageAltTag={handleSaveImageAltTag}
        inputName={detailAltTagId}
      />

    </>
  );
}
