import React from "react";
import { useMutation, useQuery } from "react-query";
import { useParams, useNavigate,Link } from "react-router-dom";
import { useState, useEffect } from "react";

import { getNavigation } from "../dataService/DataService";
import * as querydata from "../dataService/DataService";

import { toast } from "react-toastify";


const ManageNavigationPermission = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const { data } = useQuery(["getNavigation", +id], () =>
        getNavigation(id)
    );
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);

    const [checkedItems, setCheckedItems] = useState(() => {
        const initialState = {};
        if (data && data.subItems) {
            data.subItems.forEach(data1 => {
                initialState[`checkbox-${data1.id}`] = data1.is_navigation_active;
            });
        }
        return initialState;
    });
    const handleCheckboxChange = (event, childItemId, parent) => {
        // const { id, checked } = event.target;
        setIsButtonEnabled(true);
    

        const { checked } = event.target;
        setCheckedItems((prevItems) => {
            const updatedItems = {
                ...prevItems,
                [`checkbox-${childItemId}`]: checked,
            };

            // Check if all child checkboxes are checked, then check the parent checkbox
            const allChildrenChecked = parent.subItems.every(
                (child) => updatedItems.hasOwnProperty(`checkbox-${child.navigation_item_id}`)
                    ? updatedItems[`checkbox-${child.navigation_item_id}`]
                    : child.is_navigation_active
                // (child) =>child.is_navigation_active? updatedItems[`checkbox-${child.navigation_item_id}`]:child.is_navigation_active
            );

            // Update the parent checkbox state
            updatedItems[`checkbox-${parent.navigation_item_id}`] = allChildrenChecked;
        
            return updatedItems;
        });
      
    };
    const handleParentCheckboxChange = (event, parent, childItems) => {
        const { checked } = event.target;
        const updatedItems = { ...checkedItems };
        setIsButtonEnabled(true);
        // Update parent checkbox
        updatedItems[`checkbox-${parent.navigation_item_id}`] = checked;

        // Update child checkboxes based on parent state
        if (childItems && childItems.length > 0) {
            childItems.forEach((child) => {
                updatedItems[`checkbox-${child.navigation_item_id}`] = checked;
            });
        }

        setCheckedItems(updatedItems);
        // Do something with the checked state, e.g., update your data object
        // For example: updateDataItem(data1.id, checked);
    };

    //   UpdateUserRoleNavigationPermission
    const checkboxes = Array.from(document.querySelectorAll('input[type="checkbox"]'));
    const newData = checkboxes.map(checkbox => {
        return {
            userRoleId: id,
            isActive: checkbox.checked ? 1 : 0,
            navigationItemId: parseInt(checkbox.getAttribute("data-navigation-item-id"), 10)
        };
    });
    const [userRoleNavigationData, setUserRoleNavigationData] = useState(newData);

    useEffect(() => {

        setUserRoleNavigationData(newData);
    }, [checkedItems]); // Empty dependency array ensures that the effect runs once after the initial render



    const handleSubmit = async () => {
        userRoleNavigationPermissionMutation.mutate(newData);

    };


    // Form post
    const userRoleNavigationPermissionMutation = useMutation(querydata.UpdateUserRoleNavigationPermission, {
        onError: (error) => {
            toast.dismiss();
            toast.error(error.message);
        },

        onSuccess: (data) => {
            toast.dismiss();
            toast.success("Navigation Updated Successfully");
            navigate("/manage-roles")
        },
    });

    

    return (

        <>
            <div className="page-body">
                <div className="container-fluid dashboard-default">

                    <div className="page-heading d-flex flex-wrap align-items-center justify-content-between">
                        <nav className="w-100" aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link className="text-decoration-none" to="/manage-roles">Manage Roles</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Assign Navigation to Role</li>
                            </ol>
                        </nav>
                        <div className="left-header">
                            <h4 className="fw-normal m-0 fs-6">Role Navigation</h4>
                        </div>
                    </div>

                    <div className="row mt-md-4 mt-3">
                        <div className="col-md-12">
                            <div className="card mb-4">
                                <div className="card-header bg-transparent border-bottom py-2 d-flex align-items-center justify-content-between">
                                    <h5 className="m-0 fw-medium fs-6">Assign Navigation to Role</h5>
                                    <div className="right-actions">
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="content">

                                        <div className="card border-0">
                                        
                                            <div className="card-body">
                                                <div className="permission-view mb-4">
                                                    <div className="row">
                                                        {data && data.data[0] && data.data[0].navigation && data.data[0].navigation.map((data) => (
                                                            <div key={`parent-${data.navigation_item_id}`} className="col-xl-3 col-md-6">
                                                                <ul className="roles-view list-unstyled w-100 rounded-2">
                                                                    <li>
                                                                        <label htmlFor="MCheckOne" className="main-navigation d-flex align-items-center w-100">
                                                                            <div className="check-box">
                                                                                <input type="checkbox" name="" id={`checkbox-${data.navigation_item_id}`} data-navigation-item-id={data.navigation_item_id}
                                                                                    checked={
                                                                                        checkedItems.hasOwnProperty(`checkbox-${data.navigation_item_id}`)
                                                                                            ? checkedItems[`checkbox-${data.navigation_item_id}`]
                                                                                            : data.is_navigation_active
                                                                                    }
                                                                                    onChange={event => handleParentCheckboxChange(event, data, data.subItems)} />
                                                                            </div>
                                                                            <div className="navigation-name ms-2">
                                                                                <span className="fw-semibold">{data.navigation_item}</span>
                                                                            </div>
                                                                        </label>
                                                                        {/* <ol className="list-unstyled ps-4 opacity-75">
                                                                            {data && data.subItems && data.subItems.map((data1) => (
                                                                                <li key={data1.navigation_item_id}>
                                                                                    <label htmlFor="SCheckOne" className="main-navigation d-flex align-items-center w-100">
                                                                                        <div className="check-box">
                                                                                            <input type="checkbox" name="" id={`checkbox-${data1.navigation_item_id}`} data-navigation-item-id={data1.navigation_item_id}
                                                                                                checked={
                                                                                                    checkedItems.hasOwnProperty(`checkbox-${data1.navigation_item_id}`)
                                                                                                        ? checkedItems[`checkbox-${data1.navigation_item_id}`]
                                                                                                        : data1.is_navigation_active
                                                                                                }
                                                                                                onChange={event => handleCheckboxChange(event, data1.navigation_item_id, data)} />
                                                                                        </div>
                                                                                        <div className="navigation-name ms-2">
                                                                                            <span>{data1.navigation_item}</span>
                                                                                        </div>
                                                                                    </label>
                                                                                </li>
                                                                            ))}
                                                                        </ol> */}
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        ))}
                                                        {/* <!-- /col-md-3 --> */}
                                                    </div>
                                                    {/* <!-- /row --> */}
                                                </div>
                                                {/* <!-- /card-body --> */}
                                            </div>

                                            <div className="bottom-actions mt-4 text-end">

                                                <button type="button" onClick={handleSubmit} disabled={!isButtonEnabled} value="Save" className="btn btn-primary btn-theme fw-semibold text-uppercase px-2 py-1">Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </>

    )


};

export default ManageNavigationPermission;