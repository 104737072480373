import { useParams, useNavigate, Link } from "react-router-dom";
import React, {  useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation, useQuery } from "react-query";

import * as querydata from "../../services/service";
import 'react-datepicker/dist/react-datepicker.css';

export const userSchema = Yup.object({
    userName: Yup.string().required("Please enter user name")
    .min(4, "User name must be at least 4 character long"),
    email: Yup.string().email().required("Please enter email"),
    phone: Yup.string().matches(/^[0-9]+$/, "Invalid phone number"),
});
const EditUser = () => {
    const navigate = useNavigate();    
    const [error, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
   
    const { userId } = useParams();
    const  getSingleUserData = useQuery(
        ["SingleUserData", +userId],
        () => querydata.getSingleUser1Data(userId)
    );




    const initialValues = {
        userName: getSingleUserData && getSingleUserData.data && getSingleUserData.data.data && getSingleUserData.data.data.user_name ? getSingleUserData.data.data.user_name : "",
        
        email:getSingleUserData && getSingleUserData.data && getSingleUserData.data.data && getSingleUserData.data.data.email,
        phone: getSingleUserData && getSingleUserData.data && getSingleUserData.data.data && getSingleUserData.data.data.phone,
        isActive: getSingleUserData && getSingleUserData.data && getSingleUserData.data.data && getSingleUserData.data.data.is_active,
    };
    useEffect(() => {
        setValues({ ...initialValues })
       
    }, [getSingleUserData.data])



    const { values, handleBlur, handleChange, handleSubmit, errors, touched, setValues } =
        useFormik({
            initialValues: initialValues,
            validationSchema: userSchema,
            onSubmit: (values, action) => {  
                const formData = new FormData();
                formData.append("userName", values.userName);
                formData.append("email", values.email);
                formData.append("phone", values.phone);               
                formData.append("isActive", values.isActive);         
                 updateprojectMutation.mutate({userId, formData});
            },
        });


    const updateprojectMutation = useMutation(querydata.updateUser, {
        onError: (error) => {
            toast.dismiss();
            toast.error(error.message);
        },
        onSuccess: (data) => {
            setIsLoading(false);
           
            toast.dismiss();
            toast.success(data.message);
            navigate("/manage-users");
        },
        onSettled: () => {
            setIsLoading(false); // Set loading to false on success or error
        },
        onMutate: () => {
            setIsLoading(true); // Set loading to true before making the request
        },
    });

    if (!getSingleUserData.data) {
        return <div>Loading....</div>
    }


    return (
        <>
            <div className="page-body">
                <div className="container-fluid dashboard-default">
                    <div className="page-heading d-flex flex-wrap align-items-center justify-content-between">
                        <nav className="w-100" aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link className="text-decoration-none" to="/manage-offers">Manage Users</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Edit User</li>
                            </ol>
                        </nav>
                    
                    </div>
                    <div className="row mt-md-4 mt-3">
                        <div className="col-md-12">
                            <div className="card mb-4">
                                <div className="card-header bg-transparent border-bottom py-2 d-flex align-items-center justify-content-between">
                                    <h5 className="m-0 fw-medium fs-6">Edit User </h5>
                                </div>
                                <div className="card-body">
                                    <div className="upload-file-wrapper">
                                        <form action="#" method="get">
                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <div className="mb-4">
                                                        <label className="form-label" htmlFor="userName">User Name</label>
                                                        <input name="userName" value={values.userName}
                                                            onChange={handleChange}
                                                            maxLength={15}
                                                            onBlur={handleBlur} type="text" id="userName" className="form-control py-2 shadow-none px-3" />
                                                    </div>
                                                    {errors.userName && touched.userName ? (
                                                        <p className="form-error text-danger">
                                                            {errors.userName}
                                                        </p>
                                                    ) : error.userName ? (
                                                        <p className="form-error text-danger">
                                                            {error.userName}
                                                        </p>
                                                    ) : null}
                                                </div>

                                                <div className="col-lg-4">
                                                    <div className="mb-4">
                                                        <label className="form-label" htmlFor="email">Email </label>
                                                        <input name="email"
                                                            value={values.email}
                                                            maxLength={100}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur} type="email" id="email" className="form-control py-2 shadow-none px-3" />
                                                    </div>
                                                    {errors.email && touched.email ? (
                                                        <p className="form-error text-danger">
                                                            {errors.email}
                                                        </p>
                                                    ) : error.email ? (
                                                        <p className="form-error text-danger">
                                                            {error.email}
                                                        </p>
                                                    ) : !errors.email &&
                                                        error.email &&
                                                        touched.email ? (
                                                        <p className="form-error text-danger">
                                                            Please enter email
                                                        </p>
                                                    ) : null}
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="mb-4">
                                                        <label className="form-label" htmlFor="phone">Phone</label>
                                                        <input name="phone" value={values.phone}
                                                            onChange={handleChange}
                                                            maxLength={15}
                                                            onBlur={handleBlur} type="text" id="phone" className="form-control py-2 shadow-none px-3" />
                                                    </div>
                                                    {errors.phone && touched.phone ? (
                                                        <p className="form-error text-danger">
                                                            {errors.phone}
                                                        </p>
                                                    ) : error.phone ? (
                                                        <p className="form-error text-danger">
                                                            {error.phone}
                                                        </p>
                                                    ) : !errors.phone &&
                                                        error.phone &&
                                                        touched.phone ? (
                                                        <p className="form-error text-danger">
                                                            Please enter phone number
                                                        </p>
                                                    ) : null}
                                                </div>

                                            
                                                <div className="col-12 text-end mt-4">
                                                    <input onClick={handleSubmit} type="submit" value="Save" className="btn btn-primary btn-theme fw-semibold text-uppercase px-2 py-1" />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditUser
