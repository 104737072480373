
import React, { useState } from "react";
import Table from "../../components/Table2";
import { getNewsLetter } from "../dataService/DataService";

import { useQuery } from "react-query";
import moment from "moment";
import CSVFileDownload from "../../components/CSVFileDownload";


const Newsletter = () => {
    const [excelDownload, setDownload] = useState();

    const handleDownloadExcelFile = (downloadExcel) => {
      setDownload(downloadExcel);
    };


    const getCookie = (name) => {
        const cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Check if this cookie is the one we are looking for
            if (cookie.startsWith(name + "=")) {
                // Return the value of the cookie
                return cookie.substring(name.length + 1);
            }
        }
        // Return null if the cookie is not found
        return null;
    };
    const websiteIdFromCookie = getCookie("websiteId");


    const newsletterData = useQuery(
        ["getNewsLetter", +websiteIdFromCookie],
        () => getNewsLetter(websiteIdFromCookie)
    );


    const columns = React.useMemo(
        () => [
            { Header: "Sr. No", accessor: "srNo" },
            { Header: "Email", accessor: "email", Cell: ({ row }) => `${row.original.email}`, },
            {
                Header: "CREATED AT", accessor: "last_requested_on", Cell: ({ row }) => (
                    <span className="" style={{ width: "200px" }}>
                        {moment(row.original.last_requested_on).format(
                            "MMM D, YYYY h:mm A [GMT]Z"
                        )}
                    </span>
                )
            },

        ],
        []
    );

    const headers = ['Sr. No', 'Email', 'Created At'];
    const headerHeading = 'All NEWSLETTER';
    return (
        <>

            <div className="page-body">
                <div className="container-fluid dashboard-default">
                    <div className="page-heading d-flex flex-wrap align-items-center justify-content-between">

                        <div className="left-header">
                            <h4 className="fw-normal m-0 fs-6">NEWSLETTER</h4>
                        </div>
                    </div>

                    <div className="card mt-lg-5">
                        <div className="bg-transparent d-flex align-items-center justify-content-between">
                           
                            {newsletterData.data && (
                                <CSVFileDownload
                                    data={newsletterData?.data?.data?.data?.map((item, index) => [index + 1, item.email, moment(item.last_requested_on).format(
                                        "MMM D, YYYY h:mm A [GMT]Z"
                                    )])}
                                    headers={headers}
                                    filename="newsletter.xlsx"
                                    excelDownload={excelDownload}
                                    onClick={handleDownloadExcelFile}
                                />
                            )}
                        </div>

                        {newsletterData.isLoading || !newsletterData?.data ? (
                        <div className="text-center">Loading...</div>
                      ) : newsletterData?.data?.data?.data?.length === 0 ? (
                        <div className="card-body text-center">No Data Available</div>
                      ) : (
                            <Table data={newsletterData?.data?.data?.data} key={newsletterData?.data?.data?.data.newsletter_id} columns={columns} isShowingHeading={true} handleDownloadExcelFile={handleDownloadExcelFile} headerHeading={headerHeading} isExcelDownloadBtnShow={true}/>
                      )}

                    </div>
                </div>
            </div>






        </>
    );
};

export default Newsletter;
