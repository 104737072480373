import { Link, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import Table from "../../components/Table2";
import { getAllUsers ,updateUserIsActive} from "../dataService/DataService";
import { useMutation, useQuery} from "react-query";
import DeleteModal from "./DeleteModal";
import { toast } from "react-toastify";
import moment from 'moment';

const ManageUser = () => {
  
  let AllUsers = useQuery(
    "getAllUsers", getAllUsers
  );


  const navigate = useNavigate();
  const handleNavigateToEdit = (userId) => {

    navigate(`/manage-users/edit-user/${userId}`)
  };
  const [showModal, setShowModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [selectedRowNameData, setSelectedRowNameData] = useState({});
  const handleShowModal = (rowData) => {
    setSelectedRowData(rowData.original.user_id);
    setSelectedRowNameData(rowData.original.user_name);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };


  const handleStatus = (e, row) => {
    const status = e.target.value;
    const userId = row.original.user_id;
    row.original.is_active = status;  
    const statusData = {isActive : status};   
    changeApplicationStatusMutate.mutate({ userId, statusData });
  };
  const changeApplicationStatusMutate = useMutation(updateUserIsActive, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess(data) {
      toast.dismiss();
      toast.success(data.message);
 
    },
  });
 

  const columns = React.useMemo(
    () => [
      { Header: "Sr. No", accessor: "srNo" },
      {
        Header: "User Name",
        accessor: "user_name",
        Cell: ({ row }) => (
          <>
            {row.original.user_name}  {row.original.case ? <span className="px-2 py-1 rounded-1 btn-sm status-btn unpublish-btn position-relative text-white"></span> : ""}
          </>
        ),
      },
      {
        Header: "User Email",
        Cell: ({ row }) => `${row.original.email}`,
      },
      { Header: "User Mobile No", accessor: "phone" },
      {
        Header: "User Role",
        accessor: "user_role",
        Cell: ({ row }) => (
          <>
            {row.original.user_role}
          </>
        ),
      },
      { Header: "Assign Website", accessor: "website" ,
      Cell: ({ row }) => (
        <>
          {row.original.websites}
        </>
      ),},
      {
        Header: "Created On",
        Cell: ({ row }) => (
          <>
          {moment(row.original.created_on).format('MMM D, YYYY h:mm A [GMT]Z')}
          </>
        ),
      },

      {
        Header: "Status",
        Cell: ({ row }) => (
          <select
            onChange={(e) => handleStatus(e, row)}
            value={row.original.is_active}
            className="form-select"
            style={{ width: "200px" }}
          >
            <option key="1" value="1" >
              Active
            </option>
            <option key="0" value="0" >
              Inactive
            </option>
          </select>
        ),
      },
      {
        Header: "Actions",
        Cell: ({ row }) => (
          <>
            <button
              onClick={() =>
                handleNavigateToEdit(row.original.user_id)
              }
              className="btn btn-primary btn-sm btn-theme"
            >
              <i className="fa fa-pencil" aria-hidden="true"></i>
            </button>
            <button
              variant="info" onClick={() => handleShowModal(row)}
             
              className="btn btn-primary bg-danger border-danger btn-sm btn-theme ms-1"
            >
              <i className="fa fa-trash" aria-hidden="true"></i>
              
            </button>
          </>
        ),
      },
    ],
    []
  );



  if (!AllUsers.data) {
    <div>Loading</div>
  }
  if (AllUsers.isLoading) {
    <div>Loading</div>
  }


  return (
    <>

      <div className="page-body">
        <div className="container-fluid dashboard-default">
          <div className="page-heading d-flex flex-wrap align-items-center justify-content-between">
          
            <div className="left-header">
              <h4 className="fw-normal m-0 fs-5">Manage Users</h4>
            </div>
          </div>
          <div className="row mt-md-4 mt-3">
            <div className="col-md-12">
              <div className="card mb-4">
                <div className="card-header bg-transparent border-bottom py-2 d-flex align-items-center justify-content-between">
                  <h5 className="m-0 fw-medium fs-6">All Users</h5>
                  <div className="right-actions">
                    <Link to="/manage-users/create-user" className="btn btn-primary btn-theme fw-semibold text-uppercase px-2 py-1">Add New User</Link>
                  </div>
                </div>
                <div className="card-body">
                  <div className="tabledata">
                    <div className="table-responsive">
                      {AllUsers.data &&
                        <Table data={AllUsers?.data?.data} columns={columns} isShowingHeading={false} />
                      }
                      <DeleteModal
                        show={showModal}
                        handleClose={handleCloseModal}
                        rowData={selectedRowData}
                        rowNameData={selectedRowNameData}
                      />

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default ManageUser;
