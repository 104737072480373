import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";


// import Table from "../../components/Table2";
import {
  getAllRestaurants,
  updateDiningSorting,
  updateRestaurantIsActive,
} from "../dataService/DataService";
import { useMutation, useQuery, useQueryClient } from "react-query";
import DeleteModal from "../manageDining/DeleteModal";
// import DragTable from "../../components/DragTable";
// import DiningDragTable from "../../components/DiningDragTable";
import { toast } from "react-toastify";
import { Table } from "../../components/dragTable/Table";
import dummyImage from "../../assets/images/album.png";
function ManageDining() {
  const cach = useQueryClient();

  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [selectedRowNameData, setSelectedRowNameData] = useState({});
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [data, setData] = useState([]);
  const handleShowModal = (rowData) => {
    setSelectedRowData(rowData.original.restaurant_id);
    setSelectedRowNameData(rowData.original.restaurant_name);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleNavigateToEdit = (restaurantId) => {
    navigate(`/manage-dining/edit-restaurant/${restaurantId}`);
  };
  const handleStatus = (e, row) => {
    const status = Number(e.target.value);
    const id = row.original.restaurant_id;
    row.original.is_active = status;
    const data = changeApplicationStatusMutate.mutate({ id, status });
  };

  const changeApplicationStatusMutate = useMutation(updateRestaurantIsActive, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess(data) {
      toast.dismiss();

      toast.success(data.message);
    },
  });

  const columns = React.useMemo(
    () => [

      { Header: "Sr. No", accessor: "srNo" },
      {
        Header: "Image",
        accessor: "restaurant_image",
        Cell: ({ row }) => (
          <>{<img

            src={row.original.restaurant_image}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // Prevents looping
              currentTarget.src = dummyImage; // Replace with the path to your dummy image
            }}
            width="100"></img>}</>
        ),
      },
      {
        Header: "Name",
        accessor: "restaurant_name",
      },
      { Header: "Type", accessor: "restaurant_type" },
      {
        Header: "Created On", accessor: "created_on",
        Cell: ({ row }) => (
          <span className="" style={{ width: "200px" }}>
            {moment(row.original.created_on).format(
              "MMM D, YYYY h:mm A [GMT]Z"
            )}
          </span>
        ),

      },
      {
        Header: "Status",
        Cell: ({ row }) => (
          <select
            onChange={(e) => handleStatus(e, row)}
            value={row.original.is_active}
            className="form-select"
            style={{ width: "110px" }}
          >
            <option key="1" value="1">
              Active
            </option>
            <option key="0" value="0">
              Inactive
            </option>
          </select>
        ),
      },
      // {
      //   Header: "Status",
      //   Cell: ({ row }) => (
      //     <>
      //       {row.original.is_active == 1
      //         ? "Active"
      //         : "Inactive"}
      //     </>
      //   ),
      // },
      {
        Header: "Actions",
        Cell: ({ row }) => (
          <>
            <button
              onClick={() => handleNavigateToEdit(row.original.restaurant_id)}
              className="btn btn-primary btn-sm btn-theme"
            >
              <i className="fa fa-pencil" aria-hidden="true"></i>
            </button>
            <button
              variant="info"
              onClick={() => handleShowModal(row)}
              className="btn btn-primary bg-danger border-danger btn-sm btn-theme ms-1"
            >
              <i className="fa fa-trash" aria-hidden="true"></i>
            </button>
          </>
        ),
      },
    ],
    []
  );
  let allRestaurants = useQuery("getAllRestaurents", getAllRestaurants);
  if (!allRestaurants?.data?.data) {
    <div>Loading</div>;
  }
  if (allRestaurants.isLoading) {
    <div>Loading</div>;
  }
  useEffect(() => {
    if (allRestaurants?.data) {
      setData(allRestaurants?.data?.data);
    }
  }, [allRestaurants]);


  return (
    <>
      <div className="page-body">
        <div className="container-fluid dashboard-default">
          <div className="page-heading d-flex flex-wrap align-items-center justify-content-between">
            <div className="left-header">
              <h4 className="fw-normal m-0 fs-5">Manage Dining</h4>
            </div>
          </div>
          <div className="row mt-md-4 mt-3">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header bg-transparent border-bottom py-2 d-flex align-items-center justify-content-between">
                  <h5 className="m-0 fw-medium fs-6">Manage Dining</h5>
                  <div className="right-actions">
                    <Link
                      to="/manage-dining/add-restaurant"
                      className="btn btn-primary btn-theme fw-semibold text-uppercase px-2 py-1"
                    >
                      Add New
                    </Link>
                  </div>
                </div>

                {/* {allRestaurants?.isLoading ? (
                  <div>Loading...</div>
                ) : ( */}
                  {allRestaurants.isLoading || !allRestaurants?.data ? (
                        <div className="text-center">Loading...</div>
                      ) : data?.length === 0 ? (
                        <div className="card-body text-center">No Data Available</div>
                      ) : (
                  <Table
                    data={data}
                    setData={setData}
                    columns={columns}
                    isShowingHeading={false}
                    updateSortingTable={updateDiningSorting}
                    componentName={"dining"}
                  />
                )}
            
                <DeleteModal
                  deleteLoading={deleteLoading}
                  setDeleteLoading={setDeleteLoading}
                  show={showModal}
                  handleClose={handleCloseModal}
                  rowData={selectedRowData}
                  rowNameData={selectedRowNameData}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ManageDining;
