import React, { useEffect, useState } from "react";
import { getAllAmenities } from "../../services/service";
import {
  insertAmenity,
  getSingleAmenityData,
  updateAmenityData,
  deleteSingleAmenity,
} from "../dataService/DataService";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import AmenityModal from "./AmenityModal";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
export const amenitySchema = Yup.object({
  amenityName: Yup.string().trim().required("Please enter amenity Name"),
});

function AmenitiesTab({
  setFieldValue,
  showCropMediaModal,
  amenityFileData,
  setAmenityFile,
  amenityCropImage,
  setAmenityCropImage,
}) {
  const ToolLink = ({ id, children, title }) => (
    <OverlayTrigger overlay={<Tooltip id={id}>{title}</Tooltip>}>
      <a className="text-dark text-decoration-none">{children}</a>
    </OverlayTrigger>
  );
  const queryClient = useQueryClient();
  const [show, setShow] = useState(false);
  const [amenityId, setAmenityId] = useState(null);
  const [aminityLoading, setAminityLoading] = useState(false)
  const handleClose = (e) => {
    // e.preventDefault();
    setShow(false);
    setAmenityFile("");
    setAmenityCropImage("")
    setValues({
      amenityName: "",
      amenityFromEditIcon: "",
    });
    setModalKey((prevKey) => prevKey + 1);
  };
  const [modalTitle, setModalTitle] = useState("");
  const [modalHeading, setModalHeading] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [error, setErrors] = useState({});
  const [modalKey, setModalKey] = useState(0);
  const getSigleAmenity = useQuery(["getSigleAmenity", +amenityId], () =>
    getSingleAmenityData(amenityId)
  );

  let AllAmenities = useQuery("getAllAmenities", getAllAmenities);

  const handleShowAmentieModal = (amenity_id, e, val) => {

    if (e) {
      e.preventDefault();
    }

    // setValues(initialValues);

    setShow(true);
    setAmenityFile("");
    setAmenityCropImage("")
    if (val === "edit") {
      setModalTitle("Edit Amenity");
      setModalHeading("Edit");
      setAmenityId(amenity_id);
      setValues(initialValues);
    } else {
      setAmenityId(null);
      setModalTitle("Add New Amenity");
      setModalHeading("Add amenity");
      setValues({
        amenityName: "",
        amenityFromEditIcon: "",
      });
    }


  };

  const [checkBoxArray, setCheckBoxArray] = useState([]);
  const handleAmenityChange = (e) => {
    const value = e.target.value;
    const checked = e.target.checked;
    if (checked) {
      setCheckBoxArray([...checkBoxArray, value]);
    } else {
      setCheckBoxArray(checkBoxArray.filter((e) => e !== value));
    }
  };

  useEffect(() => {
    setFieldValue("amenitiesId", checkBoxArray);
  }, [checkBoxArray, setFieldValue]);

  const initialValues = {
    amenityName: getSigleAmenity.data?.data?.amentity_name || "",
    amenityFromEditIcon: getSigleAmenity.data?.data?.icon_path || "",
  };

  // const amenityIcon =
  //   amenityFileData &&
  //   amenityFileData.data &&
  //   amenityFileData.data.media_file_path;
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
    errors,
    touched,
    resetForm
  } = useFormik({
    initialValues: initialValues,
    validationSchema: amenitySchema,
    onSubmit: (values, action) => {
      try {
        setAminityLoading(true)
        const formData = new FormData();
        formData.append("amenityName", values.amenityName);
        if (amenityFileData) {
          formData.append("amenityIcon", amenityFileData);
        } else {
          formData.append("amenityIcon", values.amenityFromEditIcon);
        }

        if (amenityId > 0) {
          updateAmentiesMutation.mutate({ formData, amenityId });
        } else {
          addAmentiesMutation.mutate(formData);
        }
      } catch (error) {
        console.log("value of the error  : ", error)
        setAminityLoading(false)

      }
    },
  });


  useEffect(() => {

    (() => {
      if (show === false) {
        resetForm()
      }
    })()

  }, [show])


  const updateAmentiesMutation = useMutation(updateAmenityData, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess: (data) => {
      setAminityLoading(false)
      setIsLoading(false);
      toast.dismiss();
      toast.success(data.message);

      navigate("/manage-properties/create-properties");
      queryClient.invalidateQueries("getAllAmenities");
      queryClient.invalidateQueries("getSigleAmenity");
      setValues("");
      setShow(false);
      setAmenityFile("");
      setAmenityCropImage("")
      resetForm()
    },
    onSettled: () => {
      setIsLoading(false); // Set loading to false on success or error
    },
    onMutate: () => {
      setIsLoading(true); // Set loading to true before making the request
    },
  });

  const addAmentiesMutation = useMutation(insertAmenity, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess: (data) => {
      setAminityLoading(false)
      setIsLoading(false);
      toast.dismiss();
      toast.success(data.message);
      resetForm()
      navigate("/manage-properties/create-properties");
      queryClient.invalidateQueries("getAllAmenities");
      queryClient.invalidateQueries("getSigleAmenity");
      setValues("");
      setShow(false);
      setAmenityFile("");
      setAmenityCropImage("")
    },
    onSettled: () => {
      setIsLoading(false); // Set loading to false on success or error
    },
    onMutate: () => {
      setIsLoading(true); // Set loading to true before making the request
    },
  });

  useEffect(() => {
    if (getSigleAmenity.isSuccess) {
      setValues((prevValues) => ({
        ...prevValues,
        amenityName: getSigleAmenity.data?.data?.amentity_name || "",
        amenityFromEditIcon: getSigleAmenity.data?.data?.icon_path || "",
      }));
    }
  }, [getSigleAmenity.data, setValues]);

  const handleAmenityDelete = (amenityId, e) => {
    if (e) {
      e.preventDefault();
    }
    amenityDelete.mutate(amenityId);
  };
  const amenityDelete = useMutation(deleteSingleAmenity, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess: (data) => {
      toast.dismiss();
      toast.success(data.message);
      queryClient.invalidateQueries("getAllAmenities");
    },
  });

  return (
    <>
      <div className="edit-mein-info">
        <div className="d-flex flex-wrap align-items-center justify-content-between">
          <div className="top-header-edit">
            <h5>Amenities</h5>
          </div>
          <div className="header-action">
            <a
              onClick={(e) => handleShowAmentieModal(e)}
              className="btn btn-primary btn-theme fw-semibold text-uppercase px-2 py-1"
            >
              Add New
            </a>
          </div>
        </div>
        <div className="row mt-4 row-gap-4">
          {AllAmenities.data &&
            AllAmenities.data.data.map((amenity) => (
              <div key={amenity.amenity_id} className="col-xxl-3 col-xl-4 col-lg-6">
                <div className="amenity-wrap d-flex align-items-center mb-3 border px-3 py-3 h-100">
                  <div className="check-amenity">
                    <input
                      type="checkbox"
                      value={amenity.amenity_id}
                      onChange={(e) => {
                        handleAmenityChange(e);
                      }}
                      id={amenity.amenity_id}
                      name={amenity.amenity_id}
                    />
                  </div>

                  <div className="amenity-view d-flex align-items-center ps-2">
                    <div className="icon-amenity">
                      <img src={amenity.icon_path} />
                    </div>

                    <div className="name-amenity px-2" >
                      <ToolLink
                        title={amenity.amentity_name}
                        id="t-1"
                        className='text-decoration-none'
                      >
                        {amenity.amentity_name}
                      </ToolLink>{" "}

                    </div>
                  </div>
                  <button
                    onClick={(e) =>
                      handleShowAmentieModal(amenity.amenity_id, e, "edit")
                    }
                    className="btn btn-primary btn-sm btn-theme"
                  >
                    <i className="fa fa-pencil" aria-hidden="true"></i>
                  </button>
                  <button
                    variant="info"
                    onClick={(e) => handleAmenityDelete(amenity.amenity_id, e)}
                    className="btn btn-primary bg-danger border-danger btn-sm btn-theme ms-1"
                  >
                    <i className="fa fa-trash" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            ))}
        </div>
      </div>

      <AmenityModal
        key={modalKey}
        aminityLoading={aminityLoading}
        show={show}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        values={values}
        amenityFileData={amenityFileData}
        amenityCropImage={amenityCropImage}
        showCropMediaModal={showCropMediaModal}
        handleChange={handleChange}
        handleBlur={handleBlur}
        errors={errors}
        touched={touched}
        modalTitle={modalTitle}
        modalHeading={modalHeading}
      />
    </>
  );
}

export default AmenitiesTab;
