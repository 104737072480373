import React from "react";
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify';
// import * as dataquery from "../dataService/DataService";
import { deleteVenue } from "../dataService/DataService";

import * as dataquery from "../../services/service";
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { Button, Modal } from 'react-bootstrap';

const DeleteModal = ({ row, show, handleClose, rowData, rowNameData }) => {
  const queryClient = useQueryClient();

  const handleArchive = (data) => {
    deleteUserRecord.mutate(data);
    handleClose();
  };
  const deleteUserRecord = useMutation(deleteVenue, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess: (data) => {
      toast.dismiss();
      toast.success(data.message);
      // queryClient.invalidateQueries('getAllProperties')
      queryClient.resetQueries('getAllVenues')
    },
  });
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="py-2" closeButton>
          <Modal.Title className="fs-5">Delete - {rowNameData}?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Once you delete this data, you won't be able to recover it. Are
            you sure you want to go ahead?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="text-uppercase btn-sm" onClick={handleClose} >
            Close
          </Button>
          <Button variant="primary" className="text-uppercase btn-sm btn-danger" onClick={() => handleArchive(rowData)}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteModal;
