import React from 'react'

function MultiRestaurantDropDown({data, dropdown, index, handleChangeFeatureChange, handleRemoveFeatureDropdown }) {

    return (
        <>
        
            <div key={index} data-key={dropdown.key} className="row position-relative">
                <div className="bed-row d-flex flex-wrap align-items-end">
                    <div className="bed-add-row mt-5">
                        <input
                            type="text"
                            className="form-control py-2 shadow-none px-3"
                            placeholder="Enter feature"
                            name="features"
                            onChange={(e) => handleChangeFeatureChange(e, dropdown.key,index)}
                             value={data.tagFeatures[dropdown.key]}
                        />
                    </div>
                    <div className="add-more-blocks text-end ">
                        <a
                            onClick={() => handleRemoveFeatureDropdown(dropdown)}
                            className="btn   btn-outline-danger text-uppercase"
                        >
                            <i className="fa fa-minus"></i>
                        </a>
                    </div>
                </div>

            </div>
        </>
    )
}

export default MultiRestaurantDropDown