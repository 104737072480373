import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";

const OccupancyDropDown = (
  { initialValues, className, name, setValues, values, showDropdown ,duplicateIndexes,setDuplicateIndexes,setFieldValue},
  ref
) => {

  const [inputCount, setInputCount] = useState(
    initialValues?.adultCount?.length
  );

  const [adultCounts, setAdultCounts] = useState(initialValues?.adultCount);
  const [childrenCounts, setChildrenCounts] = useState(
    initialValues?.childrenCount
  );

  const handleAddClick = () => {
    setInputCount((prevCount) => prevCount + 1);
    setAdultCounts((prevCounts) => [...prevCounts, "1"]); // Add new adult count
    setChildrenCounts((prevCounts) => [...prevCounts, "0"]); // Add new children count
    
     
    // setValues();
   
  };
  
useEffect(()=>{  
  setFieldValue("adultCount", adultCounts ); 
  setFieldValue("childrenCount",childrenCounts); 
},[adultCounts])

  const handleRemoveClick = (index) => {
    setInputCount((prevCount) => prevCount - 1);
    setAdultCounts((prevCounts) => prevCounts.filter((_, i) => i !== index)); // Remove adult count
    setChildrenCounts((prevCounts) => prevCounts.filter((_, i) => i !== index)); // Remove children count
    updateValues(
      adultCounts.filter((_, i) => i !== index),
      childrenCounts.filter((_, i) => i !== index)
    );
    // setDuplicateIndexes(prev=>prev.filter(item => item != index))

  };

  const adultIncrementBtn = (index) => {
    const newAdultCounts = [...adultCounts];
    newAdultCounts[index] = String(+newAdultCounts[index] + 1);
    setAdultCounts(newAdultCounts);
    updateValues(newAdultCounts, childrenCounts); // Update values state
    setDuplicateIndexes(prev=>prev.filter(item => item != index))

  };

  const adultDecrementBtn = (index) => {
    if (+adultCounts[index] > 1) {
      const newAdultCounts = [...adultCounts];
      newAdultCounts[index] = String(+newAdultCounts[index] - 1);
      setAdultCounts(newAdultCounts);
      updateValues(newAdultCounts, childrenCounts); // Update values state
    }
    setDuplicateIndexes(prev=>prev.filter(item => item != index))

  };

  const childrenIncrement = (index) => {
    const newChildrenCounts = [...childrenCounts];
    newChildrenCounts[index] = String(+newChildrenCounts[index] + 1);
    setChildrenCounts(newChildrenCounts);
    updateValues(adultCounts, newChildrenCounts); // Update values state
   
    setDuplicateIndexes(prev=>prev.filter(item => item != index))
  };

  const childrenDecrement = (index) => {
    if (+childrenCounts[index] > 0) {
      const newChildrenCounts = [...childrenCounts];
      newChildrenCounts[index] = String(+newChildrenCounts[index] - 1);
      setChildrenCounts(newChildrenCounts);
      updateValues(adultCounts, newChildrenCounts); // Update values state
    }
    setDuplicateIndexes(prev=>prev.filter(item => item != index))


  };


  const updateValues = (newAdultCounts, newChildrenCounts) => {
    // Construct new values object with updated counts
    const newValues = {
      ...values,
      adultCount: newAdultCounts,
      childrenCount: newChildrenCounts,
    };
  
    setValues(newValues);
  };

  return (
    <>
      {showDropdown && (
        <>
          {Array.from({ length: inputCount }, (_, index) => (
            <div
              className={`booking-form-input guests-input position-relative d-flex gap-4 ${
                duplicateIndexes?.includes(index) ? "error" : ""
              }`}
              key={index}
            
            >
              <Dropdown>
                <Dropdown.Toggle
                  name="guests-btn"
                  className="form-control shadow-none guest-form-control pe-5 py-2 h-auto"
                  id="guests-input-btn"
                >
                  {adultCounts[index]} Adult, {childrenCounts[index]} Children
                </Dropdown.Toggle>
                <Dropdown.Menu className="guest-dropdown mw-100 mt-2">
                  <div className="row">
                    <div className="mb-4">
                      <div
                        className="guests-input_options"
                        id="guests-input-options"
                      >
                        <div className="guest-dropdown-item">
                          <label>Adult</label>
                          <div className="guest-card d-flex align-items-center">
                            <span
                              className="guests-input__ctrl minus"
                              onClick={() => adultDecrementBtn(index)}
                            >
                              <FontAwesomeIcon icon={faAngleLeft} />
                            </span>
                            <span className="guests-input__value">
                              <span>{adultCounts[index]}</span>
                            </span>
                            <span
                              className="guests-input__ctrl plus"
                              onClick={() => adultIncrementBtn(index)}
                            >
                              <FontAwesomeIcon icon={faAngleRight} />
                            </span>
                          </div>
                        </div>
                        <div className="guest-dropdown-item mt-3">
                          <label>Children</label>
                          <div className="guest-card d-flex align-items-center">
                            <span
                              className="guests-input__ctrl minus"
                              onClick={() => childrenDecrement(index)}
                            >
                              <FontAwesomeIcon icon={faAngleLeft} />
                            </span>
                            <span className="guests-input__value">
                              <span>{childrenCounts[index]}</span>
                            </span>
                            <span
                              className="guests-input__ctrl plus"
                              onClick={() => childrenIncrement(index)}
                            >
                              <FontAwesomeIcon icon={faAngleRight} />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
              {index === inputCount - 1 && (
                <div className="add-more-blocks text-end mb-4">
                  <a
                    onClick={handleAddClick}
                    className="btn btn-primary btn-theme fw-semibold text-uppercase px-2 py-2"
                  >
                    <i className="fa fa-plus" aria-hidden="true"></i>
                  </a>
                </div>
              )}
              {index !== inputCount - 1 && (
                <div className="add-more-blocks text-end mb-2">
                  <a
                    onClick={() => handleRemoveClick(index)}
                    className="btn btn-outline-danger text-uppercase px-2 py-2"
                  >
                    <i className="fa fa-minus"></i>
                  </a>
                </div>
              )}
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default OccupancyDropDown;
