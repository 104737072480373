import React, { useEffect, useState } from "react";
import pdfImg from "../../assets/images/pdf-doc.svg";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DraggableSubSection from "./DraggableSubSection";
import Tabs from "../../components/dragTabs/Tabs";
import { HiPlus } from "react-icons/hi";

const EditSubSectionComponent = ({
  key,
  subsection = [],
  handleSubSectionChange,
  subSectionDataArray,
  image,
  handleRemoveImage,
  sectionimageData,
  formErrors,
  videoImg,
  showCropMediaModal,
  mainVideoFile,
  sectionDataArray,
  handleShowModal,
  handleSubmitSubSection,
  handleShowEditSubSectionModal,
  handleReorderSubSections,
  ItemTypes,
  changeSortingIndexMutation,
  deleteBtnShow,
  handleRemoveSubSectionImage,
  handleShowImageTagModal,
}) => {
  const [childSectionSortingData, setChildSectionSortingData] = useState(
    subsection?.child || []
  );

  useEffect(() => {
    if (subsection) {
      setChildSectionSortingData(subsection?.child);
    }
  }, [subsection]);

  if (childSectionSortingData?.length === 1) {
    deleteBtnShow = false;
  }
  handleReorderSubSections = (dragIndex, hoverIndex) => {
    const subChildId = childSectionSortingData[dragIndex].page_sub_section_id;
    const dragSubSection = childSectionSortingData[dragIndex];
    const updatedSubSections = [...childSectionSortingData];
    updatedSubSections.splice(dragIndex, 1);
    updatedSubSections.splice(hoverIndex, 0, dragSubSection);
    setChildSectionSortingData(updatedSubSections);
  };
  const [activeTab, setActiveTab] = useState(null);

  return (
    <>
      <div className="row" key={key}>
        {subsection.details.map((section, index) => {
          switch (section.code) {
            case "TEXTBOX":
              return (
                <div className="col-lg-6 mb-4" key={section.id}>
                  <label className="form-label" htmlFor="IconText">
                    {section.field_title}
                  </label>
                  <input
                    type="text"
                    className="form-control py-2 shadow-none px-3"
                    id={section.field_title}
                    name={section.id}
                    defaultValue={
                      subSectionDataArray[section.id] ||
                      section.page_sub_section_detail_data
                    }
                    onChange={handleSubSectionChange}
                    required
                    {...(section.field_max_length && {
                      maxLength: section.field_max_length,
                    })}
                    {...(section.field_max_length && {
                      placeholder: `Character limit, upto ${section.field_max_length} characters`,
                    })}
                  />
                </div>
              );
            case "TEXTAREA":
              return (
                <div className="col-md-12 mb-4" key={section.id}>
                  <label className="form-label" htmlFor="Body">
                    {section.field_title}
                  </label>
                  <textarea
                    className="form-control py-2 shadow-none px-3"
                    rows="5"
                    id={section.field_title}
                    name={section.id}
                    defaultValue={
                      subSectionDataArray[section.id] ||
                      section.page_sub_section_detail_data
                    }
                    onChange={(e) => handleSubSectionChange(e, section)}
                    required
                    {...(section.field_max_length && {
                      maxLength: section.field_max_length,
                    })}
                    {...(section.field_max_length && {
                      placeholder: `Character limit, upto ${section.field_max_length} characters`,
                    })}
                  ></textarea>
                </div>
              );
            case "FILE_IMAGE":
              return (
                <>
                  {sectionimageData["subSection_" + section.id] &&
                  section.page_sub_section_detail_data ? (
                    <>
                      <div className="col-12">
                        <div className="d-flex align-items-center">
                          <label className="form-label mb-0">
                            {section.field_title}
                          </label>
                          <a
                            title="Add image alt tag"
                            className="btn image-tag"
                            onClick={() =>
                              handleShowImageTagModal(
                                "subSection_" + section.id,
                                section.img_alt_tag
                              )
                            }
                          >
                            <HiPlus />
                          </a>
                        </div>
                        <div
                          className="uploaded-img-view overflow-hidden mb-3"
                          style={{
                            height: "200px",
                            width: "200px",
                            position: "relative",
                          }}
                        >
                          <div>
                            <a
                              href={section.page_sub_section_detail_data}
                              target="_blank"
                            >
                              <img
                                // alt="subsection_image"
                                src={section.page_sub_section_detail_data}
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                              />
                            </a>
                            <div
                              style={{
                                position: "absolute",
                                top: "0",
                                right: "0",
                              }}
                            >
                              <button
                                className="remove-image-button"
                                onClick={() =>
                                  handleRemoveSubSectionImage(
                                    `subSection_${section.id}`
                                  )
                                }
                              >
                                <i className="fa fa-times"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  {image && image[`subSection_${section.id}`] ? (
                    <div className="col-md-12 mb-4" key={section.id}>
                      <div className="d-flex align-items-center">
                        <label className="form-label mb-0">
                          {section.field_title}
                        </label>{" "}
                        <a
                          title="Add image alt tag"
                          className="btn image-tag"
                          onClick={() =>
                            handleShowImageTagModal(
                              "subSection_" + section.id,
                              section.img_alt_tag
                            )
                          }
                        >
                          <HiPlus />
                        </a>
                      </div>
                      <div
                        className="uploaded-img-view overflow-hidden"
                        style={{
                          height: "200px",
                          width: "200px",
                          position: "relative",
                        }}
                      >
                        <div>
                          <a
                            href={image[`subSection_${section.id}`]}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              // alt="subsection_image"
                              src={image[`subSection_${section.id}`]}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                            />
                          </a>
                          <div
                            style={{
                              position: "absolute",
                              top: "0",
                              right: "0",
                            }}
                          >
                            <button
                              className="remove-image-button"
                              onClick={() =>
                                handleRemoveSubSectionImage(
                                  `subSection_${section.id}`
                                )
                              }
                            >
                              <i className="fa fa-times"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {!image[`subSection_${section.id}`] &&
                  !sectionimageData["subSection_" + section.id] ? (
                    <div className="col-12 mb-4" key={section.id}>
                      <div className="d-flex align-items-center">
                        <label className="form-label mb-0">
                          {section.field_title}
                        </label>
                        <a
                          title="Add image alt tag"
                          className="btn image-tag"
                          onClick={() =>
                            handleShowImageTagModal(
                              "subSection_" + section.id,
                              section.img_alt_tag
                            )
                          }
                        >
                          <HiPlus />
                        </a>
                      </div>
                      <label
                        htmlFor="UploadCover"
                        className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5"
                        onClick={() =>
                          showCropMediaModal(
                            `subSection_${section.id}`,
                            section.image_dimension
                          )
                        }
                      >
                        <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                          <button
                            type="button"
                            className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                          >
                            Upload from gallery
                          </button>
                        </div>
                      </label>

                      {formErrors[section.id] && (
                        <p
                          style={{
                            color: "red",
                          }}
                        >
                          {formErrors[section.id]}
                        </p>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </>
              );
            case "DROPDOWN":
              return (
                <div className="col-lg-6 mb-4" key={section.id}>
                  <label className="form-label" htmlFor={section.field_title}>
                    {section.field_title}
                  </label>
                  <select
                    className="form-control py-2 shadow-none px-3"
                    id={section.field_title}
                    name={section.id}
                    defaultValue={
                      subSectionDataArray[section.id] ||
                      section.page_sub_section_detail_data
                    }
                    onChange={handleSubSectionChange}
                    required
                  >
                    <option value="" disabled hidden>
                      Select One
                    </option>
                    {section?.dropdownOptions?.map((option, idx) => (
                      <option key={idx} value={option.id}>
                        {option.sub_section_title}
                      </option>
                    ))}
                  </select>
                </div>
              );
            case "FILE_PDF":
              return (
                <>
                  {sectionimageData["subSection_" + section.id] &&
                  section.page_sub_section_detail_data ? (
                    <div className="col-md-12 mb-4" key={section.id}>
                      <label className="form-label">
                        {section.field_title}
                      </label>
                      <div
                        className="uploaded-img-view overflow-hidden"
                        style={{
                          height: "200px",
                          width: "200px",
                          position: "relative",
                        }}
                      >
                        <div>
                          <a
                            href={section.page_sub_section_detail_data}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              alt=""
                              src={
                                section.page_sub_section_detail_data.endsWith(
                                  ".pdf"
                                )
                                  ? pdfImg
                                  : section.page_sub_section_detail_data
                              }
                              // src={section.page_sub_section_detail_data}
                              style={
                                section.page_sub_section_detail_data.endsWith(
                                  ".pdf"
                                )
                                  ? {
                                      width: "68%",
                                      height: "68%",
                                    }
                                  : {
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                    }
                              }
                            />
                          </a>
                          <div
                            style={
                              section.page_sub_section_detail_data.endsWith(
                                ".pdf"
                              )
                                ? {
                                    position: "absolute",
                                    top: "0",
                                    right: "0",
                                  }
                                : {
                                    position: "absolute",
                                    top: "0",
                                    right: "0",
                                  }
                            }
                          >
                            <button
                              className="remove-image-button"
                              onClick={() =>
                                handleRemoveSubSectionImage(
                                  `subSection_${section.id}`
                                )
                              }
                            >
                              <i className="fa fa-times"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {image && image[`subSection_${section.id}`] ? (
                    <div className="col-md-12 mb-4" key={section.id}>
                      <label className="form-label">
                        {section.field_title}
                      </label>
                      <div
                        className="uploaded-img-view overflow-hidden"
                        style={{
                          height: "200px",
                          width: "200px",
                          position: "relative",
                        }}
                      >
                        <div>
                          <a
                            href={image[`subSection_${section.id}`]}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              alt=""
                              // src={image[`subSection_${section.id}`]}
                              src={
                                image[`subSection_${section.id}`].endsWith(
                                  ".pdf"
                                )
                                  ? pdfImg
                                  : image[`subSection_${section.id}`]
                              }
                              style={
                                image[`subSection_${section.id}`].endsWith(
                                  ".pdf"
                                )
                                  ? {
                                      width: "68%",
                                      height: "68%",
                                    }
                                  : {
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                    }
                              }
                            />
                          </a>
                          <div
                            style={
                              image[`subSection_${section.id}`].endsWith(".pdf")
                                ? {
                                    position: "absolute",
                                    top: "0",
                                    right: "35px",
                                  }
                                : {
                                    position: "absolute",
                                    top: "0",
                                    right: "0",
                                  }
                            }
                            // style={{
                            //   position: "absolute",
                            //   top: "0",
                            //   right: "0",
                            // }}
                          >
                            <button
                              className="remove-image-button"
                              onClick={() =>
                                handleRemoveSubSectionImage(
                                  `subSection_${section.id}`
                                )
                              }
                            >
                              <i className="fa fa-times"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {!image[`subSection_${section.id}`] &&
                  !sectionimageData["subSection_" + section.id] ? (
                    <div className="col-12 mb-4" key={section.id}>
                      <label className="form-label">
                        {section.field_title}
                      </label>
                      <label
                        htmlFor="UploadCover"
                        className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5"
                        onClick={() =>
                          showCropMediaModal(
                            `subSection_${section.id}`,
                            section.image_dimension
                          )
                        }
                      >
                        <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                          <button
                            type="button"
                            className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                          >
                            Upload from gallery
                          </button>
                        </div>
                      </label>

                      {formErrors[section.id] && (
                        <p
                          style={{
                            color: "red",
                          }}
                        >
                          {formErrors[section.id]}
                        </p>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </>
              );
            case "FILE_VIDEO":
              return (
                <>
                  {sectionimageData["subSection_" + section.id] &&
                  section.page_sub_section_detail_data ? (
                    <div className="col-md-12 mb-4" key={section.id}>
                      <label className="form-label">
                        {section.field_title}
                      </label>

                      <div
                        className="uploaded-img-view overflow-hidden"
                        style={{
                          height: "200px",
                          width: "200px",
                          position: "relative",
                        }}
                      >
                        <div>
                          <a target="_blank">
                            <img
                              // alt="subsection_image"
                              src={videoImg}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                            />
                          </a>
                          <div
                            style={{
                              position: "absolute",
                              top: "0",
                              right: "0",
                            }}
                          >
                            <button
                              className="remove-image-button"
                              onClick={() =>
                                handleRemoveSubSectionImage(
                                  `subSection_${section.id}`
                                )
                              }
                            >
                              <i className="fa fa-times"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {mainVideoFile &&
                  mainVideoFile[`subSection_${section.id}`] ? (
                    <div className="col-md-12 mb-4" key={section.id}>
                      <label className="form-label">
                        {section.field_title}
                      </label>

                      <div
                        className="uploaded-img-view overflow-hidden"
                        style={{
                          height: "200px",
                          width: "200px",
                          position: "relative",
                        }}
                      >
                        <div>
                          <a target="_blank">
                            <img
                              // alt="subsection_image"
                              src={videoImg}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                            />
                          </a>
                          <div
                            style={{
                              position: "absolute",
                              top: "0",
                              right: "0",
                            }}
                          >
                            <button
                              className="remove-image-button"
                              onClick={() =>
                                handleRemoveSubSectionImage(
                                  `subSection_${section.id}`
                                )
                              }
                            >
                              <i className="fa fa-times"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {!mainVideoFile[`subSection_${section.id}`] &&
                  !sectionimageData["subSection_" + section.id] ? (
                    <div className="col-12 mb-4" key={section.id}>
                      <label className="form-label">
                        {section.field_title}
                      </label>
                      <label
                        htmlFor="UploadCover"
                        className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5"
                        onClick={() =>
                          showCropMediaModal(
                            `subSection_${section.id}`,
                            section.image_dimension
                          )
                        }
                      >
                        <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                          <button
                            type="button"
                            className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                          >
                            Upload video from gallery
                          </button>
                        </div>
                      </label>

                      {formErrors[section.id] && (
                        <p
                          style={{
                            color: "red",
                          }}
                        >
                          {formErrors[section.id]}
                        </p>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </>
              );
            case "FILE_ICON":
              return (
                <>
                  {sectionimageData["subSection_" + section.id] &&
                  section.page_sub_section_detail_data ? (
                    <div className="col-12  mb-4" key={section.id}>
                      <label className="form-label">
                        {section.field_title}
                      </label>
                      <div
                        className="uploaded-img-view overflow-hidden"
                        style={{
                          height: "200px",
                          width: "200px",
                          position: "relative",
                        }}
                      >
                        <div>
                          <a
                            href={section.page_sub_section_detail_data}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              // // alt="subsection_image"
                              src={section.page_sub_section_detail_data}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                backgroundColor: "darkgrey",
                              }}
                            />
                          </a>
                          <div
                            style={{
                              position: "absolute",
                              top: "0",
                              right: "0",
                            }}
                          >
                            <button
                              className="remove-image-button"
                              onClick={() =>
                                handleRemoveSubSectionImage(
                                  `subSection_${section.id}`
                                )
                              }
                            >
                              <i className="fa fa-times"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {image && image[`subSection_${section.id}`] ? (
                    <>
                      <div className="col-12 mb-4">
                        <label className="form-label">
                          {section.field_title}
                        </label>{" "}
                        <div
                          className="uploaded-img-view overflow-hidden"
                          style={{
                            height: "200px",
                            width: "200px",
                            position: "relative",
                          }}
                        >
                          <div>
                            <a
                              href={image[`subSection_${section.id}`]}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                // // alt="subsection_image"
                                src={image[`subSection_${section.id}`]}
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                  backgroundColor: "darkgrey",
                                }}
                              />
                            </a>
                            <div
                              style={{
                                position: "absolute",
                                top: "0",
                                right: "0",
                              }}
                            >
                              <button
                                className="remove-image-button"
                                onClick={() =>
                                  handleRemoveSubSectionImage(
                                    `subSection_${section.id}`
                                  )
                                }
                              >
                                <i className="fa fa-times"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  {!image[`subSection_${section.id}`] &&
                  !sectionimageData["subSection_" + section.id] ? (
                    <div className="col-md-12 mb-4" key={section.id}>
                      <label className="form-label">
                        {section.field_title}
                      </label>
                      <label
                        htmlFor="UploadCover"
                        className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5"
                        onClick={() =>
                          showCropMediaModal(
                            `subSection_${section.id}`,
                            section.image_dimension
                          )
                        }
                      >
                        <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                          <button
                            type="button"
                            className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                          >
                            Upload from gallery
                          </button>
                        </div>
                      </label>

                      {formErrors[section.id] && (
                        <p style={{ color: "red" }}>{formErrors[section.id]}</p>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </>
              );
            case "FACEBOOK":
              return (
                <div className="col-md-12 mb-4">
                  <div className="facility-view social-link">
                    <label className="form-label">Facebook</label>
                    <div className="input-group">
                      <span className="input-group-text px-4" id="basic-addon1">
                        <i className="fa fa-facebook" aria-hidden="true"></i>
                      </span>
                      <input
                        className="form-control py-2 shadow-none px-3"
                        id={section.field_title}
                        name={section.id}
                        defaultValue={
                          sectionDataArray[section.id] ||
                          section.page_sub_section_detail_data
                        }
                        onChange={handleSubSectionChange}
                        required
                      />
                    </div>
                  </div>
                </div>
              );
            case "INSTAGRAM":
              return (
                <div className="col-md-12 mb-4">
                  <div className="facility-view  social-link">
                    <label className="form-label">Instagram</label>
                    <div className="input-group">
                      <span className="input-group-text px-4" id="basic-addon1">
                        <i className="fa fa-instagram" aria-hidden="true"></i>
                      </span>
                      <input
                        className="form-control py-2 shadow-none px-3"
                        id={section.field_title}
                        name={section.id}
                        defaultValue={
                          sectionDataArray[section.id] ||
                          section.page_sub_section_detail_data
                        }
                        onChange={handleSubSectionChange}
                        required
                      />
                    </div>
                  </div>
                </div>
              );
            case "LINKEDIN":
              return (
                <div className="col-md-12 mb-4">
                  <div className="facility-view  social-link">
                    <label className="form-label">Linkedin</label>
                    <div className="input-group">
                      <span className="input-group-text px-4" id="basic-addon1">
                        <i className="fa fa-linkedin" aria-hidden="true"></i>
                      </span>
                      <input
                        className="form-control py-2 shadow-none px-3"
                        id={section.field_title}
                        name={section.id}
                        defaultValue={
                          sectionDataArray[section.id] ||
                          section.page_sub_section_detail_data
                        }
                        onChange={handleSubSectionChange}
                        required
                      />
                    </div>
                  </div>
                </div>
              );

            default:
              return null;
          }
        })}

        {subsection.child?.filter((m) => m.is_deletable === false).length >
        0 ? (
          <hr className="mt-4" />
        ) : (
          ""
        )}
        <div className="row mt-4">
          <div className="col-xxl-3 col-xl-4 col-lg-5 mb-lg-0 mb-4">
            <div className="left-tabs">
              <ul className="nav flex-column" id="myTab" role="tablist">
                {subsection.child
                  ?.filter((m) => m.is_deletable === false)
                  .map((childsubsection, index) => {
                    return (
                      <>
                        <li
                          // className="nav-item"
                          // role="presentation"
                          // key={`${childsubsection.page_sub_section_id}-tablink`}
                          className="nav-item w-100 position-relative"
                          id={`TabLogoOne-tab-${childsubsection.page_sub_section_id}`}
                          data-bs-toggle="tab"
                          data-bs-target={`#TabLogoOne-tab-pane-${childsubsection.page_sub_section_id}`}
                          type="button"
                          role="tab"
                          aria-controls={`TabLogoOne-tab-pane-${childsubsection.page_sub_section_id}`}
                          aria-selected="false"
                          tabIndex="-1"
                        >
                          <div className="nav-link w-100 position-relative ps-3 pe-3">
                            {childsubsection.sub_section_title}
                            {childsubsection.is_deletable ? (
                              <span
                                className=" text-dark position-absolute end-0 top-0 h-100 d-flex align-items-center px-5"
                                onClick={() =>
                                  handleShowEditSubSectionModal(childsubsection)
                                }
                              >
                                <i
                                  className="fa fa-pencil"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            ) : (
                              ""
                            )}
                            {childsubsection.is_deletable ? (
                              <span
                                className="delete-tab text-dark position-absolute end-0 top-0 h-100 d-flex align-items-center px-3"
                                onClick={() => handleShowModal(childsubsection)}
                              >
                                <i
                                  className="fa fa-trash"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </li>
                      </>
                    );
                  })}
              </ul>
            </div>

            {subsection.child?.filter((m) => m.is_deletable === false)[0]
              ?.is_deletable ? (
              <div className="facility-view addmore-facility">
                <button
                  onClick={() =>
                    handleSubmitSubSection(
                      subsection.child?.filter(
                        (m) => m.is_deletable === false
                      )[0]?.page_sub_section_id
                    )
                  }
                  type="button"
                  className="btn btn-primary btn-theme outline-btn text-uppercase px-3 py-3 fw-semibold w-100"
                >
                  <i className="fa fa-plus me-2" aria-hidden="true"></i> Add New
                </button>
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="col-xxl-9 col-xl-8 col-lg-7">
            <div className="tab-content ps-lg-4 ps-3" id="myTabContent">
              {subsection.child
                ?.filter((m) => m.is_deletable === false)
                .map((childsubsection, index) => {
                  return (
                    <div
                      className="tab-pane fade"
                      id={`TabLogoOne-tab-pane-${childsubsection.page_sub_section_id}`}
                      role="tabpanel"
                      aria-labelledby={`TabLogoOne-tab-${childsubsection.page_sub_section_id}`}
                      tabIndex="0"
                    >
                      <div className="row">
                        {childsubsection.details.map((section, index) => {
                          switch (section.code) {
                            case "TEXTBOX":
                              return (
                                <div className="col-md-6 mb-4" key={section.id}>
                                  <label
                                    className="form-label"
                                    htmlFor="IconText"
                                  >
                                    {section.field_title}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control py-2 shadow-none px-3"
                                    id={section.field_title}
                                    name={section.id}
                                    defaultValue={
                                      subSectionDataArray[section.id] ||
                                      section.page_sub_section_detail_data
                                    }
                                    onChange={(e) =>
                                      handleSubSectionChange(e, section)
                                    }
                                    required
                                    {...(section.field_max_length && {
                                      maxLength: section.field_max_length,
                                    })}
                                    {...(section.field_max_length && {
                                      placeholder: `Character limit, upto ${section.field_max_length} characters`,
                                    })}
                                  />
                                </div>
                              );
                            case "TEXTAREA":
                              return (
                                <div
                                  className="col-md-12 mb-4"
                                  key={section.id}
                                >
                                  <label className="form-label" htmlFor="Body">
                                    {section.field_title}
                                  </label>
                                  <textarea
                                    className="form-control py-2 shadow-none px-3"
                                    rows="5"
                                    id={section.field_title}
                                    name={section.id}
                                    defaultValue={
                                      subSectionDataArray[section.id] ||
                                      section.page_sub_section_detail_data
                                    }
                                    onChange={(e) =>
                                      handleSubSectionChange(e, section)
                                    }
                                    required
                                    {...(section.field_max_length && {
                                      maxLength: section.field_max_length,
                                    })}
                                    {...(section.field_max_length && {
                                      placeholder: `Character limit, upto ${section.field_max_length} characters`,
                                    })}
                                  ></textarea>
                                </div>
                              );
                            case "FILE_IMAGE":
                              return (
                                <>
                                  {sectionimageData[
                                    "subSection_" + section.id
                                  ] && section.page_sub_section_detail_data ? (
                                    <>
                                      <div className="d-flex align-items-center ">
                                        <label className="form-label mb-0">
                                          {section.field_title}
                                        </label>
                                        <a
                                          title="Add image alt tag"
                                          className="btn image-tag"
                                          onClick={() =>
                                            handleShowImageTagModal(
                                              "subSection_" + section.id,
                                              section.img_alt_tag
                                            )
                                          }
                                        >
                                          <HiPlus />
                                        </a>
                                      </div>
                                      <div
                                        className="uploaded-img-view overflow-hidden"
                                        style={{
                                          height: "200px",
                                          width: "200px",
                                          position: "relative",
                                        }}
                                      >
                                        <div>
                                          <a
                                            href={
                                              section.page_sub_section_detail_data
                                            }
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            <img
                                              // alt="subsection_image"
                                              src={
                                                section.page_sub_section_detail_data
                                              }
                                              style={{
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "cover",
                                              }}
                                            />
                                          </a>
                                          <div
                                            style={{
                                              position: "absolute",
                                              top: "0",
                                              right: "0",
                                            }}
                                          >
                                            <button
                                              className="remove-image-button"
                                              onClick={() =>
                                                handleRemoveSubSectionImage(
                                                  `subSection_${section.id}`
                                                )
                                              }
                                            >
                                              <i className="fa fa-times"></i>
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {image &&
                                  image[`subSection_${section.id}`] ? (
                                    <>
                                      <div className="d-flex align-items-center">
                                        <label className="form-label mb-0">
                                          {section.field_title}
                                        </label>{" "}
                                        <a
                                          title="Add image alt tag"
                                          className="btn image-tag"
                                          onClick={() =>
                                            handleShowImageTagModal(
                                              "subSection_" + section.id,
                                              section.img_alt_tag
                                            )
                                          }
                                        >
                                          <HiPlus />
                                        </a>
                                      </div>
                                      <div
                                        className="uploaded-img-view overflow-hidden"
                                        style={{
                                          height: "200px",
                                          width: "200px",
                                          position: "relative",
                                        }}
                                      >
                                        <div>
                                          <a
                                            href={
                                              image[`subSection_${section.id}`]
                                            }
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            <img
                                              // alt="subsection_image"
                                              src={
                                                image[
                                                  `subSection_${section.id}`
                                                ]
                                              }
                                              style={{
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "cover",
                                              }}
                                            />
                                          </a>
                                          <div
                                            style={{
                                              position: "absolute",
                                              top: "0",
                                              right: "0",
                                            }}
                                          >
                                            <button
                                              className="remove-image-button"
                                              onClick={() =>
                                                handleRemoveSubSectionImage(
                                                  `subSection_${section.id}`
                                                )
                                              }
                                            >
                                              <i className="fa fa-times"></i>
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}

                                  {!image[`subSection_${section.id}`] &&
                                  !sectionimageData[
                                    "subSection_" + section.id
                                  ] ? (
                                    <div
                                      className="col-12 mb-4"
                                      key={section.id}
                                    >
                                      <div className="d-flex align-items-center">
                                        <label className="form-label mb-0">
                                          {section.field_title}
                                        </label>
                                        <a
                                          title="Add image alt tag"
                                          className="btn image-tag"
                                          onClick={() =>
                                            handleShowImageTagModal(
                                              "subSection_" + section.id,
                                              section.img_alt_tag
                                            )
                                          }
                                        >
                                          <HiPlus />
                                        </a>
                                      </div>
                                      <label
                                        htmlFor="UploadCover"
                                        className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5"
                                        onClick={() =>
                                          showCropMediaModal(
                                            `subSection_${section.id}`,
                                            section.image_dimension
                                          )
                                        }
                                      >
                                        <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                                          <button
                                            type="button"
                                            className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                                          >
                                            Upload image from gallery
                                          </button>
                                        </div>
                                      </label>

                                      {formErrors[section.id] && (
                                        <p
                                          style={{
                                            color: "red",
                                          }}
                                        >
                                          {formErrors[section.id]}
                                        </p>
                                      )}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </>
                              );
                            case "FILE_PDF":
                              return (
                                <>
                                  {sectionimageData[
                                    "subSection_" + section.id
                                  ] && section.page_sub_section_detail_data ? (
                                    <>
                                      <label className="form-label">
                                        {section.field_title}
                                      </label>

                                      <div
                                        className="uploaded-img-view overflow-hidden"
                                        style={{
                                          height: "200px",
                                          width: "200px",
                                          position: "relative",
                                        }}
                                      >
                                        <div>
                                          <a
                                            href={
                                              section.page_sub_section_detail_data
                                            }
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            <img
                                              src={
                                                section.page_sub_section_detail_data.endsWith(
                                                  ".pdf"
                                                )
                                                  ? pdfImg
                                                  : section.page_sub_section_detail_data
                                              }
                                              style={
                                                section.page_sub_section_detail_data.endsWith(
                                                  ".pdf"
                                                )
                                                  ? {
                                                      width: "68%",
                                                      height: "68%",
                                                    }
                                                  : {
                                                      width: "100%",
                                                      height: "100%",
                                                      objectFit: "cover",
                                                    }
                                              }
                                            />
                                          </a>
                                          <div
                                            style={
                                              section.page_sub_section_detail_data.endsWith(
                                                ".pdf"
                                              )
                                                ? {
                                                    position: "absolute",
                                                    top: "0",
                                                    right: "35px",
                                                  }
                                                : {
                                                    position: "absolute",
                                                    top: "0",
                                                    right: "0",
                                                  }
                                            }
                                          >
                                            <button
                                              className="remove-image-button"
                                              onClick={() =>
                                                handleRemoveSubSectionImage(
                                                  `subSection_${section.id}`
                                                )
                                              }
                                            >
                                              <i className="fa fa-times"></i>
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {image &&
                                  image[`subSection_${section.id}`] ? (
                                    <>
                                      <label className="form-label">
                                        {section.field_title}
                                      </label>
                                      <div
                                        className="uploaded-img-view overflow-hidden"
                                        style={{
                                          height: "200px",
                                          width: "200px",
                                          position: "relative",
                                        }}
                                      >
                                        <div>
                                          <a
                                            href={
                                              image[`subSection_${section.id}`]
                                            }
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            <img
                                              // alt="subsection_image"
                                              src={
                                                image[
                                                  `subSection_${section.id}`
                                                ].endsWith(".pdf")
                                                  ? pdfImg
                                                  : image[
                                                      `subSection_${section.id}`
                                                    ]
                                              }
                                              style={
                                                image[
                                                  `subSection_${section.id}`
                                                ].endsWith(".pdf")
                                                  ? {
                                                      width: "68%",
                                                      height: "68%",
                                                    }
                                                  : {
                                                      width: "100%",
                                                      height: "100%",
                                                      objectFit: "cover",
                                                    }
                                              }
                                            />
                                          </a>
                                          <div
                                            style={{
                                              position: "absolute",
                                              top: "0",
                                              right: "0",
                                            }}
                                          >
                                            <button
                                              className="remove-image-button"
                                              onClick={() =>
                                                handleRemoveSubSectionImage(
                                                  `subSection_${section.id}`
                                                )
                                              }
                                            >
                                              <i className="fa fa-times"></i>
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}

                                  {!image[`subSection_${section.id}`] &&
                                  !sectionimageData[
                                    "subSection_" + section.id
                                  ] ? (
                                    <div
                                      className="col-12 mb-4"
                                      key={section.id}
                                    >
                                      <label className="form-label">
                                        {section.field_title}
                                      </label>
                                      <label
                                        htmlFor="UploadCover"
                                        className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5"
                                        onClick={() =>
                                          showCropMediaModal(
                                            `subSection_${section.id}`,
                                            section.image_dimension
                                          )
                                        }
                                      >
                                        <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                                          <button
                                            type="button"
                                            className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                                          >
                                            Upload image from gallery
                                          </button>
                                        </div>
                                      </label>

                                      {formErrors[section.id] && (
                                        <p
                                          style={{
                                            color: "red",
                                          }}
                                        >
                                          {formErrors[section.id]}
                                        </p>
                                      )}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </>
                              );
                            case "FILE_VIDEO":
                              return (
                                <>
                                  {sectionimageData[
                                    "subSection_" + section.id
                                  ] && section.page_sub_section_detail_data ? (
                                    <>
                                      <label className="form-label">
                                        {section.field_title}
                                      </label>

                                      <div
                                        className="uploaded-img-view overflow-hidden"
                                        style={{
                                          height: "200px",
                                          width: "200px",
                                          position: "relative",
                                        }}
                                      >
                                        <div>
                                          <a target="_blank">
                                            <img
                                              // alt="subsection_image"
                                              src={videoImg}
                                              style={{
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "cover",
                                              }}
                                            />
                                          </a>
                                          <div
                                            style={{
                                              position: "absolute",
                                              top: "0",
                                              right: "0",
                                            }}
                                          >
                                            <button
                                              className="remove-image-button"
                                              onClick={() =>
                                                handleRemoveSubSectionImage(
                                                  `subSection_${section.id}`
                                                )
                                              }
                                            >
                                              <i className="fa fa-times"></i>
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {mainVideoFile &&
                                  mainVideoFile[`subSection_${section.id}`] ? (
                                    <>
                                      <label className="form-label">
                                        {section.field_title}
                                      </label>

                                      <div
                                        className="uploaded-img-view overflow-hidden"
                                        style={{
                                          height: "200px",
                                          width: "200px",
                                          position: "relative",
                                        }}
                                      >
                                        <div>
                                          <a target="_blank">
                                            <img
                                              // alt="subsection_image"
                                              src={videoImg}
                                              style={{
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "cover",
                                              }}
                                            />
                                          </a>
                                          <div
                                            style={{
                                              position: "absolute",
                                              top: "0",
                                              right: "0",
                                            }}
                                          >
                                            <button
                                              className="remove-image-button"
                                              onClick={() =>
                                                handleRemoveSubSectionImage(
                                                  `subSection_${section.id}`
                                                )
                                              }
                                            >
                                              <i className="fa fa-times"></i>
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}

                                  {!mainVideoFile[`subSection_${section.id}`] &&
                                  !sectionimageData[
                                    "subSection_" + section.id
                                  ] ? (
                                    <div
                                      className="col-12 mb-4"
                                      key={section.id}
                                    >
                                      <label className="form-label">
                                        {section.field_title}
                                      </label>
                                      <label
                                        htmlFor="UploadCover"
                                        className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5"
                                        onClick={() =>
                                          showCropMediaModal(
                                            `subSection_${section.id}`,
                                            section.image_dimension
                                          )
                                        }
                                      >
                                        <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                                          <button
                                            type="button"
                                            className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                                          >
                                            Upload video from gallery
                                          </button>
                                        </div>
                                      </label>

                                      {formErrors[section.id] && (
                                        <p
                                          style={{
                                            color: "red",
                                          }}
                                        >
                                          {formErrors[section.id]}
                                        </p>
                                      )}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </>
                              );
                            case "FILE_ICON":
                              return (
                                <>
                                  {sectionimageData[
                                    "subSection_" + section.id
                                  ] && section.page_sub_section_detail_data ? (
                                    <>
                                      <div className="col-12 mb-4 mt-3">
                                        <label className="form-label">
                                          {section.field_title}
                                        </label>
                                        <div
                                          className="uploaded-img-view overflow-hidden"
                                          style={{
                                            height: "200px",
                                            width: "200px",
                                            position: "relative",
                                          }}
                                        >
                                          <div>
                                            <a
                                              href={
                                                section.page_sub_section_detail_data
                                              }
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              <img
                                                src={
                                                  section.page_sub_section_detail_data
                                                }
                                                style={{
                                                  width: "100%",
                                                  height: "100%",
                                                  objectFit: "cover",
                                                  backgroundColor: "darkgrey",
                                                }}
                                              />
                                            </a>
                                            <div
                                              style={{
                                                position: "absolute",
                                                top: "0",
                                                right: "0",
                                              }}
                                            >
                                              <button
                                                className="remove-image-button"
                                                onClick={() =>
                                                  handleRemoveSubSectionImage(
                                                    `subSection_${section.id}`
                                                  )
                                                }
                                              >
                                                <i className="fa fa-times"></i>
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {image &&
                                  image[`subSection_${section.id}`] ? (
                                    <>
                                      <div className="col-12 mb-4 mt-3">
                                        <label className="form-label">
                                          {section.field_title}
                                        </label>{" "}
                                        <div
                                          className="uploaded-img-view overflow-hidden"
                                          style={{
                                            height: "200px",
                                            width: "200px",
                                            position: "relative",
                                          }}
                                        >
                                          <div>
                                            <a
                                              href={
                                                image[
                                                  `subSection_${section.id}`
                                                ]
                                              }
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              <img
                                                // alt="subsection_image"
                                                src={
                                                  image[
                                                    `subSection_${section.id}`
                                                  ]
                                                }
                                                style={{
                                                  width: "100%",
                                                  height: "100%",
                                                  objectFit: "cover",
                                                  backgroundColor: "darkgrey",
                                                }}
                                              />
                                            </a>
                                            <div
                                              style={{
                                                position: "absolute",
                                                top: "0",
                                                right: "0",
                                              }}
                                            >
                                              <button
                                                className="remove-image-button"
                                                onClick={() =>
                                                  handleRemoveSubSectionImage(
                                                    `subSection_${section.id}`
                                                  )
                                                }
                                              >
                                                <i className="fa fa-times"></i>
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}

                                  {!image[`subSection_${section.id}`] &&
                                  !sectionimageData[
                                    "subSection_" + section.id
                                  ] ? (
                                    <div
                                      className="col-md-12 mb-4 mt-3"
                                      key={section.id}
                                    >
                                      <label className="form-label">
                                        {section.field_title}
                                      </label>
                                      <label
                                        htmlFor="UploadCover"
                                        className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5"
                                        onClick={() =>
                                          showCropMediaModal(
                                            `subSection_${section.id}`,
                                            section.image_dimension
                                          )
                                        }
                                      >
                                        <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                                          <button
                                            type="button"
                                            className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                                          >
                                            Upload from gallery
                                          </button>
                                        </div>
                                      </label>

                                      {formErrors[section.id] && (
                                        <p style={{ color: "red" }}>
                                          {formErrors[section.id]}
                                        </p>
                                      )}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </>
                              );
                            case "FACEBOOK":
                              return (
                                <div className="col-md-12 mb-4">
                                  <div className="facility-view social-link">
                                    <label className="form-label">
                                      Facebook
                                    </label>
                                    <div className="input-group">
                                      <span
                                        className="input-group-text px-4"
                                        id="basic-addon1"
                                      >
                                        <i
                                          className="fa fa-facebook"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                      <input
                                        className="form-control py-2 shadow-none px-3"
                                        id={section.field_title}
                                        name={section.id}
                                        defaultValue={
                                          sectionDataArray[section.id] ||
                                          section.page_sub_section_detail_data
                                        }
                                        onChange={handleSubSectionChange}
                                        required
                                      />
                                    </div>
                                  </div>
                                </div>
                              );
                            case "INSTAGRAM":
                              return (
                                <div className="col-md-12 mb-4">
                                  <div className="facility-view  social-link">
                                    <label className="form-label">
                                      Instagram
                                    </label>
                                    <div className="input-group">
                                      <span
                                        className="input-group-text px-4"
                                        id="basic-addon1"
                                      >
                                        <i
                                          className="fa fa-instagram"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                      <input
                                        className="form-control py-2 shadow-none px-3"
                                        id={section.field_title}
                                        name={section.id}
                                        defaultValue={
                                          sectionDataArray[section.id] ||
                                          section.page_sub_section_detail_data
                                        }
                                        onChange={handleSubSectionChange}
                                        required
                                      />
                                    </div>
                                  </div>
                                </div>
                              );
                            case "LINKEDIN":
                              return (
                                <div className="col-md-12 mb-4">
                                  <div className="facility-view  social-link">
                                    <label className="form-label">
                                      Linkedin
                                    </label>
                                    <div className="input-group">
                                      <span
                                        className="input-group-text px-4"
                                        id="basic-addon1"
                                      >
                                        <i
                                          className="fa fa-linkedin"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                      <input
                                        className="form-control py-2 shadow-none px-3"
                                        id={section.field_title}
                                        name={section.id}
                                        defaultValue={
                                          sectionDataArray[section.id] ||
                                          section.page_sub_section_detail_data
                                        }
                                        onChange={handleSubSectionChange}
                                        required
                                      />
                                    </div>
                                  </div>
                                </div>
                              );
                            default:
                              return null; // or a default component for unknown types
                          }
                        })}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>

        {/* content of is_deletable true */}
        {subsection.child?.filter((m) => m.is_deletable === true).length > 0 ? (
          <hr className="mt-4" />
        ) : (
          ""
        )}
        <div className="row mt-4">
          <div className="col-xxl-3 col-xl-4 col-lg-5 mb-lg-0 mb-4">
            <div className="left-tabs">
              <Tabs
                data={childSectionSortingData?.filter(
                  (m) => m.is_deletable === true
                )}
                changeSortingIndexMutation={changeSortingIndexMutation}
                handleShowEditSubSectionModal={handleShowEditSubSectionModal}
                handleShowModal={handleShowModal}
                handleReorderSubSections={handleReorderSubSections}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            </div>
            {subsection.child?.filter((m) => m.is_deletable === true)[0]
              ?.is_deletable ? (
              <div className="facility-view addmore-facility">
                <button
                  onClick={() =>
                    handleSubmitSubSection(
                      subsection.child?.filter(
                        (m) => m.is_deletable === true
                      )[0]?.page_sub_section_id
                    )
                  }
                  type="button"
                  className="btn btn-primary btn-theme outline-btn text-uppercase px-3 py-3 fw-semibold w-100"
                >
                  <i className="fa fa-plus me-2" aria-hidden="true"></i> Add New
                </button>
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="col-xxl-9 col-xl-8 col-lg-7">
            <div className="tab-content ps-lg-4 ps-3" id="myTabContent">
              {subsection.child
                ?.filter((m) => m.is_deletable === true)
                .map((childsubsection, index) => {
                  return (
                    <div
                      // className="tab-pane fade"
                      className={`tab-pane fade ${
                        activeTab === childsubsection.page_sub_section_id
                          ? "show active"
                          : ""
                      }`}
                      // id={`TabLogoOne-tab-pane-${childsubsection.page_sub_section_id}`}
                      role="tabpanel"
                      // aria-labelledby={`TabLogoOne-tab-${childsubsection.page_sub_section_id}`}
                      // tabIndex="0"
                    >
                      <div className="row">
                        {childsubsection.details.map((section, index) => {
                          switch (section.code) {
                            case "TEXTBOX":
                              return (
                                <div className="col-md-6 mb-4" key={section.id}>
                                  <label
                                    className="form-label"
                                    htmlFor="IconText"
                                  >
                                    {section.field_title}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control py-2 shadow-none px-3"
                                    id={section.field_title}
                                    name={section.id}
                                    defaultValue={
                                      subSectionDataArray[section.id] ||
                                      section.page_sub_section_detail_data
                                    }
                                    onChange={(e) =>
                                      handleSubSectionChange(e, section)
                                    }
                                    required
                                    {...(section.field_max_length && {
                                      maxLength: section.field_max_length,
                                    })}
                                    {...(section.field_max_length && {
                                      placeholder: `Character limit, upto ${section.field_max_length} characters`,
                                    })}
                                  />
                                </div>
                              );
                            case "TEXTAREA":
                              return (
                                <div
                                  className="col-md-12 mb-4"
                                  key={section.id}
                                >
                                  <label className="form-label" htmlFor="Body">
                                    {section.field_title}
                                  </label>
                                  <textarea
                                    className="form-control py-2 shadow-none px-3"
                                    rows="5"
                                    id={section.field_title}
                                    name={section.id}
                                    defaultValue={
                                      subSectionDataArray[section.id] ||
                                      section.page_sub_section_detail_data
                                    }
                                    onChange={(e) =>
                                      handleSubSectionChange(e, section)
                                    }
                                    {...(section.field_max_length && {
                                      maxLength: section.field_max_length,
                                    })}
                                    {...(section.field_max_length && {
                                      placeholder: `Character limit, upto ${section.field_max_length} characters`,
                                    })}
                                    required
                                  ></textarea>
                                </div>
                              );
                            case "FILE_IMAGE":
                              return (
                                <>
                                  {sectionimageData[
                                    "subSection_" + section.id
                                  ] && section.page_sub_section_detail_data ? (
                                    <div className="col-md-12">
                                      <div className="d-flex align-items-center">
                                        <label className="form-label mb-0">
                                          {section.field_title}
                                        </label>
                                        <a
                                          title="Add image alt tag"
                                          className="btn image-tag"
                                          onClick={() =>
                                            handleShowImageTagModal(
                                              "subSection_" + section.id,
                                              section.img_alt_tag
                                            )
                                          }
                                        >
                                          <HiPlus />
                                        </a>
                                      </div>
                                      <div
                                        className="uploaded-img-view overflow-hidden"
                                        style={{
                                          height: "200px",
                                          width: "200px",
                                          position: "relative",
                                        }}
                                      >
                                        <div>
                                          <a
                                            href={
                                              section.page_sub_section_detail_data
                                            }
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            <img
                                              // alt="subsection_image"
                                              src={
                                                section.page_sub_section_detail_data
                                              }
                                              style={{
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "cover",
                                              }}
                                            />
                                          </a>
                                          <div
                                            style={{
                                              position: "absolute",
                                              top: "0",
                                              right: "0",
                                            }}
                                          >
                                            <button
                                              className="remove-image-button"
                                              onClick={() =>
                                                handleRemoveSubSectionImage(
                                                  `subSection_${section.id}`
                                                )
                                              }
                                            >
                                              <i className="fa fa-times"></i>
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  {image &&
                                  image[`subSection_${section.id}`] ? (
                                    <div className="col-md-12">
                                      <div className="d-flex align-items-center">
                                        <label className="form-label mb-0">
                                          {section.field_title}
                                        </label>{" "}
                                        <a
                                          title="Add image alt tag"
                                          className="btn image-tag"
                                          onClick={() =>
                                            handleShowImageTagModal(
                                              "subSection_" + section.id,
                                              section.img_alt_tag
                                            )
                                          }
                                        >
                                          <HiPlus />
                                        </a>
                                      </div>
                                      <div
                                        className="uploaded-img-view overflow-hidden"
                                        style={{
                                          height: "200px",
                                          width: "200px",
                                          position: "relative",
                                        }}
                                      >
                                        <div>
                                          <a
                                            href={
                                              image[`subSection_${section.id}`]
                                            }
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            <img
                                              // alt="subsection_image"
                                              src={
                                                image[
                                                  `subSection_${section.id}`
                                                ]
                                              }
                                              style={{
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "cover",
                                              }}
                                            />
                                          </a>
                                          <div
                                            style={{
                                              position: "absolute",
                                              top: "0",
                                              right: "0",
                                            }}
                                          >
                                            <button
                                              className="remove-image-button"
                                              onClick={() =>
                                                handleRemoveSubSectionImage(
                                                  `subSection_${section.id}`
                                                )
                                              }
                                            >
                                              <i className="fa fa-times"></i>
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}

                                  {!image[`subSection_${section.id}`] &&
                                  !sectionimageData[
                                    "subSection_" + section.id
                                  ] ? (
                                    <div
                                      className="col-12 mb-4"
                                      key={section.id}
                                    >
                                      <div className="d-flex align-items-center">
                                        <label className="form-label mb-0">
                                          {section.field_title}
                                        </label>
                                        <a
                                          title="Add image alt tag"
                                          className="btn image-tag"
                                          onClick={() =>
                                            handleShowImageTagModal(
                                              "subSection_" + section.id,
                                              section.img_alt_tag
                                            )
                                          }
                                        >
                                          <HiPlus />
                                        </a>
                                      </div>
                                      <label
                                        htmlFor="UploadCover"
                                        className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5"
                                        onClick={() =>
                                          showCropMediaModal(
                                            `subSection_${section.id}`,
                                            section.image_dimension
                                          )
                                        }
                                      >
                                        <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                                          <button
                                            type="button"
                                            className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                                          >
                                            Upload image from gallery
                                          </button>
                                        </div>
                                      </label>

                                      {formErrors[section.id] && (
                                        <p
                                          style={{
                                            color: "red",
                                          }}
                                        >
                                          {formErrors[section.id]}
                                        </p>
                                      )}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </>
                              );
                            case "FILE_PDF":
                              return (
                                <>
                                  {sectionimageData[
                                    "subSection_" + section.id
                                  ] && section.page_sub_section_detail_data ? (
                                    <>
                                      <label className="form-label">
                                        {section.field_title}
                                      </label>
                                      <div
                                        className="uploaded-img-view overflow-hidden"
                                        style={{
                                          height: "200px",
                                          width: "200px",
                                          position: "relative",
                                        }}
                                      >
                                        <div>
                                          <a
                                            href={
                                              section.page_sub_section_detail_data
                                            }
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            <img
                                              // alt="subsection_image"
                                              // src={
                                              //   section.page_sub_section_detail_data
                                              // }
                                              src={
                                                section.page_sub_section_detail_data.endsWith(
                                                  ".pdf"
                                                )
                                                  ? pdfImg
                                                  : section.page_sub_section_detail_data
                                              }
                                              style={
                                                section.page_sub_section_detail_data.endsWith(
                                                  ".pdf"
                                                )
                                                  ? {
                                                      width: "68%",
                                                      height: "68%",
                                                    }
                                                  : {
                                                      width: "100%",
                                                      height: "100%",
                                                      objectFit: "cover",
                                                    }
                                              }
                                            />
                                          </a>
                                          <div
                                            // style={{
                                            //   position: "absolute",
                                            //   top: "0",
                                            //   right: "0",
                                            // }}
                                            style={
                                              section.page_sub_section_detail_data.endsWith(
                                                ".pdf"
                                              )
                                                ? {
                                                    position: "absolute",
                                                    top: "0",
                                                    right: "35px",
                                                  }
                                                : {
                                                    position: "absolute",
                                                    top: "0",
                                                    right: "0",
                                                  }
                                            }
                                          >
                                            <button
                                              className="remove-image-button"
                                              onClick={() =>
                                                handleRemoveSubSectionImage(
                                                  `subSection_${section.id}`
                                                )
                                              }
                                            >
                                              <i className="fa fa-times"></i>
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {image &&
                                  image[`subSection_${section.id}`] ? (
                                    <>
                                      <label className="form-label">
                                        {section.field_title}
                                      </label>
                                      <div
                                        className="uploaded-img-view overflow-hidden"
                                        style={{
                                          height: "200px",
                                          width: "200px",
                                          position: "relative",
                                        }}
                                      >
                                        <div>
                                          <a
                                            href={
                                              image[`subSection_${section.id}`]
                                            }
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            <img
                                              // alt="subsection_image"
                                              src={
                                                image[
                                                  `subSection_${section.id}`
                                                ].endsWith(".pdf")
                                                  ? pdfImg
                                                  : image[
                                                      `subSection_${section.id}`
                                                    ]
                                              }
                                              style={{
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "cover",
                                              }}
                                            />
                                          </a>
                                          <div
                                            style={{
                                              position: "absolute",
                                              top: "0",
                                              right: "0",
                                            }}
                                          >
                                            <button
                                              className="remove-image-button"
                                              onClick={() =>
                                                handleRemoveSubSectionImage(
                                                  `subSection_${section.id}`
                                                )
                                              }
                                            >
                                              <i className="fa fa-times"></i>
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}

                                  {!image[`subSection_${section.id}`] &&
                                  !sectionimageData[
                                    "subSection_" + section.id
                                  ] ? (
                                    <div
                                      className="col-12 mb-4"
                                      key={section.id}
                                    >
                                      <label className="form-label">
                                        {section.field_title}
                                      </label>
                                      <label
                                        htmlFor="UploadCover"
                                        className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5"
                                        onClick={() =>
                                          showCropMediaModal(
                                            `subSection_${section.id}`,
                                            section.image_dimension
                                          )
                                        }
                                      >
                                        <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                                          <button
                                            type="button"
                                            className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                                          >
                                            Upload image from gallery
                                          </button>
                                        </div>
                                      </label>

                                      {formErrors[section.id] && (
                                        <p
                                          style={{
                                            color: "red",
                                          }}
                                        >
                                          {formErrors[section.id]}
                                        </p>
                                      )}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </>
                              );
                            // Add more cases as needed
                            case "FILE_VIDEO":
                              return (
                                <>
                                  {sectionimageData[
                                    "subSection_" + section.id
                                  ] && section.page_sub_section_detail_data ? (
                                    <>
                                      <label className="form-label">
                                        {section.field_title}
                                      </label>

                                      <div
                                        className="uploaded-img-view overflow-hidden"
                                        style={{
                                          height: "200px",
                                          width: "200px",
                                          position: "relative",
                                        }}
                                      >
                                        <div>
                                          <a target="_blank">
                                            <img
                                              // alt="subsection_image"
                                              src={videoImg}
                                              style={{
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "cover",
                                              }}
                                            />
                                          </a>
                                          <div
                                            style={{
                                              position: "absolute",
                                              top: "0",
                                              right: "0",
                                            }}
                                          >
                                            <button
                                              className="remove-image-button"
                                              onClick={() =>
                                                handleRemoveSubSectionImage(
                                                  `subSection_${section.id}`
                                                )
                                              }
                                            >
                                              <i className="fa fa-times"></i>
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {mainVideoFile &&
                                  mainVideoFile[`subSection_${section.id}`] ? (
                                    <>
                                      <label className="form-label">
                                        {section.field_title}
                                      </label>

                                      <div
                                        className="uploaded-img-view overflow-hidden"
                                        style={{
                                          height: "200px",
                                          width: "200px",
                                          position: "relative",
                                        }}
                                      >
                                        <div>
                                          <a target="_blank">
                                            <img
                                              // alt="subsection_image"
                                              src={videoImg}
                                              style={{
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "cover",
                                              }}
                                            />
                                          </a>
                                          <div
                                            style={{
                                              position: "absolute",
                                              top: "0",
                                              right: "0",
                                            }}
                                          >
                                            <button
                                              className="remove-image-button"
                                              onClick={() =>
                                                handleRemoveSubSectionImage(
                                                  `subSection_${section.id}`
                                                )
                                              }
                                            >
                                              <i className="fa fa-times"></i>
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}

                                  {!mainVideoFile[`subSection_${section.id}`] &&
                                  !sectionimageData[
                                    "subSection_" + section.id
                                  ] ? (
                                    <div
                                      className="col-12 mb-4"
                                      key={section.id}
                                    >
                                      <label className="form-label">
                                        {section.field_title}
                                      </label>
                                      <label
                                        htmlFor="UploadCover"
                                        className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5"
                                        onClick={() =>
                                          showCropMediaModal(
                                            `subSection_${section.id}`,
                                            section.image_dimension
                                          )
                                        }
                                      >
                                        <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                                          <button
                                            type="button"
                                            className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                                          >
                                            Upload video from gallery
                                          </button>
                                        </div>
                                      </label>

                                      {formErrors[section.id] && (
                                        <p
                                          style={{
                                            color: "red",
                                          }}
                                        >
                                          {formErrors[section.id]}
                                        </p>
                                      )}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </>
                              );
                            case "FILE_ICON":
                              return (
                                <>
                                  {sectionimageData[
                                    "subSection_" + section.id
                                  ] && section.page_sub_section_detail_data ? (
                                    <>
                                      <div className="col-12 mt-4 mb-4">
                                        <label className="form-label">
                                          {section.field_title}
                                        </label>
                                        <div
                                          className="uploaded-img-view overflow-hidden"
                                          style={{
                                            height: "200px",
                                            width: "200px",
                                            position: "relative",
                                          }}
                                        >
                                          <div>
                                            <a
                                              href={
                                                section.page_sub_section_detail_data
                                              }
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              <img
                                                // alt="subsection_image"
                                                src={
                                                  section.page_sub_section_detail_data
                                                }
                                                style={{
                                                  // width: "100%",
                                                  height: "100%",
                                                  objectFit: "cover",
                                                  backgroundColor: "darkgrey",
                                                }}

                                                // style={
                                                //   section.page_sub_section_detail_data.endsWith(".png")||section.page_sub_section_detail_data.endsWith(".svg")
                                                //     ? {
                                                //       height: "100%",
                                                //       objectFit: "cover",
                                                //       backgroundColor:"darkgrey"
                                                //       }
                                                //     : {
                                                //       height: "100%",
                                                //       objectFit: "cover",
                                                //       }
                                                // }
                                              />
                                            </a>
                                            <div
                                              style={{
                                                position: "absolute",
                                                top: "0",
                                                right: "0",
                                              }}
                                            >
                                              <button
                                                className="remove-image-button"
                                                onClick={() =>
                                                  handleRemoveSubSectionImage(
                                                    `subSection_${section.id}`
                                                  )
                                                }
                                              >
                                                <i className="fa fa-times"></i>
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {image &&
                                  image[`subSection_${section.id}`] ? (
                                    <>
                                      <div className="col-12 mt-4 mb-4">
                                        <label className="form-label">
                                          {section.field_title}
                                        </label>{" "}
                                        <div
                                          className="uploaded-img-view overflow-hidden"
                                          style={{
                                            height: "200px",
                                            width: "200px",
                                            position: "relative",
                                          }}
                                        >
                                          <div>
                                            <a
                                              href={
                                                image[
                                                  `subSection_${section.id}`
                                                ]
                                              }
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              <img
                                                // alt="subsection_image"
                                                src={
                                                  image[
                                                    `subSection_${section.id}`
                                                  ]
                                                }
                                                style={{
                                                  width: "100%",
                                                  height: "100%",
                                                  objectFit: "cover",
                                                  backgroundColor: "darkgrey",
                                                }}
                                                // style={
                                                //   image[
                                                //     `subSection_${section.id}`
                                                //   ].endsWith(".png")||image[
                                                //     `subSection_${section.id}`
                                                //   ].endsWith(".svg")
                                                //     ? {
                                                //       height: "100%",
                                                //       objectFit: "cover",
                                                //       backgroundColor:"darkgrey"
                                                //       }
                                                //     : {
                                                //       height: "100%",
                                                //       objectFit: "cover",
                                                //       }
                                                // }
                                              />
                                            </a>
                                            <div
                                              style={{
                                                position: "absolute",
                                                top: "0",
                                                right: "0",
                                              }}
                                            >
                                              <button
                                                className="remove-image-button"
                                                onClick={() =>
                                                  handleRemoveSubSectionImage(
                                                    `subSection_${section.id}`
                                                  )
                                                }
                                              >
                                                <i className="fa fa-times"></i>
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}

                                  {!image[`subSection_${section.id}`] &&
                                  !sectionimageData[
                                    "subSection_" + section.id
                                  ] ? (
                                    // <div
                                    //   className="col-md-6 mb-4"
                                    //   key={section.id}
                                    // >
                                    //   <div className="facility-view">
                                    //     <label
                                    //       htmlFor="ChangeIcon"
                                    //       className="form-label"
                                    //     >
                                    //       {section.field_title}
                                    //     </label>
                                    //     <div className="input-group mb-3">
                                    //       <label
                                    //         className="input-group-text px-3 facility-icon position-relative"
                                    //         htmlFor="UploadFacilityIcon"
                                    //         onClick={() =>
                                    //           showCropMediaModal(
                                    //             `subSection_${section.id}`,
                                    //             section.image_dimension
                                    //           )
                                    //         }
                                    //       >
                                    //         <i className="fa-solid fa-camera"></i>
                                    //         <input
                                    //           type="file"
                                    //           name=""
                                    //           className="position-absolute"
                                    //           id="ChangeIcon"
                                    //           disabled
                                    //         />
                                    //       </label>
                                    //       <input
                                    //         className="form-control py-2 shadow-none px-3"
                                    //         placeholder="xyz.jpeg"
                                    //         name=""
                                    //         id=""
                                    //         disabled
                                    //       />
                                    //     </div>
                                    //   </div>
                                    // </div>
                                    <div
                                      className="col-md-12 mb-4 mt-4"
                                      key={section.id}
                                    >
                                      <label className="form-label">
                                        {section.field_title}
                                      </label>
                                      <label
                                        htmlFor="UploadCover"
                                        className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5"
                                        onClick={() =>
                                          showCropMediaModal(
                                            `subSection_${section.id}`,
                                            section.image_dimension
                                          )
                                        }
                                      >
                                        <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                                          <button
                                            type="button"
                                            className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                                          >
                                            Upload from gallery
                                          </button>
                                        </div>
                                      </label>

                                      {formErrors[section.id] && (
                                        <p style={{ color: "red" }}>
                                          {formErrors[section.id]}
                                        </p>
                                      )}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </>
                              );
                            case "FACEBOOK":
                              return (
                                <div className="col-md-12 mb-4">
                                  <div className="facility-view social-link">
                                    <label className="form-label">
                                      Facebook
                                    </label>
                                    <div className="input-group">
                                      <span
                                        className="input-group-text px-4"
                                        id="basic-addon1"
                                      >
                                        <i
                                          className="fa fa-facebook"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                      <input
                                        className="form-control py-2 shadow-none px-3"
                                        id={section.field_title}
                                        name={section.id}
                                        defaultValue={
                                          sectionDataArray[section.id] ||
                                          section.page_sub_section_detail_data
                                        }
                                        onChange={handleSubSectionChange}
                                        required
                                      />
                                    </div>
                                  </div>
                                </div>
                              );
                            case "INSTAGRAM":
                              return (
                                <div className="col-md-12 mb-4">
                                  <div className="facility-view  social-link">
                                    <label className="form-label">
                                      Instagram
                                    </label>
                                    <div className="input-group">
                                      <span
                                        className="input-group-text px-4"
                                        id="basic-addon1"
                                      >
                                        <i
                                          className="fa fa-instagram"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                      <input
                                        className="form-control py-2 shadow-none px-3"
                                        id={section.field_title}
                                        name={section.id}
                                        defaultValue={
                                          sectionDataArray[section.id] ||
                                          section.page_sub_section_detail_data
                                        }
                                        onChange={handleSubSectionChange}
                                        required
                                      />
                                    </div>
                                  </div>
                                </div>
                              );
                            case "LINKEDIN":
                              return (
                                <div className="col-md-12 mb-4">
                                  <div className="facility-view  social-link">
                                    <label className="form-label">
                                      Linkedin
                                    </label>
                                    <div className="input-group">
                                      <span
                                        className="input-group-text px-4"
                                        id="basic-addon1"
                                      >
                                        <i
                                          className="fa fa-linkedin"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                      <input
                                        className="form-control py-2 shadow-none px-3"
                                        id={section.field_title}
                                        name={section.id}
                                        defaultValue={
                                          sectionDataArray[section.id] ||
                                          section.page_sub_section_detail_data
                                        }
                                        onChange={handleSubSectionChange}
                                        required
                                      />
                                    </div>
                                  </div>
                                </div>
                              );
                            default:
                              return null; // or a default component for unknown types
                          }
                        })}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditSubSectionComponent;
