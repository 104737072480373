import { Link, useNavigate } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.core.css";
import "react-quill/dist/quill.bubble.css";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation, useQuery } from "react-query";
import * as querydata from "../dataService/DataService";
import Select from "react-select";
import backarrow from "../../assets/images/back-arrow.svg";

// crop image start
import Modal from "react-bootstrap/Modal";
import MediaFolderView from "../manageAlbum/MediaFolderViewSelection";
import ManageAlbumSelection from "../manageAlbum/ManageAlbumSelection";
import AlbumViewSelection from "../manageAlbum/AlbumViewSelection";
// crop image end
import { HiPlus } from "react-icons/hi";
import ImageTagModal from "../../components/ImageTagModal";
import { getMandatoryFields, getExtraFields } from "../../mandatoryfileds";
import { useContext } from "react";
import { GlobalContext } from "../../globalContext";
import { Button } from "react-bootstrap";

// let bwebsiteId="";
// if (window.location.origin=="http://localhost:3001")
//     bwebsiteId = "TOC";
// else if (window.location.origin=="http://localhost:3000")
//     bwebsiteId = "AHR";

const CreatePressRelease = () => {
  const { bwebsiteId } = useContext(GlobalContext);
  const moduleName = "pressrelease";

  const mandatoryFields = getMandatoryFields(bwebsiteId, moduleName);
  const extraFields = getExtraFields(bwebsiteId, moduleName);

  const pressReleaseSchema = Yup.object({
    title: Yup.string().trim().required("Please enter title "),

    // pressReleaseDate: Yup.string(),//condition
    pressReleaseDate: Yup.string()
      .trim()
      .test(
        "is-pressReleaseDate-required",
        "Please select press release date",
        function (value) {
          return !mandatoryFields.includes("pressReleaseDate") || !!value; // If discountText is required, ensure it's not empty
        }
      ),
    // pressReleaseTime: Yup.string(),//condition
    pressReleaseTime: Yup.string()
      .trim()
      .test(
        "is-pressReleaseTime-required",
        "Please select press release time",
        function (value) {
          return !mandatoryFields.includes("pressReleaseTime") || !!value; // If discountText is required, ensure it's not empty
        }
      ),
    description: Yup.string().trim().required("Please enter description"),
    slugName: Yup.string()
      .matches(
        /^[a-zA-Z0-9_-]*$/,
        "Only alphanumeric characters, hyphens, and underscores are allowed"
      )
      .required("Please enter slug"),
    metaTitle: Yup.string().trim(),
    keyWords: Yup.string().trim(),
    metaDescription: Yup.string().trim(),
    // resortId: Yup.array().of(Yup.number()).required("Please Select Resort"),
  });
  const navigate = useNavigate();
  const [offerfile, setofferfile] = useState("");
  const [image, setImage] = useState(null);
  const [error, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);

  // crop code start
  const [folderId, setFolderId] = useState("");
  const [albumId, setAlbumId] = useState("");
  const [websiteId, setwebsiteId] = useState("");
  const [maincroppedImage, setmainCroppedImage] = useState(null);
  const [maincroppedFile, setmainCroppedFile] = useState(null);
  const [err, setErr] = useState(false);
  const closeCropMediaModal = () => setCropMediaModal(false);
  const showCropMediaModal = () => {
    setCropMediaModal(true);
  };
  const [cropMediaModal, setCropMediaModal] = useState(false);
  const getAspectRatio = useQuery(
    ["AspectRatio", "press_release_aspect_ratio"], // Using an array as the query key with offerId
    () => querydata.getAspectRatio("press_release_aspect_ratio")
  );
  // crop code end

  const [showModalImageTagModal, setImageTagModal] = useState(false);
  const handleShowImageTagModal = () => {
    setImageTagModal(true);
  };

  const handleCloseImageTagModal = () => {
    setImageTagModal(false);
  };

  const initialValues = {
    slugName: "",
    title: "",
    pressReleaseDate: "",
    pressReleaseTime: "",
    description: "",
    metaTitle: "",
    keyWords: "",
    metaDescription: "",
    tags: "",

    is_published: false,
    resortId: selectedOptions,
    publish_date: "",
    publish_time: "",
    imageAltTag: "",
  };

  const titleRef = useRef(null);
  const pressReleaseDateRef = useRef("");
  const pressReleaseTimeRef = useRef("");
  const descriptionRef = useRef(null);
  const imageRef = useRef(null);
  const slugRef = useRef(null);
  const getAllResorts = useQuery("Resorts", querydata.getAllResorts);
  const handleTitleChange = (event) => {
    const value = event.target.value;

    setFieldValue("title", value);
    setFieldValue("slugName", value.toLowerCase().replace(/\s+/g, "-"));
  };
  const handleKeyPress = (event) => {
    const enteredChar = event.key;

    // Prevent typing characters other than lowercase letters (a-z) and hyphens (-)
    if (!/^[a-z0-9\-]$/i.test(enteredChar)) {
      event.preventDefault();
    }
  };
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const [actions, setAction] = useState();
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: pressReleaseSchema,
    onSubmit: (values, action) => {
      const formData = new FormData();
      formData.append("title", values.title);
      formData.append("date", values.pressReleaseDate);
      formData.append("time", values.pressReleaseTime);
      formData.append("description", values.description);
      formData.append("tags", values.tags);
      formData.append("slugName", values.slugName);
      formData.append("metaTitle", values.metaTitle);
      formData.append("keyWords", values.keyWords);
      formData.append("metaDescription", values.metaDescription);
      formData.append("is_published", values.is_published);
      formData.append("brand_resort_id", values.resortId);
      formData.append("publish_date", values.publish_date);
      formData.append("publish_time", values.publish_time);
      formData.append("imageAltTag", values.imageAltTag);

      if (!err) {
        if (maincroppedFile) {
          formData.append("pressImage", maincroppedFile);
        }
      }

      if (err) {
        if (imageRef.current) {
          imageRef.current.focus();
        }
        toast.dismiss();
        toast.error("Please upload an image");
        return;
      }
      if (!err) {
        if (actions === "PREVIEW") {
          preview.mutate(formData);
        }
        if (actions === "SAVE") {
          addMutation.mutate(formData);
        }
      }
    },
    // validateOnBlur: true,
    // validateOnChange: true,
  });

  // useEffect(() => {
  //   if (Object.values(errors).some((error) => error !== undefined)) {
  //     const firstEmptyField = Object.entries(errors).find(
  //       ([, error]) => error !== undefined && !touched[error.path]
  //     );

  //     if (firstEmptyField) {
  //       const [name] = firstEmptyField;
  //       switch (name) {
  //         case "title":
  //           titleRef.current.focus();
  //           break;
  //         case "pressReleaseDate":
  //           pressReleaseDateRef.current.focus();
  //           break;
  //         case "pressReleaseTime":
  //           pressReleaseTimeRef.current.focus();
  //           break;
  //         case "description":
  //           descriptionRef.current.focus();
  //           break;
  //         case "slugName":
  //           slugRef.current.focus();
  //           break;
  //         default:
  //           break;
  //       }
  //     }
  //   }
  // }, [errors, touched]);



  const handelFoucus=()=>{
 
    if (Object.values(errors).some((error) => error !== undefined)) {
      const firstEmptyField = Object.entries(errors).find(
        ([, error]) => error !== undefined && !touched[error.path]
      );

      if (firstEmptyField) {
        const [name] = firstEmptyField;
        switch (name) {
          case "title":
            titleRef.current.focus();
            break;
          case "pressReleaseDate":
            pressReleaseDateRef.current.focus();
            break;
          case "pressReleaseTime":
            pressReleaseTimeRef.current.focus();
            break;
          case "description":
            descriptionRef.current.focus();
            break;
          case "slugName":
            slugRef.current.focus();
            break;
          default:
            break;
        }
      }
    }
  
      handleSubmit()
    
  }

  //form post
  const addMutation = useMutation(querydata.insertPressRelease, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess: (data) => {
      setIsLoading(false);
      toast.dismiss();
      toast.success(data.message);
      navigate("/manage-press-releases");
    },
    onSettled: () => {
      setIsLoading(false); // Set loading to false on success or error
    },
    onMutate: () => {
      setIsLoading(true); // Set loading to true before making the request
    },
  });
  const preview = useMutation(querydata.previewPressRelease, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess: (data) => {
      const websiteUrl = `${data.previewURL}?ispreview=true&uid=${data.encryptedUserId}`;
      window.open(websiteUrl, "_blank");
    },
    onSettled: () => {
      setIsPreviewLoading(false); // Set loading to false on success or error
    },
    onMutate: () => {
      setIsPreviewLoading(true); // Set loading to true before making the request
    },
  });
  useEffect(() => {
    if (maincroppedFile) {
      setErr(false);
    }
    if (maincroppedImage) {
      setErr(false);
    } else {
      setErr(true);
    }
  }, [maincroppedFile]);

  const handleRemoveImage = () => {
    // Handle removing the image
    setErr(true);
    setImage(null);
    setmainCroppedImage(null);
  };

  ///editor start
  const fullEditorRef = useRef(null);
  useEffect(() => {
    // Assuming fullEditorRef.current is the ReactQuill instance
    const quill = fullEditorRef.current?.getEditor();

    if (quill) {
      // Listen for text-change event in the Quill editor
      quill.on("text-change", () => {
        const descriptionValue = quill.root.innerHTML; // Get the HTML content of the editor
        setFieldValue("description", descriptionValue); // Update the Formik state
      });
    }
  }, [fullEditorRef.current]);

  const handleChangeResort = (selected) => {
    setSelectedOptions(selected);
    const resortIdValue =
      selected.length > 0 ? selected.map((option) => option.value) : null;

    // Update state or form values
    setValues({
      ...values,
      resortId: resortIdValue,
    });
  };

  const [showImgTagModal, setShowImgAltTagModal] = useState(false);
  const handleToggleImgAltModal = () => {
    setShowImgAltTagModal((prevState) => !prevState);
  };
  const handleSaveImageAltTag = (tagType, value) => {
    // Save the image alt tag value to state
    setFieldValue("imageAltTag", value);
    setShowImgAltTagModal(false);
    // You can also perform any additional actions here, such as making API calls to save the data to a server
  };
  const handleCloseImgTagModal = () => {
    setShowImgAltTagModal(false);
  };
  return (
    <>
      <div className="page-body">
        <div className="container-fluid dashboard-default">
          <div className="page-heading d-flex flex-wrap align-items-center justify-content-between">
            <nav className="w-100" aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link
                    className="text-decoration-none"
                    to="/manage-press-releases"
                  >
                    manage-press-releases
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Create Press Release
                </li>
              </ol>
            </nav>
            <div className="left-header d-flex align-items-center">
              <Link
                to="/manage-press-releases"
                className="back-arrow d-inline-block me-2"
              >
                <img src={backarrow} alt="" />
              </Link>
              <h4 className="fw-normal m-0 fs-6">Press Release</h4>
            </div>
          </div>
          <div className="row mt-md-4 mt-3">
            <div className="col-xl-12 col-lg-7">
              <div className="card mb-4">
                <div className="card-header bg-transparent border-bottom py-2 d-flex align-items-center justify-content-between">
                  <h5 className="m-0 fw-medium fs-6">Create Press Release</h5>
                  <div className="right-actions d-none">
                    <a
                      href="#"
                      className="btn btn-primary btn-theme outline-btn fw-semibold text-uppercase px-2 py-1 me-2 btn-sm"
                    >
                      Save Draft
                    </a>
                    <a
                      href="#"
                      className="btn btn-primary btn-theme fw-semibold text-uppercase px-2 py-1"
                    >
                      Preview
                    </a>
                  </div>
                </div>
                <div className="card-body">
                  <div className="upload-file-wrapper">
                    <form action="#" method="get">
                      <div className="row">
                        <div className="col-md-12 mb-4">
                          <label className="form-label" htmlFor="Name">
                            {" "}
                            Title <span className="mandatory">*</span>
                          </label>

                          <input
                            name="title"
                            value={values.title}
                            ref={titleRef}
                            onChange={handleTitleChange}
                            maxLength={100}
                            onBlur={handleBlur}
                            id="Name"
                            className="form-control py-2 shadow-none px-3"
                          />
                          {errors.title && touched.title ? (
                            <p className="form-error text-danger">
                              {errors.title}
                            </p>
                          ) : error.title ? (
                            <p className="form-error text-danger">
                              {error.title}
                            </p>
                          ) : null}
                        </div>
                        <div className="col-12">
                          <div className="mb-4">
                            <div className="d-flex align-items-center">
                              <label className="form-label mb-0">
                                Image <span className="mandatory">*</span>{" "}
                              </label>
                              <a
                                title="Add image alt tag"
                                className="btn image-tag"
                                onClick={() =>
                                  handleToggleImgAltModal("IMAGEALTTAG")
                                }
                              >
                                <HiPlus />
                              </a>
                            </div>
                            {!maincroppedImage && !image && (
                              <label
                                htmlFor="UploadCover"
                                className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5"
                                onClick={showCropMediaModal}
                                ref={imageRef}
                                tabIndex={-1}
                              >
                                <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                                  <button
                                    type="button"
                                    className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                                  >
                                    Upload image from gallery
                                  </button>
                                </div>
                              </label>
                            )}
                            {maincroppedImage && (
                              <div
                                className="uploaded-img-view"
                                style={{ width: "200px", position: "relative" }}
                              >
                                <div>
                                  <a href={maincroppedImage} target="_blank">
                                    <img
                                      src={maincroppedImage}
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                      }}
                                    />
                                  </a>
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "0",
                                      right: "0",
                                    }}
                                  >
                                    <button
                                      className="remove-image-button"
                                      onClick={handleRemoveImage}
                                    >
                                      <i className="fa fa-times"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}

                            {err ? (
                              <p className="form-error text-danger">
                                {/* Please select a file{" "} */}
                              </p>
                            ) : (
                              ""
                            )}
                            {/* {!maincroppedImage && image && (
                                                            <div
                                                                className="uploaded-img-view"
                                                                style={{
                                                                    height: "200px",
                                                                    width: "200px",
                                                                    position: "relative",
                                                                    overflow: "hidden",
                                                                }}
                                                            >
                                                                <div>
                                                                    <a href={image} target="_blank">
                                                                        <img
                                                                            src={image}
                                                                            style={{
                                                                                width: "100%",
                                                                                height: "100%",
                                                                                objectFit: "cover",
                                                                            }}
                                                                        />
                                                                    </a>
                                                                    <div
                                                                        style={{
                                                                            position: "absolute",
                                                                            top: "0",
                                                                            right: "0",
                                                                        }}
                                                                    >
                                                                        <button
                                                                            className="remove-image-button"
                                                                            onClick={handleRemoveImage}
                                                                        >
                                                                            <i className="fa fa-times"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )} */}
                            {/* {errors.offerfile && touched.offerfile ? (
                                                            <p className="form-error text-danger">
                                                                {errors.offerfile}
                                                            </p>
                                                        ) : error.file ? (
                                                            <p className="form-error text-danger">
                                                                {error.file}
                                                            </p>
                                                        ) : !errors.offerfile &&
                                                            error.file &&
                                                            touched.offerfile ? (
                                                            <p className="form-error text-danger">
                                                                Please select a file
                                                            </p>
                                                        ) : null} */}
                          </div>
                        </div>
                        <div className="col-xl-4 mb-4">
                          <label
                            className="form-label"
                            htmlFor="pressReleaseDate"
                          >
                            Press Release Date{" "}
                            {mandatoryFields.includes("pressReleaseDate") && (
                              <span className="mandatory">*</span>
                            )}{" "}
                          </label>
                          <input
                            ref={pressReleaseDateRef}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            selected={values.pressReleaseDate}
                            type="date"
                            name="pressReleaseDate"
                            id="pressReleaseDate"
                            className="form-control py-2 shadow-none px-3"
                          />
                          {errors.pressReleaseDate &&
                          touched.pressReleaseDate ? (
                            <p className="form-error text-danger">
                              {errors.pressReleaseDate}
                            </p>
                          ) : error.pressReleaseDate ? (
                            <p className="form-error text-danger">
                              {error.pressReleaseDate}
                            </p>
                          ) : null}
                        </div>
                        <div className="col-xl-4 mb-4">
                          <label
                            className="form-label"
                            htmlFor="pressReleaseTime"
                          >
                            Press Release Time{" "}
                            {mandatoryFields.includes("pressReleaseTime") && (
                              <span className="mandatory">*</span>
                            )}
                          </label>
                          <input
                            onChange={handleChange}
                            ref={pressReleaseTimeRef}
                            onBlur={handleBlur}
                            type="time"
                            name="pressReleaseTime"
                            selected={values.pressReleaseTime}
                            id="pressReleaseTime"
                            className="form-control py-2 shadow-none px-3"
                          />
                          {errors.pressReleaseTime &&
                          touched.pressReleaseTime ? (
                            <p className="form-error text-danger">
                              {errors.pressReleaseTime}
                            </p>
                          ) : error.pressReleaseTime ? (
                            <p className="form-error text-danger">
                              {error.pressReleaseTime}
                            </p>
                          ) : null}
                        </div>
                        <div className="col-xl-4">
                          <div className="mb-4 publish-resort-select-box">
                            <label className="form-label" htmlFor="OfferType">
                              Publish on resort website
                            </label>
                            <Select
                              name="resortId"
                              id="OfferType"
                              className="3col active shadow-none "
                              onChange={handleChangeResort}
                              // value={values.resortId}
                              value={selectedOptions}
                              multiple="multiple"
                              isMulti
                              options={(getAllResorts?.data?.data || []).map(
                                (option) => ({
                                  value: option.brand_resort_id,
                                  label: option.resort_name,
                                })
                              )}
                            ></Select>
                          </div>
                        </div>
                        <div className="col-12 mb-4">
                          <label className="form-label" htmlFor="Description">
                            {" "}
                            Description <span className="mandatory">*</span>
                          </label>

                          <ReactQuill
                            ref={(node) => {
                              fullEditorRef.current = node;
                              descriptionRef.current = node;
                            }}
                            theme="snow"
                            modules={{
                              toolbar: true,
                            }}
                            style={{ height: "300px" }}
                          />
                          {errors.description && touched.description ? (
                            <p className="form-error text-danger">
                              {errors.description}
                            </p>
                          ) : error.description ? (
                            <p className="form-error text-danger">
                              {error.description}
                            </p>
                          ) : null}
                        </div>
                        <div className="col-md-12 mb-4">
                          <label className="form-label" htmlFor="Name">
                            Tags
                          </label>
                          <div className="bs-example">
                            <input
                              onChange={handleChange}
                              onBlur={handleBlur}
                              maxLength={100}
                              type="text"
                              name="tags"
                              value={values.tags}
                              id="tags"
                              className="form-control py-2 shadow-none px-3"
                              placeholder="Enter tags"
                              data-role="tagsinput"
                            />
                          </div>
                        </div>
                        {/* <div className="col-md-12 mb-4">
                                                    <label className="form-label" htmlFor="Name">Slug</label>
                                                    <div className="bs-example">
                                                        <input onChange={handleChange}
                                                            onBlur={handleBlur} type="text" maxLength={100} name="slug" value={values.slug} id="slug" className="form-control py-2 shadow-none px-3" placeholder="Enter slug" data-role="tagsinput" />
                                                    </div>

                                                </div> */}
                        <div className="col-md-12 mb-4">
                          <label className="form-label" htmlFor="Name">
                            Slug
                            <span className="mandatory">*</span>
                          </label>

                          <div className="bs-example">
                            <input
                              onChange={handleChange}
                              ref={slugRef}
                              onBlur={handleBlur}
                              type="text"
                              maxLength={100}
                              name="slugName"
                              value={values?.slugName?.trim()}
                              id="slugName"
                              className="form-control py-2 shadow-none px-3"
                              placeholder="Enter slug"
                              data-role="tagsinput"
                              onKeyPress={handleKeyPress}
                            />
                          </div>
                          {errors.slugName && touched.slugName ? (
                            <p className="form-error text-danger">
                              {errors.slugName}
                            </p>
                          ) : error.slugName ? (
                            <p className="form-error text-danger">
                              {error.slugName}
                            </p>
                          ) : null}
                        </div>
                        <div className="col-md-12 mb-4">
                          <label className="form-label">Keywords </label>
                          <input
                            name="keyWords"
                            value={values.keyWords}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="text"
                            id="Keywords"
                            className="form-control py-2 shadow-none px-3"
                          />
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-4">
                            <label
                              className="form-label"
                              // htmlFor="OfferName"
                            >
                              Meta Title{" "}
                            </label>
                            <input
                              name="metaTitle"
                              value={values.metaTitle}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="text"
                              id="metaTitle"
                              className="form-control py-2 shadow-none px-3"
                              maxLength={70}
                            />
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="mb-4">
                            <label
                              className="form-label"
                              // htmlFor="OfferName"
                            >
                              Meta Description{" "}
                            </label>
                            <input
                              name="metaDescription"
                              value={values.metaDescription}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="text"
                              id="metaDescription"
                              className="form-control py-2 shadow-none px-3"
                              maxLength={170}
                            />
                          </div>
                        </div>
                        <div className="bottom-actions mt-4 text-end">
                          {bwebsiteId === "TOC" && (
                            <Button
                              // href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                setAction("PREVIEW");
                                // handleSubmit();
                                handelFoucus();
                              }}
                              className="btn btn-primary btn-theme outline-btn fw-semibold text-uppercase px-2 py-1 me-2 btn-sm btn btn-primary"
                              disabled={isPreviewLoading}
                            >
                              {isPreviewLoading ? (
                                <div
                                  class="spinner-border text-primary"
                                  role="status"
                                ></div>
                              ) : (
                                "PREVIEW"
                              )}
                            </Button>
                          )}
                          {isLoading ? (
                            <div
                              class="spinner-border text-primary"
                              role="status"
                            ></div>
                          ) : (

                            <Button
                            onClick={(e) => {
                              e.preventDefault();
                              setAction("SAVE");
                              // handleSubmit();
                              handelFoucus()
                            }}
                            type="submit"
                            disabled={isLoading}
                            className="btn btn-primary btn-theme fw-semibold text-uppercase px-2 py-1">
                              Save

                            </Button>
                           
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-xl-3 col-lg-5 mt-lg-0">
                            <div className="card">
                                <div className="card-header py-3 bg-transparent border-bottom">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h5 className="m-0 fw-medium pe-3">Publish</h5>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="press-top-action d-flex justify-content-between">

                                    </div>
                                    <div className="press-body">

                                        <ul className="list-unstyled">

                                            <li className="flex-wrap">

                                                <span>Publish Date</span>
                                                <div className="press-actions d-flex">

                                                    <span className="press-date-wrap d-flex">
                                                        <input
                                                            name="publish_date"
                                                            type="date"
                                                            className="press-date-input border-0 p-0 shadow-none outline-0"
                                                            onChange={handleChange}
                                                            value={values.publish_date}
                                                            onBlur={handleBlur}
                                                            selected={values.publish_date}
                                                        />
                                                        <div className="edit-action position-absolute end-0 top-0 d-none z-2 bg-white">
                                                        </div>


                                                    </span>
                                                </div>
                                                {errors.publish_date && touched.publish_date ? (
                                                    <p className="form-error text-danger">
                                                        {errors.publish_date}
                                                    </p>
                                                ) : error.publish_date ? (
                                                    <p className="form-error text-danger">
                                                        {error.publish_date}
                                                    </p>
                                                ) : null}
                                            </li>
                                            <li className="flex-wrap">
                                                <span>Publish Time</span>
                                                <div className="press-actions d-flex">

                                                    <span>
                                                        <input
                                                            className="border-0"
                                                            type="time"
                                                            name="publish_time"
                                                            value={values.publish_time}
                                                            onBlur={handleBlur}
                                                            selected={values.publish_time}
                                                            onChange={handleChange}
                                                        />
                                                    </span>
                                                </div>
                                                {errors.publish_time && touched.publish_time ? (
                                                    <p className="form-error text-danger">
                                                        {errors.publish_time}
                                                    </p>
                                                ) : error.publish_time ? (
                                                    <p className="form-error text-danger">
                                                        {error.publish_time}
                                                    </p>
                                                ) : null}
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div> */}
          </div>
        </div>
      </div>
      <Modal size="xl" show={cropMediaModal} onHide={closeCropMediaModal}>
        <Modal.Header className="modal-header py-2" closeButton>
          <Modal.Title className="modal-title fs-5">
            {"Select Image"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className=" overflow-x-auto overflow-y-auto">
          {!folderId && <MediaFolderView setFolderId={setFolderId} />}
          {folderId && !albumId && !websiteId && (
            <ManageAlbumSelection
              folderId={folderId}
              setAlbumId={setAlbumId}
              setwebsiteId={setwebsiteId}
              setFolderId={setFolderId}
            />
          )}
          {folderId && albumId && websiteId && (
            <AlbumViewSelection
              albumId={albumId}
              websiteId={websiteId}
              setAlbumId={setAlbumId}
              setwebsiteId={setwebsiteId}
              setFolderId={setFolderId}
              setCropMediaModal1={setCropMediaModal}
              setmainCroppedImagePress={setmainCroppedImage}
              setmainCroppedFilePress={setmainCroppedFile}
              setting_value={
                getAspectRatio &&
                getAspectRatio.data &&
                getAspectRatio.data.data &&
                getAspectRatio.data.data.setting_value
              }
            />
          )}
        </Modal.Body>
      </Modal>

      <ImageTagModal
        showModalImageTagModal={showImgTagModal}
        handleCloseImageTagModal={handleCloseImgTagModal}
        initialImageAltTag={values.imageAltTag}
        tagType="IMAGEALTTAG"
        handleSaveImageAltTag={handleSaveImageAltTag}
        inputName="imageAltTag"
      />
    </>
  );
};

export default CreatePressRelease;
