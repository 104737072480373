import React, { useEffect, useState,useContext } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import * as querydata from "../dataService/DataService";
import { Dropdown, Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import MediaFolderView from "../manageAlbum/MediaFolderViewSelection";
import ManageAlbumSelection from "../manageAlbum/ManageAlbumSelection";
import AlbumViewSelection from "../manageAlbum/AlbumViewSelectionDynamic";
import axios from "axios";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import DeleteModal from "./DeleteModal";
import videoImg from "../../assets/images/video.png";
import pdfImg from "../../assets/images/pdf-doc.svg";
import EditSubSectionComponent from "./EditSubSectionComponent";
import backarrow from "../../assets/images/back-arrow.svg";
import EditSubSectionModal from "./EditSubSectionModal";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Tabs from "../../components/dragTabs/Tabs";
import { HiPlus } from "react-icons/hi";
import ImageTagModal from "../../components/ImageTagModalPages";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DropDownForEditPage from './DropDownForEditPage';
import { GlobalContext } from "../../globalContext";  
export const subSectionSchema = Yup.object({
  title: Yup.string().trim().required("Please enter title"),
});

function EditPage() {
  const { bwebsiteId } = useContext(GlobalContext);
  const apikey = bwebsiteId=="AHR"?process.env.REACT_APP_APIKEY:process.env.REACT_APP_APIKEY_TOC;

  const navigate = useNavigate()
  // const location = useLocation()
  // const state = location.state;

  //for Disable save button
  // if (!state) {
  //   navigate("/manage-pages")

  // }

  //for Disable save button
  const [isSaveButtonActive, setIsSaveButtonActive] = useState(false);
  const { pageId, pageSectionId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const pageSectionDetails = useQuery(
    ["getPageSectionDetails", +pageSectionId],
    () => querydata.getPageSectionDetails(pageSectionId)
  );

  const pageSubSectionDetails = useQuery(
    ["getPageSubSectionDetails", +pageSectionId],
    () => querydata.getPageSubSectionDetails(pageSectionId)
  );

  const queryClient = useQueryClient();
  const [folderId, setFolderId] = useState("");
  const [currentFileId, setCurrentFileId] = useState("");
  const [albumId, setAlbumId] = useState("");
  const [websiteId, setwebsiteId] = useState("");
  const [mainVideoFile, setmainVideoFile] = useState({});
  const [mainPdfFile, setmainPdfFile] = useState({});
  const [selectedRowData, setSelectedRowData] = useState({});
  const [selectedRowNameData, setSelectedRowNameData] = useState({});
  const [imageDimension, setImageDimension] = useState({});
  const [mTitle, setMTitle] = useState("Add New");
  const [altTagData, setAltTagData] = useState([]);
  const [open, setOpen] = useState(false);

  const [altTagId, setAltTagId] = useState(null);
  const [selectedRowImageAltTagData, setSelectedImageAltTagData] = useState("");

  const handleShowModal = (rowData) => {
    setSelectedRowData(rowData.id);
    setSelectedRowNameData(rowData.sub_section_title);
    setShowModal(true);
  };

  const initialValuesOfImgAltTag = [
    {
      id: altTagId,
      data:
        altTagData.find((item) => item.id === altTagId)?.data ||
        selectedRowImageAltTagData,
    },
  ];

  const [showModal, setShowModal] = useState(false);
  const [showEditSubSectionModal, setShowEditSubSectionModal] = useState(false);
  const handleShowEditSubSectionModal = (rowData) => {
    setSelectedRowData(rowData.page_sub_section_id);
    setSelectedRowNameData(rowData.sub_section_title);
    setShowEditSubSectionModal(true);
  };

  const [showModalImageTagModal, setImageTagModal] = useState(false);

  const handleShowImageTagModal = (sectionId, imgAltTagData) => {
    setAltTagId(sectionId);
    setSelectedImageAltTagData(imgAltTagData || "");
    setImageTagModal(true);
  };

  const handleCloseImageTagModal = () => {
    setImageTagModal(false);
  };

  const handleCloseEditSubSectionModal = () => {
    setShowEditSubSectionModal(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const closeCropMediaModal = () => setCropMediaModal(false);
  const showCropMediaModal = (id, dimension) => {
    setCropMediaModal(true);
    setCurrentFileId(id);
    setImageDimension(dimension);
  };

  const handleSubmitSubSection = (id) => {
    const modalId = pageSectionId;
    const titleMap = {
      4: "Add New Hotels & Resorts",
      5: "Add New Glimpses",
      17: "Add New Plan",
      40: "Add New Tab",
    };
    if (modalId in titleMap) {
      setMTitle(titleMap[modalId]);
    }
    setaddSubsectionModal(true);
    setFieldValue("copyDataId", id);
  };

  const handleCloseSubmitSubSection = (id) => {
    setaddSubsectionModal(false);
  };

  const initialValues = {
    pageSectionId: pageSectionId,
    title: "",
    copyDataId: "",
  };

  async function fetchPreview() {
    const websiteUrl = "http://localhost:3000/";
    convertWebUrlToImage(websiteUrl);
  }

  function convertWebUrlToImage(url) {
    // Open the URL in a new window
    const websiteUrl = "http://localhost:3000/";
    const newWindow = window.open(url, "_blank");
  }

  // handlenaviagte
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: subSectionSchema,
    onSubmit: (values, action) => {
      addMutation.mutate(values);
      action.resetForm();
    },
  });

  //form post
  const addMutation = useMutation(querydata.insertpageSubSection, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
      setaddSubsectionModal(false);
    },
    onSuccess: (data) => {
      setIsSaveButtonActive(false);
      setIsLoading(false);
      queryClient.invalidateQueries("getPageSectionDetails");
      queryClient.invalidateQueries("getPageSubSectionDetails");
      toast.dismiss();
      toast.success(data.message);
      setaddSubsectionModal(false);
    },
    onSettled: () => {
      setIsLoading(false); // Set loading to false on success or error
    },
    onMutate: () => {
      setIsLoading(true); // Set loading to true before making the request
    },
  });

  const [cropMediaModal, setCropMediaModal] = useState(false);
  const [addSubsectionModal, setaddSubsectionModal] = useState(false);
  const [closeSubSectionModal, setcloseSubSectionModal] = useState(false);

  const getAspectRatio = useQuery(
    ["AspectRatio", "offer_aspect_ratio"], // Using an array as the query key with offerId
    () => querydata.getAspectRatio("offer_aspect_ratio")
  );
  const handleRemoveImage = (sectionId) => {
    setImage((prevData) => {
      const updatedData = { ...prevData };
      delete updatedData[sectionId];
      return updatedData;
    });

    setImageFiles((prevData) => {
      const updatedData = { ...prevData };
      delete updatedData[sectionId];
      return updatedData;
    });

    setsectionimageData((prevData) => {
      const updatedData = { ...prevData };
      delete updatedData[sectionId];
      return updatedData;
    });

    setmainVideoFile((prevData) => {
      const updatedData = { ...prevData };
      delete updatedData[sectionId];
      return updatedData;
    });

    setmainPdfFile((prevData) => {
      const updatedData = { ...prevData };
      delete updatedData[sectionId];
      return updatedData;
    });

    const newTextName = sectionId.replace(/^section_/, "");
    setSectionDataArray((prevData) => ({
      ...prevData,
      [newTextName]: "",
    }));
  };

  const handleRemoveSubSectionImage = (sectionId) => {
    setImage((prevData) => {
      const updatedData = { ...prevData };
      delete updatedData[sectionId];
      return updatedData;
    });

    setImageFiles((prevData) => {
      const updatedData = { ...prevData };
      delete updatedData[sectionId];
      return updatedData;
    });

    setsectionimageData((prevData) => {
      const updatedData = { ...prevData };
      delete updatedData[sectionId];
      return updatedData;
    });
    setmainVideoFile((prevData) => {
      const updatedData = { ...prevData };
      delete updatedData[sectionId];
      return updatedData;
    });

    setmainPdfFile((prevData) => {
      const updatedData = { ...prevData };
      delete updatedData[sectionId];
      return updatedData;
    });
    const newTextName = sectionId.replace(/^subSection_/, "");
    setSubSectionDataArray((prevData) => ({
      ...prevData,
      [newTextName]: "",
    }));
  };

  const [sectionDataArray, setSectionDataArray] = useState({});
  const [subSectionDataArray, setSubSectionDataArray] = useState({});
  const [imageFiles, setImageFiles] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [sectionimageData, setsectionimageData] = useState({});
  const [image, setImage] = useState({});

  const handleChangeSection = (e, section) => {
    const { name, value } = e.target;
    const enteredText = e.target.value;
    const maxLength = section?.field_max_length || null;

    if (maxLength && enteredText.length + 1 > maxLength) {
      toast.error(`Character limit reached (${maxLength} characters)`);
    }

    if (value.trim() === "") {
      // If empty, update the state with an empty string
      setSectionDataArray((prevData) => ({
        ...prevData,
        [name]: "",
      }));
    } else {
      setSectionDataArray((prevData) => ({
        ...prevData,
        [name]: value.trim(),
      }));
    }
  };
  const handleSubSectionChange = (e, section) => {
    const { name, value } = e.target;
    const enteredText = e.target.value;
    const maxLength = section?.field_max_length || null;

    if (maxLength && enteredText.length + 1 > maxLength) {
      toast.error(`Character limit reached (${maxLength} characters)`);
    }
    setSubSectionDataArray((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const errors = {};
    pageSectionDetails &&
      pageSectionDetails.data &&
      pageSectionDetails.data.data &&
      pageSectionDetails.data.data.forEach((field) => {
        if (!sectionDataArray[field.name]) {
          errors[field.name] = `${field.label} is required.`;
        }
      });
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const getCookie = (name) => {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Check if this cookie is the one we are looking for
      if (cookie.startsWith(name + "=")) {
        // Return the value of the cookie
        return cookie.substring(name.length + 1);
      }
    }
    // Return null if the cookie is not found
    return null;
  };
  const websiteIdFromCookie = getCookie("websiteId");
 
 
  const handlePreviewSubmitSection = async (e) => {
    //setIsLoading(true);
    const formData = new FormData();
    const sectionArray = Object.entries(sectionDataArray).map(
      ([key, value]) => ({
        id: key,
        data: value,
      })
    );

    const subSectionArray = Object.entries(subSectionDataArray).map(
      ([key, value]) => ({
        id: key,
        data: value,
      })
    );

    const vediosArray = Object.entries(mainVideoFile).map(([key, value]) => ({
      id: key,
      data: value,
    }));

    formData.append("pageSectionId", JSON.stringify(pageSectionId));
    formData.append("sectionData", JSON.stringify(sectionArray));
    formData.append("subSectionData", JSON.stringify(subSectionArray));
    formData.append("vediosArrayData", JSON.stringify(vediosArray));
    formData.append("imageAltTag", JSON.stringify(altTagData));
    // formData.append("page", state?.page);
    // formData.append("section", state?.section);
    formData.append("websiteId", websiteIdFromCookie);

    for (const dynamicName in imageFiles) {
      formData.append(dynamicName, imageFiles[dynamicName]);
    }
    for (const dynamicName in mainVideoFile) {
      formData.append(dynamicName, imageFiles[dynamicName]);
    }
    try {
      const response = await axios.post(
        `${apikey}/page/insert-preview-subsection-data`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
           
          },
          withCredentials: true,
        }
      );
     
      const websiteUrl = `${response.data.previewURL}?ispreview=true&uid=${response.data.encryptedUserId}&sid=${pageSectionId}`;
      // const websiteUrl = `http://localhost:5173?ispreview=true&uid=${response.data.encryptedUserId}&sid=${pageSectionId}`;
      // navigate(websiteUrl)
      const newWindow = window.open(websiteUrl, '_blank');
      // setImage({});
      // setmainVideoFile({});
      // queryClient.invalidateQueries("getPageSectionDetails");
      // queryClient.invalidateQueries("getPageSubSectionDetails");
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
    if (validateForm()) {
    }
  };

  const handleSubmitSection = async (e) => {
    setIsLoading(true);
    const formData = new FormData();
    const sectionArray = Object.entries(sectionDataArray).map(
      ([key, value]) => ({
        id: key,
        data: value,
      })
    );

    const subSectionArray = Object.entries(subSectionDataArray).map(
      ([key, value]) => ({
        id: key,
        data: value,
      })
    );

    const vediosArray = Object.entries(mainVideoFile).map(([key, value]) => ({
      id: key,
      data: value,
    }));

    const pdfArray = Object.entries(mainPdfFile).map(([key, value]) => ({
      id: key,
      data: value,
    }));

    formData.append("sectionData", JSON.stringify(sectionArray));
    formData.append("subSectionData", JSON.stringify(subSectionArray));
    formData.append("vediosArrayData", JSON.stringify(vediosArray));
    formData.append("imageAltTag", JSON.stringify(altTagData));

    for (const dynamicName in imageFiles) {
      formData.append(dynamicName, imageFiles[dynamicName]);
    }
    for (const dynamicName in mainVideoFile) {
      formData.append(dynamicName, mainVideoFile[dynamicName]);
    }
    try {
    
      const config = {
        
        withCredentials: true,
      };
      const response = await axios.post(
        `${apikey}/page/insert-subsection-data`,
        formData,
        config
      );

      toast.success(response.data.message);
      setIsSaveButtonActive(false);
      setImage({});
      setmainVideoFile({});
      queryClient.invalidateQueries("getPageSectionDetails");
      queryClient.invalidateQueries("getPageSubSectionDetails");
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
    if (validateForm()) {
    }
  };

  useEffect(() => {
    pageSectionDetails &&
      pageSectionDetails.data &&
      pageSectionDetails.data.data &&
      pageSectionDetails.data.data.map((section, index) => {
        switch (section.code) {
          case "TEXTBOX":
            setsectionimageData((prevData) => ({
              ...prevData,
              ["section_" + section.id]: section.data,
            }));
          case "TEXTAREA":
            setsectionimageData((prevData) => ({
              ...prevData,
              ["section_" + section.id]: section.data,
            }));
          case "FILE_IMAGE":
            setsectionimageData((prevData) => ({
              ...prevData,
              ["section_" + section.id]: section.data,
            }));
          case "FILE_PDF":
            setsectionimageData((prevData) => ({
              ...prevData,
              ["section_" + section.id]: section.data,
            }));
          case "FILE_VIDEO":
            setsectionimageData((prevData) => ({
              ...prevData,
              ["section_" + section.id]: section.data,
            }));
          case "FILE_ICON":
            setsectionimageData((prevData) => ({
              ...prevData,
              ["section_" + section.id]: section.data,
            }));
          case "TEXTEDITOR":
            setsectionimageData((prevData) => ({
              ...prevData,
              ["section_" + section.id]: section.data,
            }));
        }
      });
  }, [pageSectionDetails.data]);
  useEffect(() => {
    pageSubSectionDetails &&
      pageSubSectionDetails.data &&
      pageSubSectionDetails.data.data &&
      pageSubSectionDetails.data.data.map((subsection, index) => {
        {
          subsection.details.map((section, index) => {
            switch (section.code) {
              case "TEXTBOX":
                setsectionimageData((prevData) => ({
                  ...prevData,
                  ["subSection_" + section.id]:
                    section.page_sub_section_detail_data,
                }));
              case "TEXTAREA":
                setsectionimageData((prevData) => ({
                  ...prevData,
                  ["subSection_" + section.id]:
                    section.page_sub_section_detail_data,
                }));
              case "FILE_IMAGE":
                setsectionimageData((prevData) => ({
                  ...prevData,
                  ["subSection_" + section.id]:
                    section.page_sub_section_detail_data,
                }));
              case "FILE_PDF":
                setsectionimageData((prevData) => ({
                  ...prevData,
                  ["subSection_" + section.id]:
                    section.page_sub_section_detail_data,
                }));
              case "FILE_VIDEO":
                setsectionimageData((prevData) => ({
                  ...prevData,
                  ["subSection_" + section.id]:
                    section.page_sub_section_detail_data,
                }));
              case "FILE_ICON":
                setsectionimageData((prevData) => ({
                  ...prevData,
                  ["subSection_" + section.id]:
                    section.page_sub_section_detail_data,
                }));
            }
          });
          subsection.child
            ?.filter((m) => m.is_deletable === true)
            .map((childsubsection, index) => {
              {
                childsubsection.details.map((section, index) => {
                  switch (section.code) {
                    case "TEXTBOX":
                      setsectionimageData((prevData) => ({
                        ...prevData,
                        ["subSection_" + section.id]:
                          section.page_sub_section_detail_data,
                      }));
                    case "TEXTAREA":
                      setsectionimageData((prevData) => ({
                        ...prevData,
                        ["subSection_" + section.id]:
                          section.page_sub_section_detail_data,
                      }));
                    case "FILE_IMAGE":
                      setsectionimageData((prevData) => ({
                        ...prevData,
                        ["subSection_" + section.id]:
                          section.page_sub_section_detail_data,
                      }));
                    case "FILE_PDF":
                      setsectionimageData((prevData) => ({
                        ...prevData,
                        ["subSection_" + section.id]:
                          section.page_sub_section_detail_data,
                      }));
                    case "FILE_VIDEO":
                      setsectionimageData((prevData) => ({
                        ...prevData,
                        ["subSection_" + section.id]:
                          section.page_sub_section_detail_data,
                      }));
                    case "FILE_ICON":
                      setsectionimageData((prevData) => ({
                        ...prevData,
                        ["subSection_" + section.id]:
                          section.page_sub_section_detail_data,
                      }));
                  }
                });
              }
            });

          subsection.child
            ?.filter((m) => m.is_deletable === false)
            .map((childsubsection, index) => {
              {
                childsubsection.details.map((section, index) => {
                  switch (section.code) {
                    case "TEXTBOX":
                      setsectionimageData((prevData) => ({
                        ...prevData,
                        ["subSection_" + section.id]:
                          section.page_sub_section_detail_data,
                      }));
                    case "TEXTAREA":
                      setsectionimageData((prevData) => ({
                        ...prevData,
                        ["subSection_" + section.id]:
                          section.page_sub_section_detail_data,
                      }));
                    case "FILE_IMAGE":
                      setsectionimageData((prevData) => ({
                        ...prevData,
                        ["subSection_" + section.id]:
                          section.page_sub_section_detail_data,
                      }));
                    case "FILE_PDF":
                      setsectionimageData((prevData) => ({
                        ...prevData,
                        ["subSection_" + section.id]:
                          section.page_sub_section_detail_data,
                      }));
                    case "FILE_VIDEO":
                      setsectionimageData((prevData) => ({
                        ...prevData,
                        ["subSection_" + section.id]:
                          section.page_sub_section_detail_data,
                      }));
                    case "FILE_ICON":
                      setsectionimageData((prevData) => ({
                        ...prevData,
                        ["subSection_" + section.id]:
                          section.page_sub_section_detail_data,
                      }));
                  }
                });
              }
            });
        }
      });
    // setAllSortingData(pageSubSectionDetails?.data?.data);
  }, [pageSubSectionDetails.data]);

  if (!pageSectionDetails.data) {
    <div>Loading</div>;
  }
  if (pageSectionDetails.isLoading) {
    <div>Loading</div>;
  }
  if (!pageSubSectionDetails.data) {
    <div>Loading</div>;
  }
  if (pageSubSectionDetails.isLoading) {
    <div>Loading</div>;
  }

  const isSaveButtonActiveVar =
    Object.values(sectionDataArray).length > 0 ||
    Object.values(subSectionDataArray).length > 0 ||
    Object.values(mainVideoFile).length > 0 ||
    Object.values(mainPdfFile).length > 0 ||
    Object.values(imageFiles).length > 0 ||
    Object.values(altTagData).length > 0;

  useEffect(() => {
    setIsSaveButtonActive(isSaveButtonActiveVar);
  }, [
    isSaveButtonActiveVar,
    sectionDataArray,
    subSectionDataArray,
    imageFiles,
    altTagData,
  ]);

  //For subSectionDropdown
  let sectionTitle = pageSectionDetails?.data?.data?.[0]?.section_title;
  if (sectionTitle) {
    sectionTitle = sectionTitle.replace("Manage", "Select");
  }

  const [activeTab, setActiveTab] = useState("list-home");
  const [selectedDropdown, setSelectedDropdown] = useState("Select One");

  const handleSelect = (selectedKey, event) => {
    setActiveTab(selectedKey);
    setSelectedDropdown(event.target.textContent);
    setOpen(false);
  };

  const isDropdown =
    pageSubSectionDetails?.data?.data?.[0]?.is_dropdown ?? false;

  const [allSortingData, setAllSortingData] = useState(
    pageSubSectionDetails?.data?.data || []
  );

  useEffect(() => {
    if (pageSubSectionDetails?.data) {
      setAllSortingData(pageSubSectionDetails?.data?.data);
    }
  }, [pageSubSectionDetails?.data]);

  let deleteBtnShow = true;
  if (allSortingData?.length === 1) {
    deleteBtnShow = false;
  }

  const handleReorderSubSections = (dragIndex, hoverIndex) => {
    const pageId = allSortingData[dragIndex].page_sub_section_id;
    const dragSubSection = allSortingData[dragIndex];
    const updatedSubSections = [...allSortingData];
    updatedSubSections.splice(dragIndex, 1);
    updatedSubSections.splice(hoverIndex, 0, dragSubSection);
    setAllSortingData(updatedSubSections);
  };

  const changeSortingIndexMutation = useMutation(querydata.updatePagesSorting, {
    // Configuration options for the mutation
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess: (data) => {
      setIsLoading(false);
      toast.dismiss();
      toast.success(data.message);
      queryClient.invalidateQueries("getPageSectionDetails");
      queryClient.invalidateQueries("getPageSubSectionDetails");
    },
    onSettled: () => {
      setIsLoading(false);
    },
    onMutate: () => {
      setIsLoading(true);
    },
  });

  return (
    <>

      <div className="page-body" onClick={open ? () => setOpen(!open) : undefined}>
        <div className="container-fluid dashboard-default">
          <div className="page-heading d-flex flex-wrap align-items-center justify-content-between">
            <nav className="w-100" aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link className="text-decoration-none" to="/manage-pages">
                    Manage Pages
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link
                    className="text-decoration-none"
                    to={`/manage-pages/manage-home-page/${pageId}`}
                  >
                    Manage{" "}
                    {pageSectionDetails &&
                      pageSectionDetails.data &&
                      pageSectionDetails.data.data &&
                      pageSectionDetails.data.data[0].section_title}
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Edit{" "}
                  {pageSectionDetails &&
                    pageSectionDetails.data &&
                    pageSectionDetails.data.data &&
                    pageSectionDetails.data.data[0].section_label}
                </li>{" "}
              </ol>
            </nav>
            <div className="left-header d-flex align-items-center">
              <Link
                to={`/manage-pages/manage-home-page/${pageId}`}
                className="back-arrow d-inline-block me-2"
              >
                <img src={backarrow} alt="" />
              </Link>

              <h4 className="fw-normal m-0 fs-6">
                {pageSectionDetails &&
                  pageSectionDetails.data &&
                  pageSectionDetails.data.data &&
                  pageSectionDetails.data.data[0].section_title}
              </h4>
            </div>
          </div>
          <div className="row mt-md-4 mt-3">
            <div className="col-md-12">
              <div className="card mb-4">
                <div className="card-header bg-transparent border-bottom py-2 d-flex align-items-center justify-content-between">
                  <h5 className="m-0 fw-medium">
                    {pageSectionDetails &&
                      pageSectionDetails.data &&
                      pageSectionDetails.data.data &&
                      pageSectionDetails.data.data[0].section_label}
                  </h5>
                </div>
                <div className="card-body">
                  <div className="upload-file-wrapper">
                    <div className="row">
                      {/* Page Sections Loop start */}
                      {pageSectionDetails?.data?.data?.map((section, index) => {
                        switch (section.code) {
                          case "TEXTBOX":
                            return (
                              <div className="col-lg-6 mb-4" key={section.id}>
                                <label
                                  className="form-label"
                                  htmlFor="IconText"
                                >
                                  {section.field_label}
                                </label>
                                <input
                                  type="text"
                                  className="form-control py-2 shadow-none px-3"
                                  id={section.field_label}
                                  name={section.id}
                                  defaultValue={
                                    sectionDataArray[section.id] || section.data
                                  }
                                  {...(section.field_max_length && {
                                    maxLength: section.field_max_length,
                                  })}
                                  onChange={(e) =>
                                    handleChangeSection(e, section)
                                  }
                                  {...(section.field_max_length && {
                                    placeholder: `Character limit, upto ${section.field_max_length} characters`,
                                  })}
                                  required
                                />
                              </div>
                            );
                          case "TEXTAREA":
                            return (
                              <div className="col-md-12 mb-4" key={section.id}>
                                <label className="form-label" htmlFor="Body">
                                  {section.field_label}
                                </label>
                                <textarea
                                  className="form-control py-2 shadow-none px-3"
                                  rows="5"
                                  id={section.field_label}
                                  name={section.id}
                                  defaultValue={
                                    sectionDataArray[section.id] || section.data
                                  }
                                  {...(section.field_max_length && {
                                    placeholder: `Character limit, upto ${section.field_max_length} characters`,
                                  })}
                                  onChange={handleChangeSection}
                                  required
                                  {...(section.field_max_length && {
                                    maxLength: section.field_max_length,
                                  })}
                                ></textarea>
                              </div>
                            );
                          case "FILE_IMAGE":
                            return (
                              <>
                                {sectionimageData["section_" + section.id] &&
                                  section.data ? (
                                  <>
                                    <div className="col-12">
                                      <div className="d-flex align-items-center">
                                        <label className="form-label mb-0">
                                          {section.field_label}
                                        </label>

                                        <a
                                          title="Add image alt tag"
                                          className="btn image-tag"
                                          onClick={() =>
                                            handleShowImageTagModal(
                                              "section_" + section.id,
                                              section.img_alt_tag
                                            )
                                          }
                                        >
                                          <HiPlus />
                                        </a>
                                      </div>

                                      <div
                                        className="uploaded-img-view overflow-hidden"
                                        style={{
                                          height: "200px",
                                          width: "200px",
                                          position: "relative",
                                        }}
                                      >
                                        <div>
                                          <a
                                            href={section.data}
                                            target="_blank"
                                          >
                                            <img
                                              src={section.data}
                                              style={{
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "cover",
                                              }}
                                            />
                                          </a>
                                          <div
                                            style={{
                                              position: "absolute",
                                              top: "0",
                                              right: "0",
                                            }}
                                          >
                                            <button
                                              className="remove-image-button"
                                              onClick={() =>
                                                handleRemoveImage(
                                                  `section_${section.id}`
                                                )
                                              }
                                            >
                                              <i className="fa fa-times"></i>
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}
                                {image && image[`section_${section.id}`] ? (
                                  <>
                                    <div className="col-12">
                                      <div className="d-flex align-items-center">
                                        <label className="form-label mb-0">
                                          {section.field_label}
                                        </label>{" "}
                                        <a
                                          title="Add image alt tag"
                                          className="btn image-tag"
                                          onClick={() =>
                                            handleShowImageTagModal(
                                              "section_" + section.id,
                                              section.img_alt_tag
                                            )
                                          }
                                        >
                                          <HiPlus />
                                        </a>
                                      </div>

                                      <div
                                        className="uploaded-img-view overflow-hidden mb-4"
                                        style={{
                                          height: "200px",
                                          width: "200px",
                                          position: "relative",
                                        }}
                                      >
                                        <div>
                                          <a
                                            href={
                                              image[`section_${section.id}`]
                                            }
                                            target="_blank"
                                          >
                                            <img
                                              src={
                                                image[`section_${section.id}`]
                                              }
                                              style={{
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "cover",
                                              }}
                                            />
                                          </a>
                                          <div
                                            style={{
                                              position: "absolute",
                                              top: "0",
                                              right: "0",
                                            }}
                                          >
                                            <button
                                              className="remove-image-button"
                                              onClick={() =>
                                                handleRemoveImage(
                                                  `section_${section.id}`
                                                )
                                              }
                                            >
                                              <i className="fa fa-times"></i>
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}

                                {!image[`section_${section.id}`] &&
                                  !sectionimageData["section_" + section.id] ? (
                                  <div className="col-12 mb-4" key={section.id}>
                                    <div className="d-flex align-items-center">
                                      <label className="form-label mb-0">
                                        {section.field_label}
                                      </label>

                                      <a
                                        title="Add image alt tag"
                                        className="btn image-tag"
                                        onClick={() =>
                                          handleShowImageTagModal(
                                            "section_" + section.id,
                                            section.img_alt_tag
                                          )
                                        }
                                      >
                                        <HiPlus />
                                      </a>
                                    </div>

                                    <label
                                      htmlFor="UploadCover"
                                      className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5"
                                      onClick={() =>
                                        showCropMediaModal(
                                          `section_${section.id}`,
                                          section.image_dimension
                                        )
                                      }
                                    >
                                      <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                                        <button
                                          type="button"
                                          className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                                        >
                                          Upload from gallery
                                        </button>
                                      </div>
                                    </label>

                                    {formErrors[section.id] && (
                                      <p style={{ color: "red" }}>
                                        {formErrors[section.id]}
                                      </p>
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            );
                          case "FILE_PDF":
                            return (
                              <>
                                {sectionimageData["section_" + section.id] &&
                                  section.data ? (
                                  <>
                                    <label className="form-label">
                                      {section.field_label}
                                    </label>
                                    <div
                                      className="uploaded-img-view overflow-hidden"
                                      style={{
                                        height: "200px",
                                        width: "200px",
                                        position: "relative",
                                      }}
                                    >
                                      <div>
                                        <a href={section.data} target="_blank">
                                          <img
                                            src={
                                              section.data.endsWith(".pdf")
                                                ? pdfImg
                                                : section.data
                                            }

                                            style={
                                              section.data.endsWith(".pdf")
                                                ? {
                                                  width: "68%",
                                                  height: "68%",
                                                }
                                                : {
                                                  width: "100%",
                                                  height: "100%",
                                                  objectFit: "cover",
                                                }
                                            }
                                          />
                                        </a>
                                        <div
                                          style={
                                            section.data.endsWith(".pdf")
                                              ? {
                                                position: "absolute",
                                                top: "0",
                                                right: "0",
                                              }
                                              : {
                                                position: "absolute",
                                                top: "0",
                                                right: "0",
                                              }
                                          }

                                        >
                                          <button
                                            className="remove-image-button"
                                            onClick={() =>
                                              handleRemoveImage(
                                                `section_${section.id}`
                                              )
                                            }
                                          >
                                            <i className="fa fa-times"></i>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}

                                {image && image[`section_${section.id}`] ? (
                                  <>
                                    <div className="col-12">
                                      <label className="form-label">
                                        {section.field_label}
                                      </label>
                                      <div
                                        className="uploaded-img-view overflow-hidden"
                                        style={{
                                          height: "200px",
                                          width: "200px",
                                          position: "relative",
                                        }}
                                      >
                                        <div

                                        >
                                          <a
                                            href={
                                              image[`section_${section.id}`]
                                            }
                                            target="_blank"
                                          >
                                            <img

                                              src={
                                                image[
                                                  `section_${section.id}`
                                                ].endsWith(".pdf")
                                                  ? pdfImg
                                                  : image[
                                                  `section_${section.id}`
                                                  ]
                                              }
                                              style={
                                                image[
                                                  `section_${section.id}`
                                                ].endsWith(".pdf")
                                                  ? {
                                                    width: "68%",
                                                    height: "68%",
                                                  }
                                                  : {
                                                    width: "100%",
                                                    height: "100%",
                                                    objectFit: "cover",
                                                  }
                                              }
                                            />
                                          </a>
                                          <div
                                            style={
                                              image[
                                                `section_${section.id}`
                                              ].endsWith(".pdf")
                                                ? {
                                                  position: "absolute",
                                                  top: "0",
                                                  right: "0",
                                                }
                                                : {
                                                  position: "absolute",
                                                  top: "0",
                                                  right: "0",
                                                }
                                            }

                                          >
                                            <button
                                              className="remove-image-button"
                                              onClick={() =>
                                                handleRemoveImage(
                                                  `section_${section.id}`
                                                )
                                              }
                                            >
                                              <i className="fa fa-times"></i>
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}

                                {!image[`section_${section.id}`] &&
                                  !sectionimageData["section_" + section.id] ? (
                                  <div className="col-12 mb-4" key={section.id}>
                                    <label className="form-label">
                                      {section.field_label}
                                    </label>
                                    <label
                                      htmlFor="UploadCover"
                                      className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5"
                                      onClick={() =>
                                        showCropMediaModal(
                                          `section_${section.id}`,
                                          section.image_dimension
                                        )
                                      }
                                    >
                                      <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                                        <button
                                          type="button"
                                          className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                                        >
                                          Upload from gallery
                                        </button>
                                      </div>
                                    </label>

                                    {formErrors[section.id] && (
                                      <p style={{ color: "red" }}>
                                        {formErrors[section.id]}
                                      </p>
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            );
                          case "FILE_VIDEO":
                            return (
                              <>
                                {sectionimageData["section_" + section.id] &&
                                  section.data ? (
                                  <>
                                  <div className="col-12">
                                    <label className="form-label px-0">
                                      {section.field_label}
                                    </label>

                                    <div
                                      className="uploaded-img-view overflow-hidden"
                                      style={{
                                        height: "200px",
                                        width: "200px",
                                        position: "relative",
                                      }}
                                    >
                                      <div>
                                        <a target="_blank">
                                          <img
                                            src={videoImg}
                                            style={{
                                              width: "100%",
                                              height: "100%",
                                              objectFit: "cover",
                                            }}
                                          />
                                        </a>
                                        <div
                                          style={{
                                            position: "absolute",
                                            top: "0",
                                            right: "0",
                                          }}
                                        >
                                          <button
                                            className="remove-image-button"
                                            onClick={() =>
                                              handleRemoveImage(
                                                `section_${section.id}`
                                              )
                                            }
                                          >
                                            <i className="fa fa-times"></i>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}
                                {mainVideoFile &&
                                  mainVideoFile[`section_${section.id}`] ? (
                                  <>
                                    <label className="form-label ksjdfklsdj">
                                      {section.field_label}
                                    </label>

                                    <div
                                      className="uploaded-img-view overflow-hidden"
                                      style={{
                                        height: "200px",
                                        width: "200px",
                                        position: "relative",
                                      }}
                                    >
                                      <div>
                                        <a target="_blank">
                                          <img
                                            src={videoImg}
                                            style={{
                                              width: "100%",
                                              height: "100%",
                                              objectFit: "cover",
                                            }}
                                          />
                                        </a>
                                        <div
                                          style={{
                                            position: "absolute",
                                            top: "0",
                                            right: "0",
                                          }}
                                        >
                                          <button
                                            className="remove-image-button"
                                            onClick={() =>
                                              handleRemoveImage(
                                                `section_${section.id}`
                                              )
                                            }
                                          >
                                            <i className="fa fa-times"></i>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}

                                {!mainVideoFile[`section_${section.id}`] &&
                                  !sectionimageData["section_" + section.id] ? (
                                  <div
                                    className="col-12 mb-4 mainVideoFile"
                                    key={section.id}
                                  >
                                    <label className="form-label">
                                      {section.field_label}
                                    </label>
                                    <label
                                      htmlFor="UploadCover"
                                      className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5"
                                      onClick={() =>
                                        showCropMediaModal(
                                          `section_${section.id}`,
                                          section.image_dimension
                                        )
                                      }
                                    >
                                      <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                                        <button
                                          type="button"
                                          className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                                        >
                                          Upload video from gallery
                                        </button>
                                      </div>
                                    </label>

                                    {formErrors[section.id] && (
                                      <p style={{ color: "red" }}>
                                        {formErrors[section.id]}
                                      </p>
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            );
                          case "FILE_ICON":
                            return (
                              <>
                                {sectionimageData["section_" + section.id] &&
                                  section.data ? (
                                  <>
                                    <div className="col-12 mt-4 mb-4">
                                      <label className="form-label">
                                        {section.field_label}
                                      </label>
                                      <div
                                        className="uploaded-img-view overflow-hidden"
                                        style={{
                                          height: "200px",
                                          width: "200px",
                                          position: "relative",
                                        }}
                                      >
                                        <div>
                                          <a
                                            href={section.data}
                                            target="_blank"
                                          >
                                            <img
                                              src={section.data}
                                              style={{

                                                height: "100%",
                                                objectFit: "cover",
                                                backgroundColor: "darkgrey",
                                              }}

                                            />
                                          </a>
                                          <div
                                            style={{
                                              position: "absolute",
                                              top: "0",
                                              right: "0",
                                            }}
                                          >
                                            <button
                                              className="remove-image-button"
                                              onClick={() =>
                                                handleRemoveImage(
                                                  `section_${section.id}`
                                                )
                                              }
                                            >
                                              <i className="fa fa-times"></i>
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}
                                {image && image[`section_${section.id}`] ? (
                                  <>
                                    <div className="col-12 mt-4 mb-4">
                                      <label className="form-label">
                                        {section.field_label}
                                      </label>{" "}
                                      <div
                                        className="uploaded-img-view overflow-hidden"
                                        style={{
                                          height: "200px",
                                          width: "200px",
                                          position: "relative",
                                        }}
                                      >
                                        <div>
                                          <a
                                            href={
                                              image[`section_${section.id}`]
                                            }
                                            target="_blank"
                                          >
                                            <img
                                              src={
                                                image[`section_${section.id}`]
                                              }
                                              style={{
                                                // width: "100%",
                                                height: "100%",
                                                objectFit: "cover",
                                                backgroundColor: "darkgrey",
                                              }}
                                            />
                                          </a>
                                          <div
                                            style={{
                                              position: "absolute",
                                              top: "0",
                                              right: "0",
                                            }}
                                          >
                                            <button
                                              className="remove-image-button"
                                              onClick={() =>
                                                handleRemoveImage(
                                                  `section_${section.id}`
                                                )
                                              }
                                            >
                                              <i className="fa fa-times"></i>
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}

                                {!image[`section_${section.id}`] &&
                                  !sectionimageData["section_" + section.id] ? (
                                  <div
                                    className="col-md-12 mb-4 mt-4"
                                    key={section.id}
                                  >
                                    <label className="form-label">
                                      {section.field_label}
                                    </label>
                                    <label
                                      htmlFor="UploadCover"
                                      className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5"
                                      onClick={() =>
                                        showCropMediaModal(
                                          `section_${section.id}`,
                                          section.image_dimension
                                        )
                                      }
                                    >
                                      <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                                        <button
                                          type="button"
                                          className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                                        >
                                          Upload from gallery
                                        </button>
                                      </div>
                                    </label>

                                    {formErrors[section.id] && (
                                      <p style={{ color: "red" }}>
                                        {formErrors[section.id]}
                                      </p>
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            );
                          case "TEXTEDITOR":
                            return (
                              <div className="col-md-12 mb-4" key={section.id}>
                                <label
                                  className="form-label"
                                  htmlFor={section.field_label}
                                >
                                  {section.field_label}
                                </label>
                                <ReactQuill
                                  style={{ height: "200px" }}
                                  value={
                                    sectionDataArray[section.id] || section.data
                                  }
                                  onChange={(value) => {
                                    handleChangeSection({
                                      target: { name: section.id, value },
                                    });
                                  }}
                                />
                              </div>
                            );

                          default:
                            return null; // or a default component for unknown types
                        }
                      })}
                      {/* Page Sections Loop end */}
                    </div>
                    <div className="row mt-4">
                      {isDropdown ? (
                        <>
                          {pageSubSectionDetails?.data?.data.filter(
                            (item) => item.is_deletable === true
                          ).length > 1 ? (
                            <div className="col-xxl-3 col-xl-4 col-lg-5 mb-lg-0 mb-4">
                              <div className="sub-brand-contain">
                                <DndProvider backend={HTML5Backend}>
                                  <DropDownForEditPage
                                    selectedDropdown={selectedDropdown}
                                    pageSubSectionDetails={pageSubSectionDetails}
                                    handleSelect={handleSelect}
                                    handleShowModal={handleShowModal}
                                    handleShowEditSubSectionModal={handleShowEditSubSectionModal}
                                    handleReorderSubSections={handleReorderSubSections}
                                    changeSortingIndexMutation={changeSortingIndexMutation}
                                    open={open}
                                    setOpen={setOpen}
                                  />
                                </DndProvider>
                              </div>
                              <div className="mb-4">
                                {pageSubSectionDetails &&
                                  pageSubSectionDetails.data &&
                                  pageSubSectionDetails.data.data &&
                                  pageSubSectionDetails?.data?.data[0]
                                    ?.is_deletable ? (
                                  <div className="facility-view addmore-facility">
                                    <button
                                      onClick={() =>
                                        handleSubmitSubSection(
                                          pageSubSectionDetails &&
                                          pageSubSectionDetails.data &&
                                          pageSubSectionDetails.data.data &&
                                          pageSubSectionDetails?.data?.data[0]
                                            ?.page_sub_section_id
                                        )
                                      }
                                      type="button"
                                      className="btn btn-primary btn-theme outline-btn text-uppercase px-3 py-3 fw-semibold w-100"
                                    >
                                      <i
                                        className="fa fa-plus me-2"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      Add New
                                    </button>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          ) : (
                            <div className="col-xxl-3 col-xl-4 col-lg-5 mb-lg-0 mb-4">
                              <div className="sub-brand-contain">
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant="secondary"
                                    id="dropdown-basic"
                                    className="form-select form-control text-start sub-brand-select bg-white py-3"
                                  >
                                    {selectedDropdown}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    {pageSubSectionDetails?.data?.data?.map(
                                      (subsection, index) => (
                                        <Dropdown.Item
                                          className="d-flex align-item-center"
                                          eventKey={`TabLogoOne-tab-pane-${subsection.page_sub_section_id}`}
                                        >
                                          <span className="select-text"
                                            onClick={(event) =>
                                              handleSelect(
                                                `TabLogoOne-tab-pane-${subsection.page_sub_section_id}`,
                                                event
                                              )
                                            }
                                          >
                                            {subsection.sub_section_title}
                                          </span>

                                        </Dropdown.Item>
                                      )
                                    )}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                              <div className="mb-4">
                                {pageSubSectionDetails &&
                                  pageSubSectionDetails.data &&
                                  pageSubSectionDetails.data.data &&
                                  pageSubSectionDetails?.data?.data[0]
                                    ?.is_deletable ? (
                                  <div className="facility-view addmore-facility">
                                    <button
                                      onClick={() =>
                                        handleSubmitSubSection(
                                          pageSubSectionDetails &&
                                          pageSubSectionDetails.data &&
                                          pageSubSectionDetails.data.data &&
                                          pageSubSectionDetails?.data?.data[0]
                                            ?.page_sub_section_id
                                        )
                                      }
                                      type="button"
                                      className="btn btn-primary btn-theme outline-btn text-uppercase px-3 py-3 fw-semibold w-100"
                                    >
                                      <i
                                        className="fa fa-plus me-2"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      Add New
                                    </button>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          )}


                          <div className="col-xl-12 col-lg-7 mt-4">
                            <div className="tab-content" id="nav-tabContent"> 
                              {pageSubSectionDetails &&
                                pageSubSectionDetails.data &&
                                pageSubSectionDetails.data.data &&
                                pageSubSectionDetails.data.data.map(
                                  (subsection, index) => {
                                    return (
                                      <div
                                        // className={`tab-pane fade show ${activeTab ===
                                        //   `TabLogoOne-tab-pane-${subsection.page_sub_section_id}`
                                        //   ? "active"
                                        //   : ""
                                        //   }`}
                                          className={`tab-pane fade ${activeTab === subsection.page_sub_section_id ? 'show active' : ''}`}
                                        // className="tab-pane fade"
                                        // id={`TabLogoOne-tab-pane-${subsection.page_sub_section_id}`}
                                        role="tabpanel"
                                        // aria-labelledby={`TabLogoOne-tab-${subsection.page_sub_section_id}`}
                                        // tabIndex="0"
                                      >
                                        <EditSubSectionComponent
                                          key={subsection.page_sub_section_id}
                                          subsection={subsection}
                                          handleSubSectionChange={
                                            handleSubSectionChange
                                          }
                                          subSectionDataArray={
                                            subSectionDataArray
                                          }
                                          image={image}
                                          handleRemoveImage={handleRemoveImage}
                                          handleRemoveSubSectionImage={
                                            handleRemoveSubSectionImage
                                          }
                                          sectionimageData={sectionimageData}
                                          formErrors={formErrors}
                                          videoImg={videoImg}
                                          showCropMediaModal={
                                            showCropMediaModal
                                          }
                                          mainVideoFile={mainVideoFile}
                                          sectionDataArray={sectionDataArray}
                                          handleShowModal={handleShowModal}
                                          handleSubmitSubSection={
                                            handleSubmitSubSection
                                          }
                                          handleShowEditSubSectionModal={
                                            handleShowEditSubSectionModal
                                          }
                                          handleReorderSubSections={
                                            handleReorderSubSections
                                          }
                                          changeSortingIndexMutation={
                                            changeSortingIndexMutation
                                          }
                                          deleteBtnShow={deleteBtnShow}
                                          handleShowImageTagModal={
                                            handleShowImageTagModal
                                          }
                                        />
                                      </div>
                                    );
                                  }
                                )}
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="col-xxl-3 col-xl-4 col-lg-5 mb-lg-0 mb-4">
                            <div className="left-tabs">
                              {allSortingData?.filter(
                                (item) => item?.is_deletable === true
                              ) && (
                                  <Tabs
                                    data={allSortingData?.filter(
                                      (item) => item.is_deletable === true
                                    )}
                                    handleShowEditSubSectionModal={
                                      handleShowEditSubSectionModal
                                    }
                                    handleShowModal={handleShowModal}
                                    handleReorderSubSections={
                                      handleReorderSubSections
                                    }
                                    changeSortingIndexMutation={
                                      changeSortingIndexMutation
                                    }
                                    setActiveTab={setActiveTab}
                                  />
                                )}
                            </div>
                            <div className="mb-4">
                              {allSortingData?.filter(
                                      (item) => item.is_deletable === true
                                    ).length > 0 ? (
                                <div className="facility-view addmore-facility">
                                  <button
                                    onClick={() =>
                                      handleSubmitSubSection(
                                        allSortingData?.filter(
                                          (item) => item.is_deletable === true
                                        )[0]
                                          ?.page_sub_section_id
                                      )
                                    }
                                    type="button"
                                    className="btn btn-primary btn-theme outline-btn text-uppercase px-3 py-3 fw-semibold w-100"
                                  >
                                    <i
                                      className="fa fa-plus me-2"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    Add New
                                  </button>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>

                          <div className="col-xxl-9 col-xl-8 col-lg-7">
                            <div
                              className="tab-content ps-lg-4 ps-3"
                              id="nav-tabContent"
                            >
                              {pageSubSectionDetails?.data?.data
                                ?.filter((item) => item.is_deletable === true)
                                .map((subsection, index) => {
                                  return (
                                    <div
                                      className={`tab-pane fade ${activeTab ===
                                        subsection.page_sub_section_id
                                        ? "show active"
                                        : ""
                                        }`}
                                      // id={`TabLogoOne-tab-pane-${subsection.page_sub_section_id}`}
                                      // role="tabpanel"
                                      // aria-labelledby={`TabLogoOne-tab-${subsection.page_sub_section_id}`}
                                      // tabIndex="0"
                                    >
                                      <EditSubSectionComponent
                                        key={subsection.page_sub_section_id}
                                        subsection={subsection}
                                        handleSubSectionChange={
                                          handleSubSectionChange
                                        }
                                        subSectionDataArray={
                                          subSectionDataArray
                                        }
                                        image={image}
                                        handleRemoveImage={handleRemoveImage}
                                        handleRemoveSubSectionImage={
                                          handleRemoveSubSectionImage
                                        }
                                        sectionimageData={sectionimageData}
                                        formErrors={formErrors}
                                        videoImg={videoImg}
                                        showCropMediaModal={showCropMediaModal}
                                        mainVideoFile={mainVideoFile}
                                        sectionDataArray={sectionDataArray}
                                        handleShowModal={handleShowModal}
                                        handleSubmitSubSection={
                                          handleSubmitSubSection
                                        }
                                        handleShowEditSubSectionModal={
                                          handleShowEditSubSectionModal
                                        }
                                        handleReorderSubSections={
                                          handleReorderSubSections
                                        }
                                        changeSortingIndexMutation={
                                          changeSortingIndexMutation
                                        }
                                        deleteBtnShow={deleteBtnShow}
                                        handleShowImageTagModal={
                                          handleShowImageTagModal
                                        }
                                      />
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                          {allSortingData?.filter(
                            (item) => item?.is_deletable === true
                          ).length > 0 ? (
                            <hr className="mt-4" />
                          ) : (
                            ""
                          )}

                          {/* not editable  */}
                          <div className="col-xxl-3 col-xl-4 col-lg-5 mb-lg-0 mb-4">
                            <div className="left-tabs">
                              {allSortingData && (
                                <Tabs
                                  data={allSortingData.filter(
                                    (item) => item.is_deletable === false
                                  )}
                                  handleShowEditSubSectionModal={
                                    handleShowEditSubSectionModal
                                  }
                                  handleShowModal={handleShowModal}
                                  handleReorderSubSections={
                                    handleReorderSubSections
                                  }
                                  changeSortingIndexMutation={
                                    changeSortingIndexMutation
                                  }
                                  setActiveTab={setActiveTab}
                                  activeTab={activeTab}
                                />
                              )}
                            </div>
                          </div>

                          <div className="col-xxl-9 col-xl-8 col-lg-7">
                            <div
                              className="tab-content ps-lg-4 ps-3"
                              id="nav-tabContent"
                            >
                              {pageSubSectionDetails?.data?.data
                                ?.filter((item) => item.is_deletable === false)
                                .map((subsection, index) => {
                                  return (
                                    <div
                                      className={`tab-pane fade show ${activeTab ===
                                        `TabLogoOne-tab-pane-${subsection.page_sub_section_id}`
                                        ? "active"
                                        : ""
                                        }`}
                                      id={`TabLogoOne-tab-pane-${subsection.page_sub_section_id}`}
                                      role="tabpanel"
                                      aria-labelledby={`TabLogoOne-tab-${subsection.page_sub_section_id}`}
                                      tabIndex="0"
                                    >
                                      <EditSubSectionComponent
                                        key={subsection.page_sub_section_id}
                                        subsection={subsection}
                                        handleSubSectionChange={
                                          handleSubSectionChange
                                        }
                                        subSectionDataArray={
                                          subSectionDataArray
                                        }
                                        image={image}
                                        handleRemoveImage={handleRemoveImage}
                                        handleRemoveSubSectionImage={
                                          handleRemoveSubSectionImage
                                        }
                                        sectionimageData={sectionimageData}
                                        formErrors={formErrors}
                                        videoImg={videoImg}
                                        showCropMediaModal={showCropMediaModal}
                                        mainVideoFile={mainVideoFile}
                                        sectionDataArray={sectionDataArray}
                                        handleShowModal={handleShowModal}
                                        handleSubmitSubSection={
                                          handleSubmitSubSection
                                        }
                                        handleShowEditSubSectionModal={
                                          handleShowEditSubSectionModal
                                        }
                                        handleReorderSubSections={
                                          handleReorderSubSections
                                        }
                                        changeSortingIndexMutation={
                                          changeSortingIndexMutation
                                        }
                                        deleteBtnShow={deleteBtnShow}
                                        handleShowImageTagModal={
                                          handleShowImageTagModal
                                        }
                                      />
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="col-12 text-end mb-2 mt-3">
                      <a
                        href="#"
                        onClick={handlePreviewSubmitSection}
                        className="btn btn-primary btn-theme outline-btn fw-semibold text-uppercase px-2 py-1 me-2 btn-sm"
                      >
                        Preview
                      </a>
                      {isLoading ? (
                        <Button
                          type="submit"
                          className="btn btn-primary btn-theme fw-semibold text-uppercase px-2 py-1 ms-2"
                          onClick={handleSubmit}
                        >
                          <div
                            class="spinner-border text-primary"
                            role="status"
                          ></div>
                        </Button>
                      ) : (
                        <input
                          type="submit"
                          disabled={!isSaveButtonActive}
                          onClick={handleSubmitSection}
                          value="Save"
                          className="btn btn-primary btn-theme fw-semibold text-uppercase px-2 py-1"
                        />
                      )}
                    </div>
                    {/* </form> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal size="xl" show={cropMediaModal} onHide={closeCropMediaModal}>
        <Modal.Header className="modal-header py-2" closeButton>
          <Modal.Title className="modal-title fs-5">
            {"Select Media"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className=" overflow-x-auto overflow-y-auto">
          {!folderId && <MediaFolderView setFolderId={setFolderId} />}
          {folderId && !albumId && !websiteId && (
            <ManageAlbumSelection
              folderId={folderId}
              setAlbumId={setAlbumId}
              setwebsiteId={setwebsiteId}
              setFolderId={setFolderId}
            />
          )}
          {folderId && albumId && websiteId && (
            <AlbumViewSelection
              albumId={albumId}
              websiteId={websiteId}
              setAlbumId={setAlbumId}
              setwebsiteId={setwebsiteId}
              setFolderId={setFolderId}
              setCropMediaModal1={setCropMediaModal}
              setmainCroppedImage={setImage}
              setmainCroppedFile={setImageFiles}
              setting_value={
                getAspectRatio &&
                getAspectRatio.data &&
                getAspectRatio.data.data &&
                getAspectRatio.data.data.setting_value
              }
              currentFileId={currentFileId}
              setmainVideoFile={setmainVideoFile}
              setmainPdfFile={setmainPdfFile}
              imageDimension={imageDimension}
            />
          )}
        </Modal.Body>
      </Modal>

      <Modal
        show={addSubsectionModal}
        onHide={closeSubSectionModal}
        backdrop="static"
      >
        <Modal.Header
          onClick={handleCloseSubmitSubSection}
          className="modal-header py-2"
          closeButton
        >
          <Modal.Title className="modal-title fs-5">{`${mTitle}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body className=" overflow-x-auto overflow-y-auto">
          <div className="crop-now">
            <div className="row">
              <div className="col-md-12 mb-4">
                <label className="form-label" htmlFor="Name">
                  Title
                </label>
                <input
                  name="title"
                  value={values.title}
                  onChange={handleChange}
                  maxLength={100}
                  onBlur={handleBlur}
                  id="title"
                  className="form-control py-2 shadow-none px-3"
                />
                {/* {errors.title && touched.title ? (
                  <p className="form-error text-danger">{errors.title}</p>
                ) : null} */}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="text-uppercase btn-sm" onClick={handleCloseSubmitSubSection}>
            Cancel
          </Button>
          <Button variant="primary" className="text-uppercase btn-sm" onClick={handleSubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <DeleteModal
        show={showModal}
        handleClose={handleCloseModal}
        rowData={selectedRowData}
        rowNameData={selectedRowNameData}
        setSelectedDropdown={setSelectedDropdown}
        setActiveTab={setActiveTab}
      />
      <EditSubSectionModal
        show={showEditSubSectionModal}
        handleClose={handleCloseEditSubSectionModal}
        pageSubSectionId={selectedRowData}
        rowNameData={selectedRowNameData}
        setSelectedDropdown={setSelectedDropdown}
        setActiveTab={setActiveTab}
      />
      <ImageTagModal
        showModalImageTagModal={showModalImageTagModal}
        handleCloseImageTagModal={handleCloseImageTagModal}
        // initialImageAltTag={selectedRowImageAltTagData}
        initialImageAltTag={initialValuesOfImgAltTag}
        setAltTagData={setAltTagData}
        altTagId={altTagId}
        altTagData={altTagData}
      // setAltTagData={updatedAltTagData => setAltTagData({ ...altTagData, [altTagId]: updatedAltTagData })}
      />
    </>
  );
}

export default EditPage;
