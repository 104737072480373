import { Link, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import Table from "../../components/Table2";
import { getAllPressRelease } from "../dataService/DataService";
import { useQuery, useQueryClient } from "react-query";
import DeleteModal from "./DeleteModal";



const ManageUserRole = () => {

    const [isLoading, setIsLoading] = useState(false);

    const cach = useQueryClient();
    const navigate = useNavigate();
    
    const queryClient = useQueryClient();
    const handleNavigateToEdit = (id) => {
        navigate(`/edit-press-release/${id}`)
    };
    const [showModal, setShowModal] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState({});
    const [selectedRowNameData, setSelectedRowNameData] = useState({});
    const [filter, setFilter] = useState(1);

    const handleShowModal = (rowData) => {
        setSelectedRowData(rowData.original.website_press_release_id);
        setSelectedRowNameData(rowData.original.title);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const [selectedRows, setSelectedRows] = useState([]);

   
    
  
    


    const columns = React.useMemo(
        () => [
            { Header: "Sr. No", accessor: "srNo" },
           
        
            {
                Header: "Image",
                accessor: "image_path",
                Cell: ({ row }) => (
                    <>
                        {row.original.image_path ? <img src={row.original.image_path} width="100"></img> : ""}
                    </>
                ),
            },
            {
                Header: "Title",
                Cell: ({ row }) => `${row.original.title}`,
            },
            { Header: "Categories", accessor: "website" },
            { Header: "Publish Date", accessor: "published_date" },
            {
                Header: "Description",
                accessor: "description",
                Cell: ({ row }) => (
                    <>
                        {row.original.description.length > 20
                            ? `${row.original.description.substring(0, 20)}...`
                            : row.original.description}
                    </>
                ),
            },
            {
                Header: "Status",
                Cell: ({ row }) => `${row.original.is_published == 1 ? "Published" : "Unpublished"}`,
            },

            {
                Header: "Actions",
                Cell: ({ row }) => (
                    <>
                        <button
                            onClick={() =>
                                handleNavigateToEdit(row.original.website_press_release_id)
                            }
                            className="btn btn-primary btn-sm btn-theme"
                        >
                            <i className="fa fa-pencil" aria-hidden="true"></i>
                        </button>
                        <button
                            variant="info" onClick={() => handleShowModal(row)}
                            className="btn btn-primary bg-danger border-danger btn-sm btn-theme ms-1"
                        >
                            <i className="fa fa-trash" aria-hidden="true"></i>
                        </button>
                    </>
                ),
            },
        ],
        []
    );

    let allPressRelease = useQuery(
        "getAllPressRelease",
        getAllPressRelease
    );

 




    if (!allPressRelease.data) {
        <div>Loading</div>
    }
    if (allPressRelease.isLoading) {
        <div>Loading</div>
    }
 

    return (
        <>

            <div className="page-body">
                <div className="container-fluid dashboard-default">
                    <div className="page-heading d-flex flex-wrap align-items-center justify-content-between">
                        <nav className="w-100" aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a className="text-decoration-none" href="#">Home</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Press Release</li>
                            </ol>
                        </nav>
                        <div className="left-header">
                            <h4 className="fw-normal m-0 fs-6">Press Release</h4>
                        </div>
                    </div>
                    <div className="row mt-md-4 mt-3">
                        <div className="col-md-12">
                            <div className="card mb-4">
                                <div className="card-header bg-transparent border-bottom py-2 d-flex align-items-center justify-content-between">
                                    <h5 className="m-0 fw-medium fs-6">All Press Releases</h5>
                                    <div className="right-actions">

                                        <Link to="/create-press-release" className="btn btn-primary btn-theme fw-semibold text-uppercase px-2 py-1">Add New</Link>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="tabledata">
                                        <div className="table-responsive">
                                            {allPressRelease.data &&
                                                <Table data={allPressRelease?.data?.data} columns={columns} />
                                            }
                                            <DeleteModal
                                                show={showModal}
                                                handleClose={handleCloseModal}
                                                rowData={selectedRowData}
                                                rowNameData={selectedRowNameData}
                                            />
                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default ManageUserRole;
