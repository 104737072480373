import React, { createContext, useState } from "react";

// Create the Context
export const GlobalContext = createContext();

// Create a Provider component
export const GlobalProvider = ({ children }) => {
  let bwebsiteId = "";
  // const [bwebsiteId, setGlobalVariable] = useState("");
  if (window.location.origin === "http://localhost:3000")
    // setGlobalVariable("TOC");
    bwebsiteId = "AHR";

  if (window.location.origin === "http://localhost:3001") {
    // setGlobalVariable("AHR");
    bwebsiteId = "TOC";
  }

  if (window.location.origin === "https://ahrdev.digitechsoft.com")
    bwebsiteId = "AHR";
  if (window.location.origin === "http://demoweb.digitechsoft.com")
    bwebsiteId = "AHR";

  if (window.location.origin === "https://toc.digitechsoft.com") {
    bwebsiteId = "TOC";
  }
  if (window.location.origin === "https://ahr-ucmsuat.atmospherehotelsandresorts.com")
    bwebsiteId = "AHR";

  if (window.location.origin === "https://toc-ucmsuat.theozencollection.com") {
    bwebsiteId = "TOC";
  }


  if (window.location.origin === "https://ahr-ucms.atmospherehotelsandresorts.com")
    bwebsiteId = "AHR";

  if (window.location.origin === "https://toc-ucms.theozencollection.com") {
    bwebsiteId = "TOC";
  }
  if (window.location.origin === "https://toc-ucms.digitechsoft.com") {
    bwebsiteId = "AHR";
  }



  if (bwebsiteId === "TOC")
    document.title = "TOC UCMS"

  if (bwebsiteId === "AHR")
    document.title = "AHR UCMS"


  return (
    <GlobalContext.Provider value={{ bwebsiteId }}>
      {children}
    </GlobalContext.Provider>
  );
};
