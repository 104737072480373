import React from 'react'

function ManageExperienceAwait() {
    return (
        <>
        fghfgh
            <div class="page-body">
                <div class="container-fluid dashboard-default">
                    <div class="page-heading d-flex flex-wrap align-items-center justify-content-between">
                        <nav class="w-100" aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a class="text-decoration-none" href="#">Home</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Experience Awaits</li>
                            </ol>
                        </nav>
                        <div class="left-header">
                            <h4 class="fw-normal m-0">A New Experience Awaits</h4>
                        </div>
                    </div>
                    <div class="row mt-md-4 mt-3">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header bg-transparent border-bottom py-2 d-flex align-items-center justify-content-between">
                                    <h5 class="m-0 fw-medium">Edit Experience Await</h5>
                                </div>
                                <div class="card-body">
                                    <div class="upload-file-wrapper">
                                        <form action="#" method="get">
                                            <div class="row">
                                                <div class="col-lg-6 mb-4">
                                                    <div class="facility-view">
                                                        <label for="ChangeIcon" class="form-label">Change icon</label>
                                                        <div class="input-group mb-3">
                                                            <label class="input-group-text px-3 facility-icon position-relative" for="UploadFacilityIcon"><i class="fa-solid fa-camera"></i>
                                                                <input type="file" name="" class="position-absolute" id="ChangeIcon" />
                                                            </label>
                                                            <input class="form-control py-2 shadow-none px-3" placeholder="xyz.jpeg" name="" id="" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 mb-4">
                                                    <label class="form-label" for="IconText">Icon Text</label>
                                                    <input maxLength={100} type="text" name="" id="IconText" class="form-control py-2 shadow-none px-3" />
                                                </div>

                                                <div class="col-lg-6 mb-4">
                                                    <label class="form-label" for="Heading">Heading</label>
                                                    <input maxLength={100} type="text" name="" id="Heading" class="form-control py-2 shadow-none px-3" />
                                                </div>

                                                <div class="col-lg-6 mb-4">
                                                    <label class="form-label" for="MoreButton">Learn More Button</label>
                                                    <input type="url" name="" id="MoreButton" class="form-control py-2 shadow-none px-3" />
                                                </div>

                                                <div class="col-md-12 mb-4">
                                                    <label class="form-label" for="Body">Body</label>
                                                    <textarea name="" id="Body" class="form-control py-2 shadow-none px-3" rows="5">Atmosphere Hotels & Resorts brand aims to provide a rich diversity of experiences through seven  well-defined sub-brand options giving property owners maximum flexibility while leveraging world class, turnkey solutions.
                                                    </textarea>
                                                </div>

                                            </div>

                                            <div class="col-md-12 mb-4">
                                                <label class="form-label" for="MoreButton">Seo Meta Tags</label>
                                                <div class="meta-tags mt-3">
                                                    <a href="#" class="rounded-pill btn btn-theme text-white px-3 me-1">Bio Data</a>
                                                    <a href="#" class="rounded-pill btn btn-theme text-white px-3 me-1">Marketing</a>
                                                    <a href="#" class="rounded-pill btn btn-theme text-white px-3 me-1">Designing</a>
                                                    <a href="#" class="rounded-pill btn btn-theme text-white px-3 me-1">Development</a>
                                                    <a href="#" class="rounded-pill btn btn-theme text-white px-3 me-1">Data Entry</a>
                                                </div>
                                            </div>
                                            <div class="col-12 text-end mb-2">
                                                <a href="#" class="btn btn-primary btn-theme outline-btn fw-semibold text-uppercase px-2 py-1 me-2 btn-sm">Preview</a>
                                                <input type="submit" value="Save" class="btn btn-primary btn-theme fw-semibold text-uppercase px-2 py-1" />
                                            </div>
                                        </form>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default ManageExperienceAwait