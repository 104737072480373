import React, { useState } from 'react';
import { Select, Button, message } from 'antd';

const { Option } = Select;

const data = {
  categories: [
    {
      label: 'Electronics',
      value: 'electronics',
      subcategories: [
        {
          label: 'GPS',
          value: 'gps',
          products: [
            { label: 'GPS Device 1', value: 'gps-device-1' },
            { label: 'GPS Device 2', value: 'gps-device-2' },
          ],
        },
        {
          label: 'Networking',
          value: 'networking',
          products: [
            { label: 'Router 1', value: 'router-1' },
            { label: 'Router 2', value: 'router-2' },
          ],
        },
      ],
    },
    {
      label: 'Clothing',
      value: 'clothing',
      subcategories: [
        {
          label: 'Men',
          value: 'men',
          products: [
            { label: 'Shirt', value: 'shirt' },
            { label: 'Jeans', value: 'jeans' },
          ],
        },
        {
          label: 'Women',
          value: 'women',
          products: [
            { label: 'Dress', value: 'dress' },
            { label: 'Skirt', value: 'skirt' },
          ],
        },
      ],
    },
  ],
};

const MultiSelectDropdown = () => {
  const [options, setOptions] = useState(data.categories);
  const [selectedValues, setSelectedValues] = useState([]);
  const [breadcrumb, setBreadcrumb] = useState([]);
  
  const handleChange = (values) => {
    setSelectedValues(values);
  };

  const handleSelect = (value) => {
    if (value === 'back') {
      handleBack();
      return;
    }

    const currentNode =
      options.find((item) => item.value === value) ||
      options.flatMap((item) => item.subcategories || []).find((item) => item.value === value) ||
      options
        .flatMap((item) => item.subcategories || [])
        .flatMap((item) => item.products || [])
        .find((item) => item.value === value);

    if (currentNode?.subcategories) {
      setOptions([{ label: "Don't select subcategories", value: 'dont-select-subcategories', disabled: true }, ...currentNode.subcategories]);
      setBreadcrumb([...breadcrumb, currentNode.label]);
    } else if (currentNode?.products) {
      setOptions([{ label: "Don't select subcategories", value: 'dont-select-subcategories', disabled: true }, ...currentNode.products]);
      setBreadcrumb([...breadcrumb, currentNode.label]);
    } else {
      setBreadcrumb([...breadcrumb, currentNode.label]);
    }
  };

  const handleBack = () => {
    const updatedBreadcrumb = breadcrumb.slice(0, -1);
    setBreadcrumb(updatedBreadcrumb);

    if (updatedBreadcrumb.length === 0) {
      setOptions(data.categories);
    } else {
      const lastCategory = updatedBreadcrumb[updatedBreadcrumb.length - 1];
      const parentCategory = data.categories.find((category) => category.label === lastCategory);
      setOptions(
        parentCategory?.subcategories ||
        data.categories.flatMap((category) => category.subcategories).find((sub) => sub.label === lastCategory)?.products ||
        data.categories
      );
    }
  };

  const handleDelete = () => {
    setSelectedValues([]);
    message.success('Selected categories deleted successfully');
  };

  return (
    <div>
      <Select
        mode="multiple"
        placeholder="Select Options"
        value={selectedValues}
        onChange={handleChange}
        onSelect={handleSelect}
        dropdownRender={(menu) => (
          <>
            {breadcrumb.length > 0 && (
              <div
                style={{ padding: '8px', cursor: 'pointer', color: '#333', fontWeight: 'bold' }}
                onClick={handleBack}
              >
                ← Back
              </div>
            )}
            {menu}
          </>
        )}
      >
        {options.map((item) => (
          <Option key={item.value} value={item.value} disabled={item.disabled}>
            {item.label}
          </Option>
        ))}
      </Select>

      {selectedValues.length > 0 && (
        <Button
          type="danger"
          onClick={handleDelete}
          style={{ marginTop: '10px' }}
        >
          Delete Selected
        </Button>
      )}
    </div>
  );
};

export default MultiSelectDropdown;