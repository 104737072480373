import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { getAllOffers } from "../dataService/DataService";
import { Table } from "../../components/dragTable/Table";
import { getAllVenues } from "../dataService/DataService";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { changeVenueStatus } from "../dataService/DataService";
import DeleteModal from "./DeleteModal";
import { Modal } from "react-bootstrap";
import { updateVenueSorting } from "../dataService/DataService";
import { useNavigate } from "react-router-dom";
const ManageVenue = () => {
  const navigate=useNavigate()
  const [showModal, setShowModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [selectedRowNameData, setSelectedRowNameData] = useState({});
  const handleStatus = (e, row) => {
    const status = e.target.value;
    const id = row.original.venue_id;
    row.original.is_active = status;
    const data = changeApplicationStatusMutate.mutate({ id, status });
  };
  const changeApplicationStatusMutate = useMutation(changeVenueStatus, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess(data) {
      toast.dismiss();
      toast.success(data.message);
    },
  });

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleNavigateToEdit = (propertyId) => {
    
    navigate(`/manage-venue/edit-venue/${propertyId}`);
  };

  const handleShowModal = (rowData) => {
    console.log(" row original : ",rowData.original)
    setSelectedRowData(rowData?.original?.id);
    setSelectedRowNameData(rowData?.original?.venue_name);
    setShowModal(true);
  };
  const [data, setData] = useState([]);

  const columns = React.useMemo(
    () => [
      { Header: "Sr. No", accessor: "srNo" },
      {
        Header: "Category",
        accessor: "category_name",
      },
      {
        Header: "Venue Name",
        accessor: "venue_name",
      },
      {
        Header: "Venue Type",
        accessor: "venue_type",
      },
      {
        Header: "Status",
        Cell: ({ row }) => (
          <select
            onChange={(e) => handleStatus(e, row)}
            value={row.original.is_active}
            className="form-select"
            style={{ width: "110px" }}
          >
            <option key="1" value="1">
              Active
            </option>
            <option key="0" value="0">
              Inactive
            </option>
          </select>
        ),
      },
      {
        Header: "Actions",
        Cell: ({ row }) => (
          <>
            <button
              onClick={() =>
                handleNavigateToEdit(row?.original?.id)
              }
              className="btn btn-primary btn-sm btn-theme"
            >
              <i className="fa fa-pencil" aria-hidden="true"></i>
            </button>
            <button
              variant="info"
              onClick={() => handleShowModal(row)}
              className="btn btn-primary bg-danger border-danger btn-sm btn-theme ms-1"
            >
              <i className="fa fa-trash" aria-hidden="true"></i>
            </button>
          </>
        ),
      },
    ],
    []
  );
  let allVenues = useQuery("getAllVenues", getAllVenues);
  useEffect(() => {
    if (allVenues?.data) {
      setData(allVenues?.data?.data);
    }
  }, [allVenues]);

  return (
    <>
      <div className="page-body">
        <div className="container-fluid dashboard-default">
          <div className="page-heading d-flex flex-wrap align-items-center justify-content-between">
            <h4 className="fw-normal m-0 fs-5">Manage Venue</h4>
          </div>

          <div className="row mt-md-4 mt-3">
            <div className="col-md-12">
              <div className="card mb-4">
                <div className="card-header bg-transparent border-bottom py-2 d-flex align-items-center justify-content-between">
                  <h5 className="m-0 fw-medium fs-6">All Venues</h5>
                  <div className="right-actions">
                    <Link
                      to="/manage-venue/create-venue"
                      className="btn btn-primary btn-theme fw-semibold text-uppercase px-2 py-1"
                    >
                      Create Venue
                    </Link>
                  </div>
                </div>
                <div className="card-body">
                  <div className="tabledata">
                    <div className="table-responsive">
                      {allVenues.isLoading || !allVenues?.data ? (
                        <div className="text-center">Loading...</div>
                      ) : data?.length === 0 ? (
                        <div className="card-body text-center">
                          No Data Available
                        </div>
                      ) : (
                        <>
                          <>
                            <Table
                              data={data}
                              setData={setData}
                              columns={columns}
                              isShowingHeading={false}
                              updateSortingTable={updateVenueSorting}
                              componentName={"venue"}
                              // resortsData={allResorts?.data?.data}
                            />
                          </>
                        </>
                      )}
                      <DeleteModal
                        show={showModal}
                        handleClose={handleCloseModal}
                        rowData={selectedRowData}
                        rowNameData={selectedRowNameData}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageVenue;
