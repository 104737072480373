import React, { useState, useEffect, useRef } from "react";
import { components } from "react-select";
import { Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import AddAlbumModal from "./AddAlbumModal";
import Pagination from "../../components/Pagination";
import { optmiseImage, cropImage } from "../dataService/DataService";
import { PiCopyThin } from "react-icons/pi";
import { FaCheck } from 'react-icons/fa';
import videoImg from "../../assets/images/video.png";
import pdfIcon from "../../assets/images/pdf-doc.svg";
import { useQuery, useQueryClient, useMutation } from "react-query";
import * as querydata from "../../services/service";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  convertToPixelCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import setCanvasPreview from "./setCanvasPreview";
import backarrow from "../../assets/images/back-arrow.svg";
import { data } from "jquery";
export const offerSchema = Yup.object({});


const { Option } = components;

function AlbumView() {
  const queryClient = useQueryClient();
  const [currentPage, setCurrentPage] = useState(1);
  const [sortMediaTypeCode, setSelectedOptions] = useState([]);
  let { websiteId, albumId } = useParams();
  const [albumSelectedId, setAlbumOptionId] = useState(albumId);
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [coordinate, setCoordinate] = useState({
    scalex: 0,
    scaley: 0,
  });
  const [dimension, setDimension] = useState({
    height: 0,
    width: 0,
  });

  const getAlbumFileData = useQuery(
    ["getAlbumFileData", +albumSelectedId, sortMediaTypeCode],
    () => querydata.getFileByAlbumId(albumSelectedId, sortMediaTypeCode)
  );
  // getFileByAlbumId
  const sortByData = useQuery(["sortByData"], () => querydata.getSortByData());

  // for album drop down
  const getMediaAlbumFolData = useQuery(
    ["getMediaAlbumFolData", +websiteId],
    () => querydata.getMediaAlbumByWebId(websiteId)
  );

  const folderName = getMediaAlbumFolData?.data?.data?.map((d) =>
    d.media_album_id === Number(albumId) ? d.media_album : ""
  );

  const handleOnChangeAlbumSelectBox = (event) => {
    const selectedItemId = event.target.value;
    setAlbumOptionId(selectedItemId);
  };

  let filteredData =
    getAlbumFileData &&
    getAlbumFileData.data &&
    getAlbumFileData.data.data &&
    getAlbumFileData.data.data;
  // const itemsPerPage = 10; // Number of items to display per page
  const [itemsPerPage, onItemsPerPageChange] = useState(20);
  const handleItemsPerPageChange = (event) => {
    const newItemsPerPage = parseInt(event.target.value);
    onItemsPerPageChange(newItemsPerPage);
  };

  const totalPages = Math.ceil(
    getAlbumFileData?.data?.data.length / itemsPerPage
  );
  const handlePageChange = (newPage) => {
    // Validate newPage to ensure it's within the valid range of pages
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const [filter, setFilter] = useState("");
  // const [filtdDsssata, setFilteredData] = useState(filteredData);

  let dataFileArray = Array.isArray(filteredData) ? filteredData : [];
  let startIndex = (currentPage - 1) * itemsPerPage;
  let endIndex = startIndex + itemsPerPage;
  let imgAndVdoData = dataFileArray.slice(startIndex, endIndex);
  const totalItems = dataFileArray.length;

  //mutation function to upload cropped images
  const cropMut = useMutation({
    mutationFn: cropImage,
    onSuccess: (data) => {
      let dataUrl = data?.data;

      let byteCharacters = atob(dataUrl);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const croppedBlob = new Blob([byteArray], { type: "image/png" });
      setCroppedFile(croppedBlob);
      setCroppedImage(URL.createObjectURL(croppedBlob));
      closeCropMediaModal();
    },
    onerror: (err) => {
      console.log("erorr : ", err);
    },
  });

  const cropMutation = cropMut;

  // const handleInputChange = (event) => {
  //   setFilter(event.target.value);
  //   setCurrentPage(1);
  // };
  const handleInputChange = (event) => {
    const filterValue = event.target.value.toLowerCase();
    setFilter(filterValue);
    setCurrentPage(1); // Reset currentPage when filter changes
  };
  filteredData =
    getAlbumFileData &&
    getAlbumFileData.data &&
    getAlbumFileData.data.data &&
    getAlbumFileData.data.data.filter(
      (file) =>
        file.media_title != null &&
        file.media_title.toLowerCase().includes(filter.toLowerCase())
    );
  dataFileArray = Array.isArray(filteredData) ? filteredData : [];
  startIndex = (currentPage - 1) * itemsPerPage;
  endIndex = startIndex + itemsPerPage;
  imgAndVdoData = dataFileArray.slice(startIndex, endIndex);

  //crop functionality
  const [base64, set64] = useState();
  const [crop, setCrop] = useState();
  const [image, setImage] = useState([]);
  const [croppedImage, setCroppedImage] = useState(null);
  const [croppedFile, setCroppedFile] = useState(null);
  const onCropComplete1 = (crop) => {
    cropMut.mutate({ base64, coordinate, dimension });
  };

  const onCropComplete = (crop) => {
    const fetchData = async () => {
      try {
        const { scaleX, scaleY } = setCanvasPreview(
          imgRef.current, // HTMLImageElement
          previewCanvasRef.current, // HTMLCanvasElement

          convertToPixelCrop(crop, imgRef.current.width, imgRef.current.height)
        );

        let { x, y, width, height } = crop;

        x = x * scaleX;
        y = y * scaleY;
        setCoordinate((prevState) => ({
          ...prevState,
          scalex: x,
          scaley: y,
        }));
        setDimension((prev) => ({
          ...prev,
          height: height * scaleX,
          width: width * scaleY,
        }));
        set64(image);

        const dataUrl = previewCanvasRef.current.toDataURL();

        let newOptmise;
        let byteCharacters;

        newOptmise = dataUrl.split(",")[1];
        byteCharacters = atob(newOptmise);

        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const croppedBlob = new Blob([byteArray], { type: "image/jpg" });

        // Update your state or do something with the cropped image
        setCroppedFile(croppedBlob);
      } catch (error) {}
    };
    fetchData();
  };

  const makeClientCrop = async (crop) => {
    if (image && crop.width && crop.height) {
      try {
        const croppedImageUrl = await getCroppedImg(image, crop);
        setCroppedImage(croppedImageUrl);
      } catch (error) {}
    }
  };
  const getCroppedImg = (image, crop) => {
    return new Promise((resolve) => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      const imageObj = new Image();
      imageObj.src = image;

      imageObj.onload = () => {
        canvas.width = crop.width;
        canvas.height = crop.height;

        ctx.drawImage(
          imageObj,
          crop.x,
          crop.y,
          crop.width,
          crop.height,
          0,
          0,
          crop.width,
          crop.height
        );

        // Get the cropped image as a data URL
        const base64Image = canvas.toDataURL("image/jpeg");
        resolve(base64Image);
      };
    });
  };

  const [deleteImgByFileId, setFileidForDeleteImg] = useState("");

  const [filterValue, setFilterValue] = useState("");
  const [show, setShow] = useState(false);
  const [selectedMediaFileId, setMediaFileId] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isLoading, setIsLoading] = useState(false);
  const [shows, setShows] = useState(false);
  const [localimg, setLocalimg] = useState("");
  const closeImageEditmodal = () => setShows(false);
  // const imgSearch =()=>
  const showImageEditModal = (mediaFileId) => {
    queryClient.refetchQueries("getAlbumFileData");
    if (selectedMediaFileId !== null) {
      const fetchData = async () => {
        try {
          const result = await querydata.getImageDataByMediaFileId(
            selectedMediaFileId
          );
          setGetSinleImageData(result);
        } catch (error) {
          toast.dismiss();
          // toast.error("Failed to fetch image");
        }
      };

      fetchData();
    }

    setMediaFileId((prevvalues) => {
      return mediaFileId;
    });
    setShows(true);
    setCroppedImage("");
  };
  const [cropMediaModal, setCropMediaModal] = useState(false);
  const closeCropMediaModal = () => setCropMediaModal(false);
  const showCropMediaModal = () => {
    setCropMediaModal(true);
  };

  const handleSelectChange = (event) => {
    const val = event.target.value;
    const selectMediaTypeCode = { sortBy: val };
    setSelectedOptions(selectMediaTypeCode);
    const updatedFormData = new FormData();
    updatedFormData.append("sortBy", val);
    // Assuming formData is updated based on your dropdown change
    // Refetch the query with the new albumId and updated formData
    getAlbumFileData.refetch(["getAlbumFileData", +albumId, updatedFormData]);
  };

  // Handler function for filter value
  // const handleFilterChange = (e) => {
  //   setFilterValue(e.target.value);
  // };

  const [getSingleImageData, setGetSinleImageData] = useState(null);

  useEffect(() => {
    setImage(
      getSingleImageData &&
        getSingleImageData.data &&
        getSingleImageData.data.media_file_path
    );
  }, [getSingleImageData]);

  useEffect(() => {
    if (selectedMediaFileId !== null) {
      const fetchData = async () => {
        try {
          const result = await querydata.getImageDataByMediaFileId(
            selectedMediaFileId
          );
          setGetSinleImageData(result);
        } catch (error) {
          toast.dismiss();
          toast.error("This image is not available");
        }
      };
      fetchData();
    }
  }, [selectedMediaFileId]);

  const blobToDataUrl = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  let initialValues = {
    albumFile:
      (getSingleImageData &&
        getSingleImageData.data &&
        getSingleImageData.data &&
        getSingleImageData.data.media_file_path) ||
      "",
    imgName:
      (getSingleImageData &&
        getSingleImageData.data &&
        getSingleImageData.data &&
        getSingleImageData.data.media_title) ||
      "",
    media_caption:
      (getSingleImageData &&
        getSingleImageData.data &&
        getSingleImageData.data.media_caption) ||
      "",
    seoTags:
      (getSingleImageData &&
        getSingleImageData.data &&
        getSingleImageData.data.seo_tags) ||
      "",
  };

  useEffect(() => {
    setValues({ ...initialValues });
    setFieldValue({ ...initialValues });
  }, [getSingleImageData]);

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    onSubmit: async (values, action) => {
      const formData = new FormData();

      if (croppedFile !== null) {
        formData.append("albumFile", croppedFile);
      } else {
        formData.append("albumFile", values.albumFile);
      }
      formData.append("seoTags", values.seoTags);
      formData.append("media_title", values.imgName);
      formData.append("media_caption", values.media_caption);
      if (selectedMediaFileId > 0) {
        updateprojectMutation.mutate({ formData, fileId: selectedMediaFileId });
      } else {
        toast.dismiss();
        toast.error("Please contact to administrator");
      }
    },
  });

  const updateprojectMutation = useMutation(querydata.updateImageAndDetails, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess: (data) => {
      setIsLoading(false);
      toast.dismiss();
      toast.success(data.message);
      queryClient.invalidateQueries("getAlbumFileData");
      queryClient.invalidateQueries("getMediaAlbumFolData");
      queryClient.resetQueries("getAlbumFileData");

      closeImageEditmodal();
      closeCropMediaModal();
      closeDialogModal();
    },
    onSettled: () => {
      setIsLoading(false);
    },
    onMutate: () => {
      setIsLoading(true);
    },
  });

  const deleteSingleAlbumImg = (deleteImgByFileId) => {
    setFileidForDeleteImg(deleteImgByFileId);
    closeImageEditmodal();
  };

  useEffect(() => {
    if (deleteImgByFileId !== null && deleteImgByFileId !== "") {
      const fetchData = async () => {
        try {
          const response = await querydata.deleteAlbumImg(deleteImgByFileId);

          if (response) {
            toast.dismiss();
            toast.success(response.message);
            queryClient.invalidateQueries("getAlbumFileData");
            queryClient.invalidateQueries("getMediaAlbumFolData");
          }
        } catch (error) {
          toast.dismiss();
          toast.error(error.message);
        }
      };

      fetchData();
    }
  }, [deleteImgByFileId]);

  const [showDialogModal, setShowDialogModal] = useState(false);
  const handleShowDialogModal = () => {
    setShowDialogModal(true);
  };
  const closeDialogModal = () => setShowDialogModal(false);
  const imageSizeBytes =
    (getSingleImageData &&
      getSingleImageData.data &&
      getSingleImageData.data.size) ||
    0;

  const convertBytesToMB = (bytes) => {
    // Convert to Megabytes (MB)
    const mb = bytes / (1024 * 1024);
    return mb;
  };

  const imageSizeMB = convertBytesToMB(imageSizeBytes);

  const [sortOrder, setSortOrder] = useState("");
  const [sortedData, setSortedData] = useState(dataFileArray);

  const handleFilterChange = (event) => {
    const newSortOrder = event.target.value;
    setSortOrder(newSortOrder);
  };

  useEffect(() => {
    const sortData = (data, order) => {
      const sortedData = [...data];
      switch (order) {
        case "ASC":
          sortedData.sort(
            (a, b) => new Date(a.created_on) - new Date(b.created_on)
          );
          break;
        case "DESC":
          sortedData.sort(
            (a, b) => new Date(b.created_on) - new Date(a.created_on)
          );
          break;
        default:
          // If order is not ASC or DESC, show the data in its original order
          // You can modify this default behavior based on your requirements
          break;
      }
      return sortedData; // Do not slice data here
    };

    // Apply sorting when sortOrder changes
    const newSortedData = sortData(dataFileArray, sortOrder);
    setSortedData(newSortedData);
  }, [dataFileArray.length, sortOrder]);
  const [copied, setCopied] = useState(false);
  const ImageCopyUrl = getSingleImageData?.data?.media_file_path
  const handleCopyUrl = () => {
    navigator.clipboard.writeText(ImageCopyUrl).then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 1000); // Show "Copied" state for 1 seconds
      // console.log('URL copied to clipboard!');
    }).catch(err => {
      console.error('Failed to copy URL:', err);
    });
  };
  return (
    <>
      <div className="page-body">
        <div className="container-fluid dashboard-default">
          <div className="page-heading d-flex flex-wrap align-items-center justify-content-between">
            <nav className="w-100" aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link
                    className="text-decoration-none"
                    to={`/manage-folder/manage-media/${websiteId}`}
                  >
                    Manage Albums
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Gallery Assets
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {folderName}
                </li>
              </ol>
            </nav>
            <div className="left-header d-flex align-items-center">
              <Link
                to={`/manage-folder/manage-media/${websiteId}`}
                className="back-arrow d-inline-block me-2"
              >
                <img src={backarrow} alt="" />
              </Link>
              <h4 className="fw-normal m-0 fs-6">
                Gallery <strong>Assets</strong>
              </h4>
            </div>
            <div className="header-action">
              <Button
                className="btn btn-primary btn-theme fw-semibold text-uppercase px-2 py-1"
                onClick={handleShow}
              >
                Add New File
              </Button>
              <AddAlbumModal
                albumId={albumId}
                show={show}
                headerText={`Upload Media`}
                handleClose={handleClose}
              />
            </div>
          </div>
          <div className="row mt-md-4 mt-3">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header bg-transparent border-bottom py-3 d-flex align-items-end justify-content-between">
                  <div className="left-header">
                    <div className="col-12 mb-0">
                      <label className="position-relative">
                        <input
                          type="text"
                          maxLength={20}
                          className="form-control rounded-1 py-2 shadow-none px-2 top-0"
                          onChange={handleInputChange}
                          name="imgSearch"
                          placeholder="search...."
                        />
                      </label>
                    </div>
                  </div>
                  <div className="right-action d-flex">
                    <div className="atmosphere-hr">
                      <label className="form-label form-control-sm mb-1 p-0">
                        Select Album
                      </label>

                      <select
                        className="form-control rounded-1 form-select"
                        onChange={handleOnChangeAlbumSelectBox}
                        defaultValue={albumId}
                      >
                        {getMediaAlbumFolData.data &&
                          getMediaAlbumFolData.data.data &&
                          getMediaAlbumFolData.data.data.map((option) => (
                            <option
                              key={option.media_album_id}
                              value={option.media_album_id}
                            >
                              {option.media_album}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="atmosphere-hr ms-2 me-2">
                      <label className="form-label form-control-sm mb-1 p-0">
                        Sort By
                      </label>
                      <div className="position-relative multiselect-box ">
                        <div className="ms-options-wrap">
                          <select
                            className="form-control rounded-1 form-select"
                            // onChange={handleSelectChange}
                            onChange={handleFilterChange}
                          >
                            <option value="">---Select---</option>
                            <option value="ASC">Date Ascending</option>
                            <option value="DESC">Date Descending</option>
                          </select>
                          {/* <select
                            className="form-control rounded-1 form-select"
                            onChange={handleSelectChange}
                          >
                            <option>Select </option>
                            {sortByData.data &&
                              sortByData.data.data &&
                              sortByData.data.data.map((mediaType) => (
                                <option
                                  key={mediaType.media_type_id}
                                  value={mediaType.media_type_code}
                                >
                                  {mediaType.media_type}
                                </option>
                              ))}
                          </select> */}
                        </div>
                      </div>
                    </div>
                    <div></div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="gallery-assets">
                    <ul className="list-unstyled m-0 p-0 d-flex flex-wrap align-items-center">
                      {sortedData &&
                        sortedData
                          .slice(startIndex, endIndex)
                          .map((imgData) => (
                            <li key={imgData.media_file_id}>
                              <div className="album-view border rounded-3 position-relative overflow-hidden">
                                <div className="album-head position-absolute px-3 py-3 start-0 top-0 w-100 d-flex align-items-center z-1 justify-content-between"></div>
                                <div className="album-set-icon">
                                  <a
                                    onClick={() =>
                                      showImageEditModal(imgData.media_file_id)
                                    }
                                    className="position-relative text-decoration-none text-white d-flex px-2 py-3"
                                  >
                                    {imgData.media_type_code === "VIDEO" ? (
                                      <img
                                        src={videoImg}
                                        alt={imgData.media_title}
                                        title={imgData.media_title}
                                      />
                                    ) : imgData.media_type_code === "PDF" ? (
                                      <img
                                        src={pdfIcon}
                                        alt={imgData.media_title}
                                        title={imgData.media_title}
                                      />
                                    ) : (
                                      <img
                                        src={imgData.media_file_path}
                                        alt={imgData.media_title}
                                        title={imgData.media_title}
                                        style={
                                          imgData.media_file_path.endsWith(
                                            ".png"
                                          ) ||
                                          imgData.media_file_path.endsWith(
                                            ".svg"
                                          )
                                            ? {
                                                backgroundColor: "darkgrey",
                                              }
                                            : {}
                                        }
                                      />
                                    )}
                                    <div className="album-title position-absolute start-0 w-100 z-1 bottom-0 py-3 px-3 text-center">
                                      <h6 className="mb-0">
                                        {imgData.media_title.length > 15
                                          ? `${imgData.media_title.substring(
                                              0,
                                              15
                                            )}...`
                                          : imgData.media_title}
                                      </h6>
                                      <div className="created-info mt-3 d-flex justify-content-center align-items-center">
                                        <span>
                                          On-:{" "}
                                          {moment(imgData.created_on).format(
                                            "DD/MM/YYYY HH:mm:ss"
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </div>
                            </li>
                          ))}
                    </ul>
                    <div className="d-flex align-items-center flex-wrap justify-content-between mt-1 mb-2 px-md-0 px-3">
                      <div className="show-enteries">
                        <div className="page-item">
                          <div className="ps-3">
                            <label
                              className="d-flex align-items-center"
                              style={{ maxWidth: "190px" }}
                            >
                              Show{" "}
                              <select
                                className="form-select form-select-sm ms-3 me-2"
                                value={itemsPerPage}
                                onChange={handleItemsPerPageChange}
                              >
                                <option value={20}>20</option>
                                <option value={40}>40</option>
                                <option value={60}>60</option>
                                <option value={80}>80</option>
                                <option value={100}>100</option>
                              </select>{" "}
                              entries
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="atmosphere-pagination album-pagination mt-0 d-flex">
                        <nav aria-label="...">
                          <ul className="pagination m-0 d-flex justify-content-end">
                            <Pagination
                              currentPage={currentPage}
                              totalPages={totalPages}
                              onPageChange={handlePageChange}
                              visiblePages={itemsPerPage}
                              totalItems={totalItems}
                            />
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal size="xl" show={shows} onHide={closeImageEditmodal}>
        <Modal.Header className="modal-header py-2" closeButton>
          <Modal.Title className="modal-title fs-5">{"Edit File"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <div className="row">
              <div className="col-md-6">
                <div className="large-album-cover position-relative">
                  {!croppedImage &&
                    getSingleImageData?.data?.media_file_path &&
                    !(
                      getSingleImageData?.data?.media_file_path.endsWith(
                        ".pdf"
                      ) ||
                      getSingleImageData?.data?.media_file_path.endsWith(".mp4")
                    ) && (
                      <img
                        className="w-100"
                        name="media_file_path"
                        src={getSingleImageData?.data?.media_file_path}
                        style={
                          getSingleImageData?.data?.media_file_path?.endsWith(
                            ".png"
                          ) ||
                          getSingleImageData?.data?.media_file_path?.endsWith(
                            ".svg"
                          )
                            ? {
                                backgroundColor: "darkgrey",
                              }
                            : {}
                        }
                      />
                    )}
                  {croppedImage && (
                    <img
                      src={croppedImage}
                      alt="Cropped"
                      style={{ maxWidth: "100%" }}
                    />
                  )}{" "}
                  {getSingleImageData?.data?.media_type_code === "VIDEO" ? (
                    <>
                      <img className="w-100" src={videoImg} alt="video"></img>
                    </>
                  ) : getSingleImageData?.data?.media_type_code === "PDF" ? (
                    <img className="w-50" src={pdfIcon} alt="pdf"></img>
                  ) : (
                    <div className="edit-album-cover position-absolute end-0 top-0 m-3 z-3">
                      <span
                        className="btn btn-sm btn-secondary"
                        onClick={showCropMediaModal}
                      >
                        <i className="fa fa-pencil me-1"></i> Edit
                      </span>
                    </div>
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <div className="uploaded-album-info">
                  <div className="top-album-info rounded-2 bg-light py-4 px-4">
                    <ul className="list-unstyled m-0 p-0">
                      <li>
                        <span className="ulabel opacity-50">Uploaded By</span>
                        <span className="uvalue fw-semibold">
                          {getSingleImageData?.data?.user_name}
                        </span>
                      </li>
                      <li>
                        <span className="ulabel opacity-50">Size</span>

                        <span className="uvalue fw-semibold">
                          {imageSizeMB?.toFixed(2)}MB
                        </span>
                      </li>
                      <li>
                        <span className="ulabel opacity-50">Date</span>
                        <span className="uvalue fw-semibold">
                          {moment(getSingleImageData?.data?.created_on).format(
                            "MMM D, YYYY h:mm A [GMT]Z"
                          )}
                        </span>
                       
                      </li>
                     
                      {getSingleImageData?.data?.media_type_code === "IMAGE" ? (
                        <>
                          <li>
                            <span className="ulabel opacity-50">Dimension</span>
                            <span className="uvalue fw-semibold">
                              {getSingleImageData?.data?.image_width}x
                              {getSingleImageData?.data?.image_height}
                            </span>
                          </li>
                          <li>
                            <span className="ulabel opacity-50">Extension</span>
                            <span className="uvalue fw-semibold">
                              {getSingleImageData?.data?.image_format}
                            </span>
                          </li>
                        </>
                      ) : (
                        <></>
                      )}
                      {ImageCopyUrl ? <li>
                        <span className="ulabel opacity-50">URL</span>
                        <span className="uvalue fw-semibold">                         
                          <Link onClick={handleCopyUrl} className="text-black text-decoration-none text-uppercase " >
                            {copied ? <FaCheck className="me-2" size={18} /> : <PiCopyThin className="me-2" size={18} />}
                            {copied ? 'Copied!' : 'Copy Url'}                          
                          </Link>
                        </span>
                      </li> : ""}
                    </ul>
                  </div>
                  <form>
                    <div className="mb-4">
                      {getSingleImageData?.data?.media_type_code === "VIDEO" ? (
                        <label className="form-label" htmlFor="Name">
                          Video Title
                        </label>
                      ) : getSingleImageData?.data?.media_type_code ===
                        "PDF" ? (
                        <label className="form-label" htmlFor="Name">
                          Pdf Title
                        </label>
                      ) : (
                        <label className="form-label" htmlFor="Name">
                          Image Title
                        </label>
                      )}
                      <div className="mb-4">
                        <input
                          type="text"
                          name="imgName"
                          onChange={handleChange}
                          value={values.imgName}
                          className="form-control py-2 shadow-none px-3"
                          maxLength={100}
                        />
                      </div>
                    </div>

                    <div className="mb-4">
                      <label className="form-label" htmlFor="Name">
                        SEO Image Tags
                      </label>
                      <div className="mb-4">
                        <div className="position-relative multiselect-box">
                          <div className="mb-4">
                            <input
                              type="text"
                              onChange={handleChange}
                              name="seoTags"
                              value={values.seoTags}
                              className="form-control py-2 shadow-none px-3"
                              maxLength={200}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mb-4">
                      <label className="form-label" htmlFor="Name">
                        Caption
                      </label>
                      <div className="mb-4">
                        <input
                          type="text"
                          onChange={handleChange}
                          name="media_caption"
                          value={values.media_caption}
                          className="form-control py-2 shadow-none px-3"
                          maxLength={255}
                        />
                      </div>
                    </div>

                    <div className="col-12 pt-2 text-end mb-2">
                      <input
                        type="reset"
                        value="Cancel"
                        onClick={closeImageEditmodal}
                        className="btn btn-primary btn-theme outline-btn text-uppercase px-3 py-2 me-3"
                      />

                      <Button
                        type="button"
                        onClick={() =>
                          deleteSingleAlbumImg(
                            // getSingleImageData.data.media_file_id
                            selectedMediaFileId
                          )
                        }
                        className="btn bg-danger border-danger text-white btn-theme text-uppercase px-3 py-2 me-3"
                      >
                        <span>Delete</span>
                      </Button>
                      {!croppedImage && (
                        <input
                          onClick={handleSubmit}
                          type="submit"
                          value="Save"
                          className="btn btn-primary btn-theme fw-semibold text-uppercase px-2 py-1 "
                        />
                      )}
                      {croppedImage && (
                        <Button
                          onClick={handleShowDialogModal}
                          className="btn btn-primary btn-theme fw-semibold text-uppercase px-2 py-1 me-3"
                        >
                          Save
                        </Button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* start  crop modal */}
      {/* <Modal
        show={cropMediaModal}
        onHide={closeCropMediaModal}
        className="imagecropmodel"
        backdrop="static"
      >
        <Modal.Header className="modal-header py-2" closeButton>
          <Modal.Title className="modal-title fs-5">{"Crop File"}</Modal.Title>
        </Modal.Header>
        <Modal.Body
          className=" overflow-x-auto overflow-y-auto"
          style={{ "min-height": "772px" }}
        >
          

          <div
            className="crop-now overflow-x-auto overflow-y-auto"
            style={{
              width:
                getSingleImageData &&
                getSingleImageData.data &&
                getSingleImageData.data.image_width,
            }}
          >
            {croppedImage && (
              <ReactCrop
                crop={crop}
                onChange={(c) => setCrop(c)}
                onComplete={onCropComplete}
              >
                <img alt="" src={image} />

              </ReactCrop>
            )}

            {!croppedImage && (
              <ReactCrop
                crop={crop}
                onChange={(c) => setCrop(c)}
                onComplete={onCropComplete}
              >
                <img className="before-crop-image" alt="" src={image} />

              </ReactCrop>
            )}
          </div>

         
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={onCropComplete1}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal> */}
      <Modal
        size="xl"
        show={cropMediaModal}
        onHide={closeCropMediaModal}
        className="imagecropmodel"
        backdrop="static"
      >
        <Modal.Header className="modal-header py-2" closeButton>
          <Modal.Title className="modal-title fs-5">{"Crop Media"}</Modal.Title>
        </Modal.Header>
        <Modal.Body
          className=" overflow-y-auto d-flex justify-content-center align-items-start"
          style={{ minHeight: "400px" }}
        >
          <div
            className="crop-now text-center"
            style={{
              // width:
              //   getSingleImageData &&
              //   getSingleImageData.data &&
              //   getSingleImageData.data.image_width,
              width: "800px",
              backgroundColor: "darkgrey",
            }}
          >
            <ReactCrop
              style={{ width: "800px" }}
              crop={crop}
              onChange={(c) => setCrop(c)}
              onComplete={onCropComplete}
            >
              <img
                alt=""
                src={image}
                // onLoad={onImageLoad}
                ref={imgRef}
                // crossOrigin="anonymous"
                style={{ width: "100%" }}
              />
            </ReactCrop>

            {/* dont delete this canvas as this canvas image ref is saved  */}
            <canvas
              ref={previewCanvasRef}
              className="mt-4"
              style={{
                display: "none",
                border: "1px solid black",
                objectFit: "contain",
                width: 150,
                // height: 150,
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="res text-uppercase" variant="primary" onClick={onCropComplete1} disabled={cropMutation.isLoading}>
            {cropMutation.isLoading ? (
              <div class="spinner-border" role="status"></div>
            ) : (
              "Save Changes"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* End crop Modal */}

      {/* START MEDIA DIALOG MODAL */}

      <Modal show={showDialogModal} onHide={closeDialogModal}>
        <Modal.Header className="py-2" closeButton>
          <Modal.Title className="fs-5">Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Cropping Image Connot Be Revert...?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="text-uppercase btn-sm" onClick={closeDialogModal}>
            Close
          </Button>
          <Button variant="primary" className="text-uppercase btn-sm" onClick={handleSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      {/* END MEDIA DIALOG MODAL */}
    </>
  );
}

export default AlbumView;
